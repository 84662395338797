import * as React from 'react';

import { Box, Grid, Typography } from '@mui/material';
import { isMobile } from 'react-device-detect';

import Logo from '../../images/hilight_hi_logo.png';

import './TeacherSupport.css';

export function TeachersSupport() {
    return (
    <div className='teacher-support-page'>
        <Box className='page-heading'>Support</Box>
        <Grid container spacing={3} className={`teacher-support-grid ${isMobile ? 'teacher-support-grid-mobile' : ''}`}>
            <Grid item className='teacher-main-support-box'>
                <Typography variant='h5' className='support-questions'>
                    Need help? <br/>
                    Have a question about hilight? <br/>
                    Want to provide feedback? <br/>
                </Typography>
                <Box
                    component="img"
                    className='logo'
                    alt="hilight logo."
                    src={Logo}
                />
                <Typography variant='h6' className='support-instructions'>
                    Send an email to <b>support@hilightedu.com</b>, and we will get back to you as soon as possible.</Typography>
            </Grid>
        </Grid>
    </div>
    )
}