import React,  { useState } from 'react';
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Tooltip, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import HelpIcon from '@mui/icons-material/Help';
import { isMobile } from 'react-device-detect';
import * as Sentry from "@sentry/react";
import { useSelector } from 'react-redux';

import { RecognitionData } from '../../../../../src/types/recognition';
import { authSelector } from '../../../store/authSlice';
import { MainServiceApi } from '../../../services/mainService';

import './RemoveHilightPopup.css';

export interface RemoveHilighPopupProps {
    popupOpen: boolean;
    closePopup: () => void;
    recognitionData: RecognitionData;
    hilightRemoved: () => void;
}

export default function RemoveHilightPopup(props: RemoveHilighPopupProps) {
    const {
        popupOpen,
        closePopup,
        recognitionData,
        hilightRemoved
    } = props;

    const authDataStore = useSelector(authSelector);
    const { token } = authDataStore;

    const [removeHilightInProgress, setRemoveHilightInProgress] = useState(false);
    const [removeHilightComplete, setRemoveHilightComplete] = useState(false);
    const [removeHilightError, setRemoveHilightError] = useState(false);

    const handleClosePopup = () => {

        if (removeHilightComplete) {
            hilightRemoved(); // this one is from the feedList component - it will reload the page - need to do this when we close the popup or the popup will get closed by reload
        }

        setRemoveHilightError(false); // just in case it errored and we need to try again
        setRemoveHilightInProgress(false);
        closePopup();
    }

    const handleClickConfirmRemoveHilight = async () => {
        setRemoveHilightInProgress(true);

        try {
            if (token) {
                const mainServiceApi = MainServiceApi();
                await mainServiceApi.removeRecognition(recognitionData.id, token)

                setRemoveHilightComplete(true);
            } else {
                throw new Error('No token found')
            }
            
        } catch (err) {
            setRemoveHilightError(true);
            Sentry.captureException(err);
        }

        setRemoveHilightInProgress(false);
    }

    

    let dialogContent = (
        <Box>
            <Typography>
                Please confirm you want to remove the following hilight sent by <span className='bold-text'>{recognitionData.userSenderName.firstName} {recognitionData.userSenderName.lastName} </span>
                to <span className='bold-text'>{recognitionData.userReceiverName.firstName} {recognitionData.userReceiverName.lastName}</span>:
            </Typography>
            <Typography className='hilight-text'>{recognitionData.text}</Typography>
            <Typography>It is your responsiblity to reach out to the sender and let them know why the hilight was removed. Please copy the contents of the hilight before removing it so that you can reference it if needed.</Typography>
        </Box>
    );
    if (removeHilightInProgress) {
        dialogContent = (
            <Box className='remove-hilight-progress' ><CircularProgress/></Box>
        )
    }
    if (removeHilightComplete) {
        dialogContent = (
            <Typography>Hilight successfully removed. Please contact the sender, <span className='bold-text'>{recognitionData.userSenderName.firstName} {recognitionData.userSenderName.lastName}, </span> to let them know you removed it.</Typography>
        );
    }
    if (removeHilightError) {
        dialogContent = (
            <Typography>Something went wrong when removing the hilight. Please close out of this window and try again. Hilight Support has been notified.</Typography>
        );
    }
    return (
        <Box>
            <Dialog open={popupOpen} onClose={handleClosePopup}>
                <DialogTitle>{removeHilightError ? 'Whoops!' : 'Remove Hilight'}</DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClosePopup}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: 'grey',
                    }}
                    >
                    <CloseIcon />
                </IconButton>
                <DialogContent className={isMobile ? 'remove-hilight-dialog-content-mobile' : 'remove-hilight-dialog-content'}>
                    {dialogContent}
                </DialogContent>
                <DialogActions>
                    <Tooltip title={
                        <Box>
                            <Typography>As an Admin user, you have the ability to remove hilights that you don't think are appropriate for your school or district.</Typography>
                            <br/>
                            <Typography>The recipient will not be notified, but the removed hilight will no longer show up on their personal feed or on the school or district feeds.</Typography>
                        </Box>
                    }>
                        <HelpIcon className='remove-hilight-info-icon' fontSize='large'/>
                    </Tooltip>
                    {!removeHilightComplete && !removeHilightError &&
                        <Button
                            variant='contained'
                            onClick={handleClickConfirmRemoveHilight}
                        >
                            Confirm
                        </Button>
                    }
                    <Button
                        variant='contained'
                        onClick={handleClosePopup}
                        id='close-recognition-popup-button'
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    )
}