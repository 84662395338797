import Cookies from 'js-cookie';

import { UserData, UserLoginResult } from "../../../src/types/user";


export interface LoginServiceApi {
    signInUser: (username: string, password: string) => Promise<{
        token?: string,
        expiresAt?: number,
        userData?: UserData,
        errorMessage?: string
    }>
    verifyToken: () => Promise<{
        token?: string,
        expiresAt?: number,
        userData?: UserData,
        errorMessage?: string
    }>
    signOutUser: () => Promise<void>
    sendForgotPasswordEmail: (userEmail: string, token: string) => Promise<{userNotFound?: boolean} | undefined>
    cleverSignIn: (code: string, scopes?: string) => Promise<{token?: string, expiresAt?: number, userData?: UserData, errorMessage?: string}>
    googleSignIn: (userCredentials: string) => Promise<{token?: string, expiresAt?: number, userData?: UserData, errorMessage?: string}>
}

export interface LoginServiceApiFactory {
    (): LoginServiceApi;
}
export const LoginServiceApi: LoginServiceApiFactory = (): LoginServiceApi => {
    return {
        signInUser: async (
            username: string,
            password: string
        ): Promise<{
            token?: string,
            expiresAt?: number,
            userData?: UserData,
            errorMessage?: string
        }> => {
            const response = await fetch(`/api/v1/user/signin`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    username,
                    password
                })
            })
            if (response.ok) {
                return response.json() as Promise<UserLoginResult>;
            } else if (response.status === 401) {
                const result = await response.json();
                return {
                    errorMessage: result.message
                }
            } else if (response.status === 403) {
                // 403 means that no enabled schools were found for this user
                // might want to make this more broad in the future
                return {
                    errorMessage: 'You logged in using the correct credentials, but we were not able to log you into hilight because your school or district has not gone live on hilight yet. Your account should be enabled soon! Please contact your system administrator or reach out to us at support@hilightedu.com for more help.' 
                }
            } else {
                throw new Error(`Error logging in user. Error message: ${response.statusText}`)
            }
            
        },
        verifyToken: async (): Promise<{
            token?: string,
            expiresAt?: number,
            userData?: UserData,
            errorMessage?: string
        }> => {
            const response = await fetch(`/api/v1/verifyToken`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'X-XSRF-TOKEN': Cookies.get('XSRF-TOKEN') || '', 
                },
            });
            if (response.status === 204) {
                // 204 means that a refresh token was not sent, so the service did not generate a new access token
                // so we need to convey to the action reducer that the state should not change - we do that by returning an empty object
                return {}
            } else if (response.ok) {
                return response.json();
            } else if (response.status === 401) {
                // 401 means the token was not valid
                // We shouldn't throw an error but we should return an error message for the action reducer to handle
                return {
                    errorMessage: 'Unathorized'
                }

            } else {
                throw new Error(`Error verifying token. Error message: ${response.statusText}`);
            }
        },
        signOutUser: async (): Promise<void> => {
            const response = await fetch('/api/v1/user/signout', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application-json'
                }
            });
            if (response.ok) {
                return;
            } else {
                throw new Error(`Unable to logout user. Error message: ${response.statusText}`);
            }
        },
        sendForgotPasswordEmail: async (userEmail: string, token: string): Promise<{ userNotFound?: boolean} | undefined> => {
            const response = await fetch('/api/v1/sendForgotPasswordEmail', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                    'X-XSRF-TOKEN': Cookies.get('XSRF-TOKEN') || '',
                },
                body: JSON.stringify({
                    userEmail
                })
            });
            if (response.ok) {
                return;
            } else if (response.status === 404) {
                return {
                    userNotFound: true,
                }
            } else {
                throw new Error(`Unable to send Forgot Password Email. Error message: ${response.statusText}`);
            }
        },
        cleverSignIn: async (
            code: string,
            scopes?: string
        ): Promise<{
            token?: string,
            expiresAt?: number,
            userData?: UserData,
            errorMessage?: string
        }> => {
            const response = await fetch(`/api/v1/user/cleversignin`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    code,
                    scopes
                })
            })
            if (response.ok) {
                return response.json() as Promise<UserLoginResult>;
            } else if (response.status === 401) {
                // 401 means that the Clever login was successful but we don't have a user with the Clever id
                return {
                    errorMessage: 'We were not able to log you into Hilight because your school or district has not enabled Hilight in Clever yet or has been disabled. Please contact your system administrator or reach out to us at support@hilightedu.com for more help.'
                }
            } else if (response.status === 403) {
                // 403 means that no enabled schools were found for this user
                // might want to make this more broad in the future
                return {
                    errorMessage: 'We were not able to log you into Hilight because your school or district has not gone live on Hilight yet or has been disabled. Please contact your system administrator or reach out to us  at support@hilightedu.com for more help.' 
                }
            }
            else {
                throw new Error(`Error signing in the user with Clever. Error message: ${response.statusText}`)
            }
            
        },
        googleSignIn: async (
            userCredentials: string,
        ): Promise<{
            token?: string,
            expiresAt?: number,
            userData?: UserData,
            errorMessage?: string
        }> => {
            const response = await fetch(`/api/v1/user/googlesignin`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    userCredentials
                })
            })
            if (response.ok) {
                return response.json() as Promise<UserLoginResult>;
            } else if (response.status === 401 || response.status === 403) {
                // 401 means that the Google login was successful but we don't have this user in Hilight
                // 403 means that no enabled schools were found for this user
                return {
                    errorMessage: 'We were not able to log you into Hilight because your school or district has not enabled Hilight yet. Please contact your system administrator or reach out to us at support@hilightedu.com for more help.'
                }
            } else {
                throw new Error(`Error signing in the user with Google. Error message: ${response.statusText}`)
            }
            
        },
    }
}