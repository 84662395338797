import React, {useState, useEffect} from 'react';

import { Accordion, AccordionDetails, AccordionSummary, Autocomplete, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, MenuItem, TextField, Tooltip, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import * as Sentry from "@sentry/react";
import { isMobile } from 'react-device-detect';

import { School, UserData } from '../../../../../../src/types/user';
import { UserType } from '../../../../util/type';
import { useAppSelector, useAppDispatch } from '../../../../store/hooks';
import { authSelector, updateUserData } from '../../../../store/authSlice';
import { UserServiceApi } from '../../../../services/userService';
import { PermissionServiceApi } from '../../../../services/permissionService';

import './EditUserPopup.css';

export interface EditUserPopupProps {
    userToUpdate?: UserData;
    districtId: number;
    districtSchools: School[];
    popupOpen: boolean;
    closePopup: () => void;
    resetUser: (data:{ user: UserData, newlyCreatedUser: boolean}) => void;
    reactivateUser: boolean;
}

export default function EditUserPopup(props: EditUserPopupProps) {
    const { userToUpdate, districtSchools, popupOpen, closePopup, resetUser, reactivateUser, districtId } = props;

    // Form fields
    const [firstNameField, setFirstNameField] = useState<string | undefined>();
    const [lastNameField, setLastNameField] = useState<string | undefined>();
    const [emailField, setEmailField] = useState<string | undefined>();
    const [selectedSchool, setSelectedSchool ] = useState<School | undefined>();
    const [selectedUserType, setSelectedUserType ] = useState<string | undefined>('teacher');
    const [roleField, setRoleField] = useState<string | undefined>();
    const [additionalSchools, setAdditionalSchools] = useState<School[] | undefined>(undefined);
    const [isCleverUser, setIsCleverUser] = useState(false);

    const authDataStore = useAppSelector(authSelector);
	const dispatch =  useAppDispatch();
    const { token } = authDataStore;
    const currentLoggedInUser = authDataStore.user;

    // Loading and error handling
    const [updateInProgress, setUpdateInProgress] = useState(false);
    const [updateSuccessful, setUpdateSuccessful] = useState(false);
    const [errorUpdatingUser, setErrorUpdatingUser] = useState(false);

    // Permissions
    const [allowMultipleSchools, setAllowMultipleSchools] = useState(false);

    const resetFields = () => {
        setUpdateInProgress(false);
        setUpdateSuccessful(false);
        setErrorUpdatingUser(false);
        setFirstNameField(undefined);
        setLastNameField(undefined);
        setEmailField(undefined);
        setSelectedSchool(undefined);
        setSelectedUserType('teacher');
        setRoleField(undefined);
        setAdditionalSchools([]);
        setIsCleverUser(false);
    };

    useEffect(() => {
        // reset the popup
        setUpdateInProgress(false);
        setUpdateSuccessful(false);
        setErrorUpdatingUser(false);
        
        if (userToUpdate) {
            setFirstNameField(userToUpdate.firstName);
            setLastNameField(userToUpdate.lastName);
            setEmailField(userToUpdate.email);
            const usersCurrentPrimarySchool = districtSchools.find((school) => school.id === userToUpdate.primarySchoolId);
            setSelectedSchool(usersCurrentPrimarySchool);
            setSelectedUserType(userToUpdate.userType === UserType.Admin ? 'admin' : 'teacher');
            setRoleField(userToUpdate.roleAtSchool);
            if (userToUpdate.schools && userToUpdate.schools?.length > 1) {
                setAdditionalSchools(userToUpdate.schools.filter((school) => school.id !== userToUpdate.primarySchoolId));
            } else {
                setAdditionalSchools([]);
            }
            setIsCleverUser(userToUpdate.cleverUserId !== undefined && userToUpdate.cleverUserId !== null);
        } else {
            resetFields();
        }
    }, [userToUpdate]);

    // Permissions
    const getDistrictLevelPermissions = async (districtId: number, token: string) => {
        try {
            const permissionsServiceApi = PermissionServiceApi();
            const districtPermissions = await permissionsServiceApi.getPermissionsForDistrict(districtId, token);

            // Does this district allow a user to belong to multiple schools?
            const allowMultipleSchoolsKey = 'ALLOW_MULTI_SCHOOL_USERS';
            const districtAllowsMultiSchools = districtPermissions.find(permission => permission.permissionKey === allowMultipleSchoolsKey);
            if (districtAllowsMultiSchools) {
                setAllowMultipleSchools(true);
            } else {
                setAllowMultipleSchools(false);
            }
        } catch (err) {
            // log the error
            Sentry.captureException(err);
        }
    }
    useEffect(() => {
        if (districtId && token) {
            getDistrictLevelPermissions(districtId, token);
        }
    }, [districtId, token])

    const getUserTypeFromDropdownOptions = (userTypeOption?: string ): UserType | undefined => {
        if (!userTypeOption) {
            return;
        }
        return userTypeOption === 'admin' ? UserType.Admin : UserType.Teacher;
    }

    const handleClickSave = async () => {
        const userService = UserServiceApi();
        setUpdateInProgress(true);
		try {
			// Update or Create the User Data
            if (!userToUpdate) {
                // create the user
                let userSchools: School[] = [selectedSchool!];
                if (additionalSchools) {
                    userSchools = userSchools.concat(additionalSchools);
                }
                const result: {user?: UserData} = await userService.createUser({
                    firstName: firstNameField!,
                    lastName:  lastNameField!,
                    email: emailField!,
                    primarySchoolId: selectedSchool!.id,
                    roleAtSchool: roleField!,
                    userType: getUserTypeFromDropdownOptions(selectedUserType)!, 
                    districtId,
                    sendWelcomeEmail: true, // when users are created from this popup, we always send a welcome email
                    schools: userSchools
                }, token!);

                if (!result || !result.user) {
                    throw new Error('Created user not returned from request');
                }
                resetUser({user: result.user, newlyCreatedUser: true})
            } else {
                // Otherwise, update the user
                const newUserType = getUserTypeFromDropdownOptions(selectedUserType);
                let userSchools: School[] = [selectedSchool!];
                if (additionalSchools) {
                    userSchools = userSchools.concat(additionalSchools);
                }
                const result: { user?: UserData} = await userService.updateUserData(
                    userToUpdate.id, 
                    {
                        newFirstName: firstNameField !== userToUpdate.firstName ? firstNameField : undefined, // set these fields to undefined if they haven't changed
                        newLastName: lastNameField !== userToUpdate.lastName ? lastNameField : undefined,
                        newEmail: emailField !== userToUpdate.email ? emailField : undefined,
                        newPrimarySchoolId: selectedSchool?.id !== userToUpdate.primarySchoolId ? selectedSchool?.id : undefined,
                        newRoleAtSchool: roleField !== userToUpdate.roleAtSchool ? roleField : undefined,
                        newUserType: newUserType !== userToUpdate.userType ? newUserType : undefined,
                        schools: userSchools, // for now we won't check if they've changed - handle it on the backend
                    },
                    token!
                );

                if (!result || !result.user) {
                    throw new Error('Updated user not returned from request');
                }
                // If the updated user is the currently logged in user, then we need to make sure we reset with any updates to the currently logged in user
                if (result.user.id === currentLoggedInUser!.id) {
                    dispatch(updateUserData(result.user));
                }
                resetUser({user: result.user, newlyCreatedUser: false});
            }
            setUpdateSuccessful(true);
		} catch (err) {
            // show error message
            setErrorUpdatingUser(true);
			// log the error
			Sentry.captureException(err);
		}
        
        // handle showing loading
        setUpdateInProgress(false);
    }


    const handleClickClose = async () => {
        closePopup();
        resetFields();
    }

    if (errorUpdatingUser) {
        return (
            <Box>
                <Dialog open={popupOpen} onClose={handleClickClose}>
                    <DialogTitle>Whoops!</DialogTitle>
                    <IconButton
                        aria-label="close"
                        onClick={handleClickClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: 'grey',
                        }}
                        >
                        <CloseIcon />
                    </IconButton>
                    <DialogContent className={isMobile ? 'edit-user-popup-content-mobile' : 'edit-user-popup-content'}>
                        <Typography className='edit-user-popup-error-text'>Something went wrong when updating the user. Please refresh this page and try again. Hilight Support has been notified.</Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button 
                            variant='contained'
                            onClick={handleClickClose}
                            id='cancel-edit-user-button'
                        >
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </Box>
        )
    }

    if (updateSuccessful) {
        return (
            <Box>
                <Dialog open={popupOpen} onClose={handleClickClose}>
                    <DialogTitle>{userToUpdate ? 'Edit User' : 'Create User'}</DialogTitle>
                    <IconButton
                        aria-label="close"
                        onClick={handleClickClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: 'grey',
                        }}
                        >
                        <CloseIcon />
                    </IconButton>
                    <DialogContent className={isMobile ? 'edit-user-popup-content-mobile' : 'edit-user-popup-content'}>
                        <Typography className='edit-user-popup-success-text'>{userToUpdate ? 'User updated successfully!' : 'User created successfully!'}</Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button 
                            variant='contained'
                            onClick={handleClickClose}
                            id='cancel-edit-user-button'
                        >
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </Box>
        );
    }

    return (
        <Box>
            <Dialog open={popupOpen} onClose={handleClickClose}>
                <DialogTitle className='edit-user-popup-title'>{reactivateUser ? 'Reactivate User' : (userToUpdate ? 'Edit User' : 'Create User') }</DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClickClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: 'grey',
                    }}
                    >
                    <CloseIcon />
                </IconButton>
                <DialogContent className={isMobile ? 'edit-user-popup-content-mobile' : 'edit-user-popup-content'}>
                    {updateInProgress ? 
                        <Box className='edit-user-loading'><CircularProgress /> </Box>
                    : 
                    <div>
                        {(isCleverUser && userToUpdate && !reactivateUser) && 
                            <Box >
                                <Box className='edit-clever-user-warning edit-clever-user-warning-text'>
                                    <Typography>
                                        This user is linked to a Clever account. The following information was imported from Clever. If any of this information is incorrect, it needs to be updated in Clever. 
                                        <Tooltip 
                                            title={
                                                <Typography>If any of the information for this user is incorrect, it needs to be updated in Clever. It will then get updated in Hilight during the nightly Clever sync. Please reach out to your system administrator to do so. You can also contact the Hilight Support Team at support@hilightedu.com for more help.</Typography>
                                            }
                                            arrow={true}
                                        >
                                            <HelpOutlineIcon className='additional-schools-help-icon' />
                                        </Tooltip>
                                    </Typography>
                                    
                                </Box>
                                
                                <Box className='edit-user-clever-fields-border'>
                                    <Typography className='edit-user-clever-header'>User Data imported from Clever:</Typography>
                                    <Box className='edit-user-clever-fields'>
                                        <Typography><span className='bold-text-black'>First Name: </span>{firstNameField}</Typography>
                                        <Typography><span className='bold-text-black'>Last Name: </span>{lastNameField}</Typography>
                                        <Typography><span className='bold-text-black'>Email: </span>{emailField}</Typography>
                                        {userToUpdate.schools && userToUpdate.schools.length > 1 ?
                                            <Box>
                                                <Typography className='bold-text-black'>Schools:</Typography>
                                                <Box className='edit-user-clever-fields'>
                                                    {userToUpdate.schools.map(school => <Typography>{school.name}</Typography>)}
                                                </Box>
                                            </Box>
                                        : 
                                            <Typography><span className='bold-text-black'>School: </span>{selectedSchool?.name}</Typography>
                                        }
                                    </Box>
                                </Box>
                                    <Typography className='clever-user-type-text'>
                                        However, you can change this user's <span className='edit-user-bold-text'>Role</span> and <span className='edit-user-bold-text'>User Type</span> here, and it will NOT be overriden by the Clever sync.
                                    </Typography>
                            </Box>
                        }
                        {reactivateUser && !isCleverUser &&
                            <Typography className='reactivate-user-text'>
                                Please confirm that you want to reactivate this user and make any updates needed to their account information.
                            </Typography>
                        }
                        {!userToUpdate &&
                            <div className='create-user-instructions'>
                                <span>{`Please provide the following information to create a new user. If you'd like to create users in bulk (more than one at a time), you can email a csv to `}</span>
                                <span className='create-users-bold-text'>support@hilightedu.com</span>
                                <span>. Please make sure you provide a first name, last name, email, and school or department for the user. The support team will create the new users for you!</span>
                            </div>
                        }
                        {(!isCleverUser || reactivateUser) &&
                            <Box>
                                <TextField 
                                    label='First Name'
                                    variant='outlined'
                                    className='edit-user-field'
                                    value={firstNameField || ''}
                                    type='text'
                                    onChange={(e) => setFirstNameField(e.target.value)}
                                />
                                <TextField 
                                    label='Last Name'
                                    variant='outlined'
                                    className='edit-user-field'
                                    value={lastNameField || ''}
                                    type='text'
                                    onChange={(e) => setLastNameField(e.target.value)}
                                />
                                <TextField 
                                    label='Email'
                                    variant='outlined'
                                    className='edit-user-field'
                                    id='edit-user-email-field'
                                    value={emailField || ''}
                                    type='text'
                                    onChange={(e) => setEmailField(e.target.value)}
                                />
                                <Autocomplete
                                    disablePortal
                                    id='edit-user-school-field'
                                    className='edit-user-field'
                                    options={districtSchools}
                                    value={selectedSchool || null}
                                    getOptionLabel={(option) => option.name}
                                    renderInput={(params) => <TextField {...params} label='Select School' />}
                                    onChange={(event: any, value: School | null) => {
                                        setSelectedSchool(value || undefined)
                                    }}
                                />
                            </Box>
                        }
                        <TextField 
                            label='Role'
                            variant='outlined'
                            className='edit-user-field'
                            id='edit-user-role-field'
                            value={roleField || ''}
                            type='text'
                            onChange={(e) => setRoleField(e.target.value)}
                        />
                        <TextField
                            select
                            label='User Type'
                            id='edit-user-type-field'
                            value={selectedUserType}
                            className='edit-user-field'
                            onChange={(event: any) => {
                                const newValue = event.target.value;
                                if (newValue) {
                                    setSelectedUserType(newValue);
                                }
                            }}
                        >
                            <MenuItem key='admin' value='admin'>Admin</MenuItem>
                            <MenuItem key='teacher' value='teacher'> Standard</MenuItem>
                        </TextField>
                        {!isCleverUser && districtSchools && districtSchools?.length > 1 && allowMultipleSchools &&
                            <Accordion 
                                className='optional-fields-accordian'
                                elevation={0}
                            >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                >
                                <Typography className='optional-fields-header'>Optional Fields:</Typography>
                                {/* <span className='additional-schools-subheader'>(Click to expand)</span> */}
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div className='additional-schools-section'>
                                        <Typography className='additional-schools-header'>Additional Schools:</Typography>
                                        {/* add tooltip with  */}
                                        <div>
                                            <Typography className='additional-schools-instructions'>
                                                You can use the below drop down to assign this user to additional schools in your district. This is not required. It can be useful for staff who work in multiple school buildings.
                                                <Tooltip 
                                                    title={
                                                        <Typography> Remember, your staff can always send hilights across schools in your district even if they only belong to one school. You don't need to add schools here for that to work. We only recommend adding more than one school for a staff member if you want to make sure they are easy to find when someone is trying to hilight them. For example, if a school counselor works across multiple schools, and it's too confusing to assign them to only one.</Typography>
                                                    }
                                                    arrow={true}
                                                >
                                                    <HelpOutlineIcon className='additional-schools-help-icon'/>
                                                </Tooltip>
                                            </Typography>
                                            
                                        </div>
                                        <Autocomplete
                                            disablePortal
                                            multiple
                                            id='edit-user-school-field'
                                            className='edit-user-field'
                                            options={districtSchools.filter(school => school.id !== selectedSchool?.id)}
                                            isOptionEqualToValue={(option: School, value: School) => option.id === value.id}
                                            defaultValue={additionalSchools}
                                            filterSelectedOptions
                                            value={additionalSchools}
                                            getOptionLabel={(option) => option.name}
                                            renderInput={(params) => <TextField {...params} label='Select Additional Schools' />}
                                            onChange={(event: any, value: School[] | null) => {
                                                setAdditionalSchools(value || [])
                                            }}
                                        />
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                        }
                        {!userToUpdate &&
                            <Typography className='create-user-send-email-text'>When you click <span className='edit-user-bold-text'>Save</span>, an email will be sent to this new user with their login information and some instructions on how to get started using Hilight.</Typography>
                        }
                    </div>
                    }
                </DialogContent>
                <DialogActions>
                    <Button 
                        variant='contained'
                        onClick={handleClickSave}
                        disabled={
                            updateInProgress ||
                            !(firstNameField &&  firstNameField !== '' 
                            && lastNameField && lastNameField !== '' 
                            && emailField && emailField !== ''
                            && selectedSchool  
                            && selectedUserType)
                        }
                    >
                        {reactivateUser ? 'Reactivate' : 'Save'}
                    </Button>
                    <Button 
                        variant='contained'
                        onClick={handleClickClose}
                        disabled={updateInProgress}
                        id='cancel-edit-user-button'
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    )
}