import React, {useEffect, useState} from 'react';
import { Box, CircularProgress, Tab, Typography } from '@mui/material';
import { isMobile } from 'react-device-detect';
import { useSelector } from 'react-redux';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import * as Sentry from "@sentry/react";

import { getRedeemInstructions, RewardCardType, RewardsCard } from './RewardsCard';
import { PurchasedRewardDisplayData, RewardDisplayData } from '../../../../../src/types/rewards';
import { MainServiceApi } from '../../../services/mainService';
import { UserKredsCount } from '../../../../../src/types/user';
import { InstructionsPopup } from './InstructionsPopup';
import { authSelector } from '../../../store/authSlice';
import { UserType } from '../../../util/type';
import { ManageRewardsTab } from '../AdminSettings/ManageRewards/ManageRewardsTab';

import './Rewards.css';
import { PermissionServiceApi } from '../../../services/permissionService';

export interface RewardsProps {
    schoolId: number;
    userId: number;
    userKreds?: UserKredsCount;
    refreshDataAfterRewardRedeemed: () => Promise<void>;
}

export function Rewards(props: RewardsProps) {
    const { schoolId, userId, userKreds, refreshDataAfterRewardRedeemed } = props;

    const [tabValue, setTabValue] = useState('1');
    const [availableRewards, setAvailableRewards] = useState<RewardDisplayData[] | undefined>();
    const [purchasedRewards, setPurchasedRewards] = useState<PurchasedRewardDisplayData[] | undefined>();
    const [redeemRewardInProgress, setRedeemRewardInProgress] = useState(false);
    const availableRewardsLoading = availableRewards === undefined || userKreds === undefined || redeemRewardInProgress;
    const purchasedRewardsLoading = purchasedRewards === undefined || userKreds === undefined || redeemRewardInProgress;
    const [showRewardJustRedeemedPopup, setShowRewardJustRedeemedPopup] = useState(false);
    const [useRewardInstructions, setUseRewardInstructions] = useState<JSX.Element | undefined>();
    const authDataStore = useSelector(authSelector);
	
    const { user, token } = authDataStore;

    const userIsFreeUser = user?.userType === UserType.Free;

    const [userCanManageRewards, setUserCanManageRewards] = useState(user?.userType === UserType.Admin)
    
    useEffect(() => {
        loadRewards();
    }, [schoolId, userId, userKreds]) // Included userKreds here so that we re-load the purchased and available rewards if there is a change in their kreds earned

    const loadRewards = async () => {
        const mainServiceApi = MainServiceApi();

        try {
            // Get the available rewards for the school
            const availableRewards = await mainServiceApi.getAvailableRewardsForSchool(schoolId, token!)
            setAvailableRewards(availableRewards.sort((a, b) => a.cost - b.cost));

            // Get the purchased rewards for this user
            const purchasedRewards = await mainServiceApi.getPurchasedRewardsForUser(userId, schoolId, token!)
                // sort by the purchase date in descending order
            setPurchasedRewards(
                purchasedRewards.map((reward) => 
                    { 
                        return {
                            ...reward,
                            purchaseDate: new Date(reward.purchaseDate)
                        }
                    }
                ).sort((a, b) => 
                    b.purchaseDate.getTime() - a.purchaseDate.getTime()
                )
            );
        } catch(err) {
            // log the error
			Sentry.captureException(err);
        }
    }

  	const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
    	setTabValue(newValue);
  	};

    const handleRedeemReward = async (rewardId: number): Promise<void> => {
        setRedeemRewardInProgress(true);
        await new Promise(resolve => setTimeout(resolve, 1000)); // Looks bad if it loads for less than a second - probably a better way to do this?

        const mainServiceApi = MainServiceApi();
        let purchasedReward: PurchasedRewardDisplayData | undefined = undefined;
        try {
            purchasedReward = await mainServiceApi.redeemReward({userId, rewardId, schoolId}, token!);
        } catch (err) {
            // log the error
			Sentry.captureException(err);
        }
    
        refreshDataAfterRewardRedeemed();
        
        // Navigate to the Purcahsed Rewards tab
        setTabValue('2');
        
        // End loading
        setRedeemRewardInProgress(false);
        
        // Show the popup with instructions on what to do next
        if (purchasedReward) {
            setUseRewardInstructions(getRedeemInstructions(purchasedReward.rewardOwnerName, purchasedReward.redeemInstructions))
            setShowRewardJustRedeemedPopup(true);
        }
	}

    // Permissions
    const getUserLevelPermissions = async (userId: number, token: string) => {
        // Currently only need to do this for non-admin users
        // If we ever need to check other permissions, remove this if statement

        if (user?.userType !== UserType.Admin) {
            const permissionsServiceApi = PermissionServiceApi();

            try {
                const userPermissions = await permissionsServiceApi.getPermissionsForUser(userId, token);

                const manageRewardsKey = 'USER_MANAGE_REWARDS_DISRICT';
                const userHasRewardsPermission = userPermissions.find(permission => permission.permissionKey === manageRewardsKey);
                if (userHasRewardsPermission) {
                    setUserCanManageRewards(true);
                }
            } catch (err) {
                // log the error
                Sentry.captureException(err);
            }
        }
    }
    useEffect(() => {
        getUserLevelPermissions(userId, token!);
    }, [userId])

    let pointsToRedeemText = <></>;
    if (userKreds?.currentPoints === 0) {
        pointsToRedeemText = <Typography className='rewards-points-text'>{`You don't currently have any hilight points to redeem for rewards. Every time you earn a hilight, you earn one hilight point.`}</Typography>
    }
    if (userKreds?.currentPoints && userKreds.currentPoints > 0) {
        pointsToRedeemText = 
            <Typography>
                You have <span className='number-of-hilights'>{userKreds?.currentPoints}</span> points to redeem for rewards!
            </Typography>
    }

    return (
        <div className='rewards-page'>
            <Box className='page-heading'>Rewards</Box>
            {/* Redeem Rewards popup - shows up after a user has redeemed a reward */}
            <InstructionsPopup 
                open={showRewardJustRedeemedPopup}
                handleClosePopup={() => { setShowRewardJustRedeemedPopup(false)}}
                popupHeader='Reward Redeemed Successfully!'
                popupContent={
                    <Typography>
                        <p className='popup-bold'>Congrats on redeeming your reward! You deserve it!</p>
                        {useRewardInstructions}
                        <div><p>You can view these instructions at any time by clicking on the <b>View Redeem Instructions</b> button that will now appear on your used reward.</p></div>
                    </Typography>
                }
            />
            {/* Rewards tabs and page */}
            <TabContext value={tabValue}>
                <Box className={isMobile ? 'rewards-grid-mobile' : 'rewards-grid'}>
                    <Box className='rewards-tabs'>
                        <TabList 
                            onChange={handleChangeTab} 
                            aria-label="basic tabs example"
                            variant={isMobile ? "scrollable" : 'standard'}
                            scrollButtons={isMobile ? true : false}
                            allowScrollButtonsMobile
                        >
                            <Tab label="Available Rewards" value='1' />
                            <Tab label="My Purchased Rewards" value='2' />
                            {userCanManageRewards && 
                                <Tab label='Manage Rewards' value='3'/>
                            }
                        </TabList>
                    </Box>
                </Box>
                <TabPanel value='1' className='available-rewards-tab'>
                    {pointsToRedeemText}
                    <Box className={`rewards-list ${(availableRewardsLoading || availableRewards?.length === 0) ? 'reward-loading' : ''} `}>
                        {
                            userIsFreeUser ?
                                <Typography>The Rewards Marketplace is only enabled when your school or district has purchased the premium version of Hilight. Want to learn more or request more information? Visit our <a href='https://www.hilightedu.com'>website</a>. </Typography>
                            : 

                            availableRewardsLoading ? (<CircularProgress className='reward-loading' />) :
                                (
                                    availableRewards.length === 0 ? <Typography className='no-rewards'>{'Rewards coming soon!'}</Typography> : 
                                    availableRewards.map((availableReward) => 
                                        <RewardsCard 
                                            imageUrl={availableReward.imageUrl}
                                            rewardDisplayName={availableReward.displayName}
                                            rewardDescription={availableReward.description}
                                            rewardCost={availableReward.cost}
                                            rewardId={availableReward.id}
                                            rewardCardType={RewardCardType.Available}
                                            rewardLimitCount={availableReward.availableCount}
                                            key={availableReward.id}
                                            userId={userId}
                                            userPointsToSpend={userKreds?.currentPoints}
                                            handleRedeemReward={handleRedeemReward}
                                        />
                                    )
                                )
                        }
                    </Box>
                </TabPanel>
                <TabPanel value='2'>
                    <Box className={`purchased-rewards-list ${(availableRewardsLoading || purchasedRewards?.length === 0) ? 'reward-loading' : ''}`}>
                        { (purchasedRewardsLoading) ? (<CircularProgress className='reward-loading'/>) :
                            (
                                purchasedRewards.length === 0 ? <Typography className='no-rewards'>No Rewards to Display</Typography> :
                                purchasedRewards.map((purchasedReward) => 
                                    <RewardsCard 
                                        imageUrl={purchasedReward.imageUrl}
                                        rewardDisplayName={purchasedReward.displayName}
                                        rewardDescription={purchasedReward.description}
                                        rewardCost={purchasedReward.cost}
                                        purchasedRewardId={purchasedReward.id}
                                        rewardId={purchasedReward.rewardId}
                                        rewardCardType={RewardCardType.Purchased}
                                        purchaseDate={purchasedReward.purchaseDate}
                                        key={purchasedReward.id}
                                        userId={userId}
                                        userPointsToSpend={userKreds?.currentPoints}
                                        handleRedeemReward={handleRedeemReward}
                                        rewardOwnerName={purchasedReward.rewardOwnerName}
                                        redeemInstructions={purchasedReward.redeemInstructions}
                                    />
                                )
                            )
                        }
                    </Box>
                </TabPanel>
                <TabPanel value='3'>
                    <ManageRewardsTab districtId={user!.districtId} rewardsChanged={loadRewards}/>
                </TabPanel>
            </TabContext>
        </div>
        
    )
}

