import React, { useState } from 'react';

import {Card, CardMedia, CardContent, Typography, CardActions, Button, Tooltip } from '@mui/material';
import { StyledEngineProvider } from '@mui/material/styles';
import InfoIcon from '@mui/icons-material/Info';

import './RewardsCard.css';
import { ConfirmationPopup } from './ConfirmationPopup';
import { InstructionsPopup } from './InstructionsPopup';

export enum RewardCardType {
  Available,
  Purchased
}

export interface RewardsCardProps {
	rewardCardType: RewardCardType;
    imageUrl: string,
    rewardDisplayName: string;
    rewardDescription: string;
    rewardCost: number;
    rewardId: number;
	rewardLimitCount?: number | null;
	handleRedeemReward: (rewardId: number) => void;
	// Additional fields for purchased rewards
    purchaseDate?: Date;
	purchasedRewardId?: number;
	redeemInstructions?: string;
	rewardOwnerName?: string;
	// Info about the user
	userId: number;
	userPointsToSpend?: number;
}

export function RewardsCard(props: RewardsCardProps) {
	const {
		rewardCardType,
		imageUrl,
		rewardDisplayName,
		rewardDescription,
		rewardCost,
		rewardId,
		rewardLimitCount,
		purchaseDate,
		purchasedRewardId,
		redeemInstructions,
		rewardOwnerName,
		handleRedeemReward,
		userPointsToSpend,
	} = props;

	const buttonText = rewardCardType === RewardCardType.Available  ? 'Redeem' : 
		'View Redeem Instructions';
	const hasEnoughKredsToRedeem = userPointsToSpend ? rewardCost <= userPointsToSpend : false;

	// Redeem Reward Confirmation Popup
	const [showRedeemConfirmationPopup, setShowRedeemConfirmationPopup] = useState(false);
	const handleClickRedeemReward = async (): Promise<void> => {
		setShowRedeemConfirmationPopup(true);
	}
	const handleClickConfirmRedeemReward = async (): Promise<void> => {
		setShowRedeemConfirmationPopup(false);
		await handleRedeemReward(rewardId);
	}

	const [showRedeemInstructionsPopup, setShowRedeemInstructionsPopup] = useState(false);
	const handleClickViewInstructions = async (): Promise<void> => {
		setShowRedeemInstructionsPopup(true);
	}

	const redeemButtonDisabled = rewardCardType === RewardCardType.Available && !hasEnoughKredsToRedeem;

	return (
		<StyledEngineProvider injectFirst>
			{/* Redeem Reward Confirmation */}
			<ConfirmationPopup 
				open={showRedeemConfirmationPopup}
				handleCancelConfirmation={() => { setShowRedeemConfirmationPopup(false)}}
				handleSubmitConfirmation={() => handleClickConfirmRedeemReward()}
				popupContent={
					<Typography>
                        Please confirm that you would like to redeem the <span className='popup-bold popup-purple-text'>{rewardDisplayName}</span> reward for <span className='popup-bold popup-orange-text'>{rewardCost} </span> Tokens.
                    </Typography>
				}
				popupHeader={'Confirm Redeem Reward'}
			/>
			{/* View Redeem Instructions Popup */}
			<InstructionsPopup
				open={showRedeemInstructionsPopup}
				handleClosePopup={() => { setShowRedeemInstructionsPopup(false)}}
				popupHeader='How to Redeem your Reward'
				popupContent={
					<Typography>{getRedeemInstructions(rewardOwnerName, redeemInstructions)}</Typography>

				} 
			/>
			<Card className='rewards-card'>
				<CardMedia
					component="img"
					height="125"
					image={imageUrl}
					alt={rewardDisplayName}
				/>
				<CardContent className='rewards-card-content'>
					<Typography variant="body1" color='primary' component="div" className='rewards-card-title'>
						{rewardDisplayName}
					</Typography>
					<Typography  color="text.secondary">
						{rewardCardType === RewardCardType.Available ?
						` ${rewardCost} points`
						: `You spent ${rewardCost} points on this` }
					</Typography>
					<div className='rewards-card-additional-fields'>
						<Typography variant="body2" className='rewards-purchase-date'>
						{purchaseDate?.toLocaleDateString('en-US', {month: 'long', year: 'numeric', day: 'numeric'})}
						</Typography>
						<Typography variant='body2' color='text.secondary'>
							{rewardLimitCount && `${rewardLimitCount} available`}
						</Typography>
					</div>
				</CardContent>
				<CardActions className='rewards-card-actions'>
						<Button 
							variant='contained'
							className={`rewards-card-button ${!redeemButtonDisabled && 'rewards-card-button-enabled'}`}  
							disabled={redeemButtonDisabled}
							onClick={purchasedRewardId ? handleClickViewInstructions : handleClickRedeemReward}
						>
							{buttonText}
						</Button>
					
					{purchasedRewardId !== undefined ||
						<div className='reward-info'>
							<Tooltip title={<Typography>{rewardDescription && rewardDescription !== '' ? rewardDescription : 'No additional information provided about this reward.'}</Typography>}>
								<InfoIcon className='reward-info-icon' color='primary'/>
							</Tooltip>
						</div>
					}
				</CardActions>
				
			</Card>
		</StyledEngineProvider>
    );
}

export function getRedeemInstructions(ownerOfRewardFullName?: string, optionalInstructions?: string): JSX.Element {
	if (ownerOfRewardFullName) {
		if (!optionalInstructions || optionalInstructions === '') {
			return (
				<Typography>
					<p className='popup-bold'>The person responsible for distributing this reward is <span className='popup-purple-text'>{ownerOfRewardFullName}</span>. They have been notified via email.</p>
					<p>If you have not received your reward in a timely manner, please reach out to them. </p> 
				</Typography>
			)
		} else {
			return (
				<Typography>
					<p className='popup-bold'>The person responsible for distributing this reward is <span className='popup-purple-text'>{ownerOfRewardFullName}</span>. They have been notified via email and have provided the following instructions for obtaining your reward: </p>
					<p>{optionalInstructions}</p>
				</Typography>
			)
		}
	}

	// If for some reason the reward owner name is not populated (this shouldn't happen)
	return (
		<Typography>
			The person responsible for distributing this reward at your school has been notified. If you have not received your reward in a timely manner, please contact the Hilight facilitators at your school.
		</Typography>
	)
}