import React from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography, IconButton } from '@mui/material';
import IosShareIcon from '@mui/icons-material/IosShare';
import CloseIcon from '@mui/icons-material/Close';

import Logo from '../images/hilight_hi_logo.png';

import './AddToHomeScreenPopup.css';

export interface AddToHomeScreePopupProps {
    popupOpen: boolean;
    closePopup: () => void;
}

export default function AddToHomeScreenPopup(props: AddToHomeScreePopupProps) {
    const { popupOpen, closePopup } = props;

    return (
        <Box>
            <Dialog open={popupOpen} onClose={closePopup}>
                <DialogTitle>
                <Box className='add-to-homescreen-header'>
                    <Box
                        component="img"
                        className='add-to-homescreen-logo'
                        alt="hilight logo."
                        src={Logo}
                    />
                    <Typography className='add-to-homescreen-header-text'>
                        Install Hilight
                    </Typography>
                </Box>
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={closePopup}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: 'grey',
                    }}
                    >
                    <CloseIcon />
                </IconButton>
                <DialogContent className='add-to-homescreen-content'>
                    <Typography>Install Hilight on your mobile device for quick and easy access at any time!</Typography>
                    <Typography className='add-to-homescreen-instructions-text'>Just tap <IosShareIcon/> and 'Add to Home Screen'</Typography>
                    <Typography className='add-to-homescreen-subtext'>Can't find the share icon? Look for it in the top or bottom bar of your current screen next to where the url is. You might have to scroll up and down a little bit to get the url bar to appear.</Typography>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant='contained'
                        size='small'
                        onClick={closePopup}
                        id='close-homescreen-popup-button'
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    )
}