import React, {useEffect, useState} from 'react';

import { Box } from '@mui/material';
import { PieChart, Pie, Label, Cell, ResponsiveContainer, Legend } from 'recharts';

import { DefaultColor } from './Data';

import './UserKredsPieChart.css';

// functions for pie chart
const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, value, index }: { cx: number, cy: number, midAngle: number, innerRadius: number, outerRadius: number, value: number, index: number }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
        x={x}
        y={y}
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline="central"
        className='pie-chart-label'
    >
      {value}
    </text>
  );
};

export interface UserKredsPieChartProps {
    data: {
        tokenCoreValue: string;
        count: number;
        tokenId: number;
    }[];
    chartLegend: {
        id: string,
        color: string,
        value: string
    }[];
    printView?: boolean;
}

export function UserKredsPieChart(props: UserKredsPieChartProps) {
    const {data, chartLegend, printView} = props;

    const [totalKredsCount, setTotalKredsCount] = useState(0);
   

    useEffect(() => {
        if (data && data.length > 0) {
            let count = 0;
            for (const tokenTypeData of data) {
                count += tokenTypeData.count
            }
            setTotalKredsCount(count);
        } else {
            setTotalKredsCount(0);
        }
        
    }, [data]);

    return (
        <Box className={printView ? 'pie-chart-box-print' : 'pie-chart-box'}>
            <ResponsiveContainer width={printView ? 400 : "100%"} height={printView ? 500 : "100%"}>
                    <PieChart>
                        <Pie
                            data={data}
                            labelLine={false}
                            label={renderCustomizedLabel}
                            outerRadius={'90%'}
                            innerRadius={'45%'}
                            dataKey="count"
                            key={Math.random()} // Used to make the animation work even with re-render
                        >
                            {
                                data.map((entry, index) => {
                                    const tokenType = chartLegend.find((tokenType) => tokenType.value === entry.tokenCoreValue);
                                    return <Cell key={`cell-${index}`} fill={tokenType ? tokenType.color : DefaultColor} />
                                })
                            }
                            <Label 
                                fill='black'
                                position='center'
                                id='total-kreds-pie-chart-label-count'
                            >{totalKredsCount}</Label>
                            <Label 
                                fill='black'
                                position='center'
                                id='total-kreds-pie-chart-label-text'
                            > TOTAL </Label>

                        </Pie>
                        <Legend payload={chartLegend}/>
                    </PieChart>
            </ResponsiveContainer>
        </Box>
    )
}
