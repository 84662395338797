import React, { useState, useEffect } from 'react';
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector } from 'react-redux';
import * as Sentry from "@sentry/react";

import { UserServiceApi } from '../../../services/userService';
import { authSelector } from '../../../store/authSlice';

import './ChangePasswordPopup.css';

export interface ChangePasswordPopupProps {
    userId: number;
    popupOpen: boolean;
    closePopup: () => void
}
export default function ChangePasswordPopup(props: ChangePasswordPopupProps) {
    const {userId, popupOpen, closePopup} = props;
    const [newPassword, setNewPassword] = useState<string | undefined>();
    const [confirmedPassword, setConfirmedPassword] = useState<string | undefined>();
    const [newPasswordIsValid, setNewPasswordIsValid] = useState(false);
    const [showNonMatchingPasswordsMessage, setShowNonMatchingPasswordsMessage] = useState(false);
    const [changePasswordInProgress, setChangePasswordInProgress] = useState(false);
    const [changePasswordComplete, setChangePasswordComplete] = useState(false);

    const authDataStore = useSelector(authSelector);
	const { token } = authDataStore;

    // State for the password requirements
    const [passwordLengthValid, setPasswordLengthValid] = useState(false);
    const [passwordUppercaseValid, setPasswordUppercaseValid] = useState(false);
    const [passwordLowercaseValid, setPasswordLowercaseValid] = useState(false);
    const [passwordNumberValid, setPasswordNumberValid] = useState(false);
    const [passwordSpecialCharValid, setPasswordSpecialCharValid] = useState(false);

    const handleClickSave = async () => {
        setChangePasswordInProgress(true);
        try {
            if (newPassword) {
                const userServiceApi = UserServiceApi();
                await userServiceApi.changeUserPassword(userId, newPassword, token!);
                // Should we call verify token to get a new token?
            }
        } catch (err) {
            // log the error
			Sentry.captureException(err);
        }
        setChangePasswordComplete(true);
        setChangePasswordInProgress(false);
    }
    
    const handleClickCancel = () => {
        // Close the popup
        closePopup();

        // reset the popup values
        setNewPassword(undefined);
        setConfirmedPassword(undefined);
        setChangePasswordComplete(false);
    }

    useEffect(() => {
        setNewPasswordIsValid(
            newPassword === confirmedPassword
            && passwordLengthValid
            && passwordUppercaseValid
            && passwordLowercaseValid
            && passwordNumberValid
            && passwordSpecialCharValid
        );

        if (confirmedPassword) {
            setShowNonMatchingPasswordsMessage(confirmedPassword !== newPassword);
        } else {
            setShowNonMatchingPasswordsMessage(false);
        }
    }, [
        newPassword,
        confirmedPassword,
        passwordLengthValid,
        passwordUppercaseValid,
        passwordLowercaseValid,
        passwordNumberValid,
        passwordSpecialCharValid
    ]);

    useEffect(() => {
        setPasswordLengthValid(newPassword !== undefined && newPassword.length >= 8 && newPassword.length <= 50);
        setPasswordUppercaseValid(Boolean(newPassword?.match(/[A-Z]/)));
        setPasswordLowercaseValid(Boolean(newPassword?.match(/[a-z]/)));
        setPasswordNumberValid(Boolean(newPassword?.match(/\d/)));
        setPasswordSpecialCharValid(Boolean(newPassword?.match(/[!#$%&*?]/)));
    }, [newPassword])

    return (
        <Box>
            <Dialog open={popupOpen} onClose={handleClickCancel}>
                <DialogTitle>Change Password</DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClickCancel}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: 'grey',
                    }}
                    >
                    <CloseIcon />
                </IconButton>
                <DialogContent className='change-password-popup-content'>
                    {changePasswordComplete ? 
                        <div>
                            <Typography>
                                Password successfully changed!
                            </Typography>
                        </div> :
                        changePasswordInProgress ? <CircularProgress /> :
                            <div>
                                <div>
                                    <Typography>
                                        Enter your new password below. It must meet the following requirements:
                                    </Typography>
                                    <Typography color={passwordLengthValid ? 'green' : 'red'} className='change-password-requirement'>
                                        - At least 8 characters but less than 50 characters
                                    </Typography>
                                    <Typography color={passwordUppercaseValid ? 'green' : 'red'} className='change-password-requirement'>
                                        - At least one uppercase letter
                                    </Typography>
                                    <Typography color={passwordLowercaseValid ? 'green' : 'red'} className='change-password-requirement'>
                                        - At least one lowercase letter
                                    </Typography>
                                    <Typography color={passwordNumberValid ? 'green' : 'red'} className='change-password-requirement'>
                                        - At least one number 
                                    </Typography>
                                    <Typography color={passwordSpecialCharValid ? 'green' : 'red'} className='change-password-requirement'>
                                        - At least one special character (#, $, %, !, &, *, ?)
                                    </Typography>
                                </div>
                                
                                    <Box className='change-passwords-inputs'>
                                        <div className='change-password-item'>
                                            <TextField
                                                variant='outlined'
                                                className='change-password-field'
                                                type='password'
                                                name='new-password'
                                                id='new-password-input'
                                                label='New password'
                                                onChange={(e) => setNewPassword(e.target.value)}
                                            />
                                        </div>
                                        <div className='change-password-item'>
                                            <TextField 
                                                variant='outlined'
                                                className='change-password-field'
                                                type='password'
                                                name='confirm-password'
                                                id='confirm-new-password-input'
                                                label='Confirm new password'
                                                onChange={(e) => setConfirmedPassword(e.target.value)}
                                            />
                                            <Typography 
                                                className='change-password-error-message'
                                                hidden={!showNonMatchingPasswordsMessage}
                                            >
                                                Confirmed password does not match
                                            </Typography>
                                        </div>
                                    </Box>
                                </div>
                    }
                    
                </DialogContent>
                <DialogActions>
                    {
                        !changePasswordComplete ?
                        <>
                            <Button 
                                variant='contained'
                                onClick={handleClickSave}
                                disabled={!newPasswordIsValid || changePasswordInProgress}
                            >
                                Save
                            </Button>
                            <Button 
                                variant='contained'
                                onClick={handleClickCancel}
                                disabled={changePasswordInProgress}
                                id='cancel-change-password-button'
                            >
                                Cancel
                            </Button>
                        </> :
                        <>
                            <Button 
                                variant='contained'
                                onClick={handleClickCancel}
                                id='cancel-change-password-button'
                            >
                                Close
                            </Button>
                        </>
                    }
                    
                </DialogActions>
            </Dialog>
        </Box>
        
    )
}