import React, {useEffect, useState} from 'react';

import * as Sentry from "@sentry/react";
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useLocation, useNavigate } from 'react-router-dom';
import { Divider, IconButton, InputAdornment } from '@mui/material';
import { isMobile } from 'react-device-detect';
import { CredentialResponse, GoogleLogin } from '@react-oauth/google';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import Logo from '../../images/hilight_hi_logo.png';
import cleverIcon from '../../images/clever-mark-blue.png';
import { Copyright } from '../Copyright';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import ForgotPasswordPopup from './ForgotPasswordPopup';
import { authSelector, userGoogleLogin, userLogin } from '../../store/authSlice';
import { getCleverAuthorizeUrl } from '../../util/cleverConfig';
import PrivacyPolicyPopup from '../PrivacyPolicy/PrivacyPolicyPopup';

import './Login.css';


const theme = createTheme({
	typography: {
	  fontFamily: 'Catamaran',
	},
	palette: {
		secondary: {
			main:  '#240145'
		},
		primary: {
			dark:  '#240145',
			main:  '#240145'
		}
	}
});

export interface LoginProps {
	showMobileInstall: boolean;
	handleClickMobileInstall?: () => Promise<void>;
}

export default function Login(props: LoginProps) {
	const {showMobileInstall, handleClickMobileInstall} = props;
    const navigate = useNavigate();
	const location = useLocation();
	const [invalidLoginMessage, setInvalidLoginMessage] = useState<string| undefined>();
	const [showForgotPasswordPopup, setShowForgotPasswordPopup] = useState(false);
	const [showPassword, setShowPassword] = useState(false);

	// In the PrivateRoute component, we save the previous url on location.state if the user was trying to navigate to a private url without logging in
	// That way we can redirect them to that url after logging in
	// @ts-ignore: Property 'previousUrl' does not exist on type {}.
	const previousUrl = location?.state?.previousUrl;
	

	// The next two are for accessing and updating the authentication data
	const authDataStore = useAppSelector(authSelector);
	const dispatch = useAppDispatch();
	const { isAuthenticated, loginError } = authDataStore;

	// privacy policy popup
	const [showPrivacyPopup, setShowPrivacyPopup] = useState(false);

	useEffect(() => {
		// redirect the user if they are already logged in
		if (!loginError && isAuthenticated) {
			navigate('/teacherdashboard/recognition');
		}
	}, []); // only on first render

	useEffect(() => {
		setInvalidLoginMessage(authDataStore.loginError);

		if (!loginError && isAuthenticated) {
			// If the user was trying to navigate to a different private url before logging in, previousUrl will be populated and we should send them there
			if (previousUrl 
				&& previousUrl !== '/'  // shouldn't happen because previousUrl is only populated from trying to naviate to a private route 
				&& previousUrl !== '/login' // also shouldn't happen (same reason)
			) {
				navigate(previousUrl);
			} else {	
				navigate('/teacherDashboard/recognition')
			}
		}
		
	}, [isAuthenticated, loginError]);

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        
        try {
            const userEmail = data.get('email')?.toString();
            const userPassword = data.get('password')?.toString();
            if (!userEmail || !userPassword) {
                throw new Error('Login did not succeed');
            }
            dispatch(userLogin({username: userEmail, password: userPassword}));

        } catch (err) {
            // Any errors from login should be caught by the reducer in authSlice.ts
			// But just in case, we should just reload the page
			navigate('/login');
			// log the error because it should have been caught
			Sentry.captureException(err);
        }
        
    };

	const handleGoogleLoginSuccess = async (response: CredentialResponse) =>  {
		// Call our login service with the credential from the response, and login the user if they have a hilight account
		try {
			if (response.credential) {
				dispatch(userGoogleLogin(response.credential));
			}
			// if no credentials returned, then don't do anything
			// TO DO - maybe show a message to the user?
		} catch (err)  {
			// Any errors from login should be caught by the reducer in authSlice.ts
			// But just in case, we should just reload the page
			navigate('/login');
			// log the error because it should have been caught
			Sentry.captureException(err);
		}
	}

	const handleGoogleLoginError = () => {
		// if there was an error with google login, it's happening on a separate tab (the tab that gets opened for google login)
		// we shouldn't do anything back on the login page, so just leave this blank
	}

	const handleClickShowPassword = () => setShowPassword((show) => !show);

	const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
	};

  	return (
		<ThemeProvider theme={theme}>
			<PrivacyPolicyPopup popupOpen={showPrivacyPopup} closePopup={() => {setShowPrivacyPopup(false)}} />
			<Grid container component="main" className='login-grid'>
				<Grid
					item
					xs={12}
					sm={12}
					md={6}
					className='login-display login-grid-item'
				>
					<Box>
						<Box
							component="img"
							className='login-logo'
							alt="hilight logo."
							src={Logo}
						/>
						<Typography variant='h1' className='login-company-name'>hilight</Typography>
					</Box>
				</Grid>
				<Grid item xs={12} sm={12} md={6} className='login-grid-item'>
					<Box className='login-box'>
						<ForgotPasswordPopup 
							popupOpen={showForgotPasswordPopup} 
							closePopup={() => setShowForgotPasswordPopup(false)}
						/>
						<Avatar className='login-icon'>
							<LockOutlinedIcon/>
						</Avatar>
						<Typography component="h1" variant="h5">
							Sign in
						</Typography>
						<Box component="form" noValidate onSubmit={handleSubmit} className='login-form'>
							<TextField
								required
								fullWidth
								id="email"
								label="Email Address"
								name="email"
								autoComplete="email"
								autoFocus
								className='login-form-item'
							/>
							<TextField
								required
								fullWidth
								name="password"
								label="Password"
								type={showPassword ? 'text' : 'password'}
								id="password"
								autoComplete="current-password"
								className='login-form-item'
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<IconButton
												aria-label="toggle password visibility"
												onClick={handleClickShowPassword}
												onMouseDown={handleMouseDownPassword}
												edge="end"
											>
											{showPassword ? <VisibilityOff /> : <Visibility />}
											</IconButton>
										</InputAdornment>
									)
								}}
							/>
							<Button
								type="submit"
								variant="contained"
								className='login-button'
							>
								Sign In
							</Button>
							<Box hidden={invalidLoginMessage === undefined}>
								<Typography className='invalid-password-text'>
									{invalidLoginMessage}
								</Typography>
							</Box>
							<Box className='login-forgot-password'>
								<Button className='forgot-password-button' onClick={() => setShowForgotPasswordPopup(true)}>
									Forgot password?
								</Button>
							</Box>
							<Divider className='login-divider'/>
							<Box className='login-box clever-box'>
								<Typography className='login-box-title'>
									Or sign in with:
								</Typography>
								<Box className='third-party-login-button'>
									<Button 
										href={getCleverAuthorizeUrl(window.location.origin)}
										variant='outlined'
										startIcon={<Box component={"img"} className='clever-logo' src={cleverIcon}/>}
										className='clever-login-button'
									>
										Clever
									</Button>
								</Box>
								<Box className='third-party-login-button'>
									<GoogleLogin width={'200px'} onSuccess={handleGoogleLoginSuccess} onError={handleGoogleLoginError} />
								</Box>
							</Box>
							<Typography className='login-help-text'>Need help? Contact us at support@hilightedu.com</Typography>
							{(showMobileInstall && isMobile && handleClickMobileInstall) && 
								<div>
									<Divider className='login-divider'/>
									<Box className='login-box'>
										<Typography className='login-box-title'>
											Click here to install Hilight on your mobile device:
										</Typography>
										<Button onClick={handleClickMobileInstall} variant='outlined' className='install-mobile-button'>Install</Button>
									</Box>
								</div>
							}
							<Box className='footer'>
								<Button className='privacy-policy-button' size='small' onClick={() => {setShowPrivacyPopup(true)}}>Privacy Policy</Button>
								<Typography> | </Typography>
								<Copyright className='copy-right-footer' />
							</Box>
						</Box>
					</Box>
				</Grid>
			</Grid>
		</ThemeProvider>
  );
}