import * as Sentry from '@sentry/react'
import { createRoutesFromChildren, useLocation, useNavigationType, matchRoutes } from "react-router-dom";
import React, {useEffect} from 'react';

// Initialize frontend error logging via Sentry
Sentry.init({
	dsn: "https://6881fa6f2f09ef186c48e32d794297ac@o4507341412696064.ingest.us.sentry.io/4507341522731008",
	environment: process.env.NODE_ENV,
	integrations: [
        Sentry.reactRouterV6BrowserTracingIntegration({
            useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes,
          }),      
	  	Sentry.replayIntegration(),
	],
	// Performance Monitoring
	tracesSampleRate: 0.25, //  Capture 25% of the transactions
	// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
	tracePropagationTargets: ["localhost", /^\/api/],
	// Session Replay
	replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
	replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});