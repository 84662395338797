import React from 'react';
import  { Navigate } from 'react-router-dom';

/**
 * Handles the Public routes
 * Use to access the routes without login token only. So if user is already logged-in then we will redirect it to the dashboard page.
 */


export function PublicRoute({children,  isAuthenticated}: {children: any, isAuthenticated: boolean}): JSX.Element {
    // If the user is not authenticated, pass through to the children
    // If the user is authenticated and they are trying to go to a public route, send them to their dashboard
    //return !isAuthenticated ? children : <Navigate to="/teacherDashboard/recognition" />;

    return children;
}