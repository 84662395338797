import React, {useState} from 'react';
import { isMobile } from 'react-device-detect';
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector } from 'react-redux';
import * as Sentry from "@sentry/react";

import { LoginServiceApi } from '../../services/loginService';
import { authSelector } from '../../store/authSlice';

import './ForgotPasswordPopup.css';

export interface ForgotPasswordPopupProps {
    popupOpen: boolean;
    closePopup: () => void;
}

export default function ForgotPasswordPopup(props: ForgotPasswordPopupProps) {
    const {popupOpen, closePopup} = props;
    const [userEmail, setUserEmail] = useState<string | undefined>();
    const [emailIsValid, setEmailIsValid] = useState(false);
    const [inProgress, setInProgress] = useState(false);
    const [emailSent, setEmailSent] = useState(false);
    const [showError, setShowError] = useState(false);
    const [userNotFound, setUserNotFound] = useState(false);

    const authDataStore = useSelector(authSelector);
	const { token } = authDataStore;

    const handleClickSend = async () => {
        setInProgress(true);
  
        if (userEmail) {
            try {
                const loginService = LoginServiceApi();
                const result = await loginService.sendForgotPasswordEmail(userEmail, token!);
                if (result?.userNotFound) {
                    setShowError(true);
                    setUserNotFound(true);
                }

                setEmailSent(true);
            } catch (err) {
                setShowError(true);

                // log the error to Sentry
                Sentry.captureException(err);
            } 
        }
        
        setInProgress(false);
    }

    const validateEmail = (email?: string) => {
        if (email) {
            setEmailIsValid(email.indexOf('@') !== -1);
        }

        setUserEmail(email);
    }

    const resetPopup = () => {
        setEmailSent(false);
        setShowError(false);
        setEmailIsValid(false);
        setUserNotFound(false);
    }
    
    const handleClickCancel = () => {
        closePopup();

        resetPopup();
    }

    const handleClickClose = () => {
        closePopup();

        resetPopup();
    }

    if (showError) {
        return (
            <Box>
                <Dialog open={popupOpen} onClose={handleClickClose}>
                    <DialogTitle>{'Whoops! Something went wrong!'}</DialogTitle>
                    <IconButton
                        aria-label="close"
                        onClick={handleClickClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: 'grey',
                        }}
                        >
                        <CloseIcon />
                    </IconButton>
                    <DialogContent className={isMobile ? 'forgot-password-dialog-content-mobile' : 'forgot-password-dialog-content'}>
                        { userNotFound ? 
                            <Typography>
                                A user was not found in Hilight for the given email. Please close this window and try again.
                            </Typography>
                        : 
                            <Typography>
                                Something went wrong when trying to reset you password. Please close this window and try again.
                            </Typography>
                        }
                    </DialogContent>
                    <DialogActions>
                        <Button 
                            variant='contained'
                            onClick={handleClickClose}
                        >
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </Box>
        )
    }

    if (emailSent) {
        return (      
            <Box>

                <Dialog open={popupOpen} onClose={handleClickClose}>
                    <DialogTitle>{'Email sent'}</DialogTitle>
                    <IconButton
                        aria-label="close"
                        onClick={handleClickClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: 'grey',
                        }}
                        >
                        <CloseIcon />
                    </IconButton>
                    <DialogContent className={isMobile ? 'forgot-password-dialog-content-mobile' : 'forgot-password-dialog-content'}>
                        <Typography>
                            Please check your email in order to retrieve your password. It might take a couple minutes to show up in your inbox.
                            If you need additional help, please reach out to support@hilightedu.com .
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button 
                            variant='contained'
                            onClick={handleClickClose}
                        >
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </Box>
        )
    }

    return (
        <Box>
            <Dialog open={popupOpen} onClose={handleClickClose}>
                <DialogTitle>Forgot Password</DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClickClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: 'grey',
                    }}
                    >
                    <CloseIcon />
                </IconButton>
                <DialogContent className={isMobile ? 'forgot-password-dialog-content-mobile' : 'forgot-password-dialog-content'}>
                    {inProgress ? <Box className='forgot-password-progress'><CircularProgress /> </Box> :
                        <Box>
                            <Typography>Enter your email address below, and we will send you an email to retrieve your password.</Typography>
                            <Box className='forgot-password-email'>
                                <TextField
                                    variant='outlined'
                                    className='forgot-password-email-field'
                                    type='text'
                                    name='user-email'
                                    id='user-email-input'
                                    label='Your email'
                                    onChange={(e) => validateEmail(e.target.value)}
                                />
                            </Box>
                        </Box>   
                    }
                </DialogContent>
                <DialogActions>
                    <Button 
                        variant='contained'
                        onClick={handleClickSend}
                        disabled={!emailIsValid || inProgress}
                    >
                        Send
                    </Button>
                    <Button 
                        variant='contained'
                        onClick={handleClickCancel}
                        disabled={inProgress}
                        id='cancel-forgot-password-button'
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );

}