import { configureStore } from '@reduxjs/toolkit';
import authReducer from './authSlice';

// Create an initialize the store
export const store = configureStore({
    reducer: {
        authReducer
    }
});

// Define these types for our specific store
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;