const cleverClientId = 'ec4bb9771a67dec1f0f0';
const cleverRedirectUriPath = 'oath/clever';
const cleverAuthorizeUrl = 'https://clever.com/oauth/authorize';

export function getCleverAuthorizeUrl(baseUrl: string): string {
    const encodedBaseUrl = encodeURIComponent(baseUrl);
    const fullCleverAuthorizeUrl = `${cleverAuthorizeUrl}?response_type=code&`
    + `redirect_uri=${encodedBaseUrl}/${cleverRedirectUriPath}&`
    + `client_id=${cleverClientId}`;
    return fullCleverAuthorizeUrl
}
