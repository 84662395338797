import React, {useEffect, useRef, useState} from 'react';
import * as Sentry from "@sentry/react";
import { Box, FormControl,  MenuItem, Select, SelectChangeEvent, IconButton, Tooltip, Tab } from '@mui/material';
import { useSelector } from 'react-redux';
import { useReactToPrint } from 'react-to-print';
import PrintIcon from '@mui/icons-material/Print';
import { isMobile } from 'react-device-detect';
import { TabContext, TabList, TabPanel } from '@mui/lab';

import { authSelector } from '../../../store/authSlice';
import { SpotlightContent } from './SpotlightContent';
import { MainServiceApi } from '../../../services/mainService';
import { SpotlightData } from '../../../../../src/types/spotlight';
import { ChartColors, DefaultColor } from '../Data/Data';
import { PermissionServiceApi } from '../../../services/permissionService';

import './Spotlight.css';

export interface SpotlightProps {
    schoolId: number;
    districtId: number;
    userId: number;
}
export function Spotlight(props: SpotlightProps) {
    const { schoolId, districtId, userId } = props;

    const authDataStore = useSelector(authSelector);
	const { token } = authDataStore;

    // Default start date is 7 days ago
    const defaultStartDate = new Date();
    defaultStartDate.setDate(defaultStartDate.getDate() - 7);
    defaultStartDate.setHours(0,0,0,0);
    const [ timeRangeSelection, setTimeRangeSelection] = useState('week');
    const [ startDate, setStartDate ] = useState<Date>(defaultStartDate);

    const [ showDistrictData, setShowDistrictData ] = useState(false);
    const [ selectedTab, setSelectedTab ] = useState('school');
    
    const spotlightDescriptionText: string = `Your ${selectedTab}'s ${timeRangeSelection}ly spotlight! How has your ${selectedTab} been doing for the past ${timeRangeSelection === 'week' ? 7 : 30} days?`

    const handleChangeTimeSelection = (event: SelectChangeEvent) => {
        setTimeRangeSelection(event.target?.value);
        const days = event.target?.value === 'week' ? 7 : 30
        const newStartDate = new Date();
        newStartDate.setDate(newStartDate.getDate() - days);
        newStartDate.setHours(0,0,0,0);
        setStartDate(newStartDate);
    }

    const [ spotlightData, setSpotlightData ] = useState<SpotlightData | undefined>()
    const [isLoadingSpotlightData, setIsLoadingSpotlightData] = useState(true);
    const [ tokenTypesChartLegend, setTokenTypesChartLegend] = useState<{id: string,
        color: string,
        value: string
    }[]>([]);

    const getSpotlightData = async (recognitionStartDate: Date, districtOrSchool: string) => {
        const mainServiceApi = MainServiceApi();
        
        try {
            setIsLoadingSpotlightData(true);
            let fetchedSpotlightData: SpotlightData;
            if (districtOrSchool === 'district') {
                fetchedSpotlightData = await mainServiceApi.getSpotlightDataForDistrict({districtId, startDate: recognitionStartDate, token: token!});
            } else {
                fetchedSpotlightData = await mainServiceApi.getSpotlightDataForSchool({schoolId, startDate: recognitionStartDate, token: token!});
            }
            
            if (fetchedSpotlightData) {
                setSpotlightData(fetchedSpotlightData)
            }

            if (fetchedSpotlightData.tokenTypes.length > 0) {
                // Build the legend based off of the token types
            const chartLegendList: {
                id: string,
                color: string,
                value: string,
            }[] = fetchedSpotlightData.tokenTypes.map((tokenType, index) => {
                return {
                    id: tokenType.tokenCoreValue,
                    color: ChartColors[index % ChartColors.length],
                    value: tokenType.tokenCoreValue
                }
            });
            // Add the "Other" category
            chartLegendList.push({
                id: '-1',
                color: DefaultColor,
                value: 'Other'
            })
            setTokenTypesChartLegend(chartLegendList);
            }
            setIsLoadingSpotlightData(false);
        } catch (err) {
            // log the error
			Sentry.captureException(err);
        }
    }

    useEffect(() => {
        getSpotlightData(startDate, selectedTab);
    }, [schoolId, startDate, selectedTab]);

    // Printing
    const spotlightRef = useRef(null);
    const handlePrint = useReactToPrint({
        content: () => spotlightRef.current,
    });

    // Permrissions
    useEffect(() => {
		if (districtId) {
			getDistrictLevelPermissions(districtId);
		}
	}, [districtId]);

	const getDistrictLevelPermissions = async (districtId: number) => {
		const permissionsServiceApi = PermissionServiceApi();

		try {
			const districtPermissions = await permissionsServiceApi.getPermissionsForDistrict(districtId, token!);
			
			const districtFeedKey = 'DISTRICT_VIEW_FEED'; // using the same permission that controls viewing district data on the feed tab (for simplicity)
			const districtHasPermissionToViewDistrictFeed = districtPermissions.find(permission => permission.permissionKey === districtFeedKey);
			if (districtHasPermissionToViewDistrictFeed) {
				setShowDistrictData(true);
			} else {
				setShowDistrictData(false);
			}

		} catch (err) {
			// log the error
			Sentry.captureException(err);
		}
	}

    const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    	setSelectedTab(newValue);
  	};

    return (
        <div className='teacher-spotlight-page' >
            <Box className='spotlight-page-header'>
                <Box className='page-heading'>Spotlight</Box>
                    <Box className='spotlight-header-options'>
                        {!isMobile && 
                        <Tooltip title='Print the Spotlight or Save as PDF' arrow>
                            <IconButton className='print-icon' onClick={handlePrint}>
                                <PrintIcon/>
                            </IconButton>
                        </Tooltip>
                        }
                        <FormControl size='small' className={`spotlight-dropdown${isMobile ? '-mobile' : ''}`} sx={{ m: 1, minWidth: 120 }}>
                            <Select
                                id='spotlight-time-select'
                                value={timeRangeSelection}
                                onChange={handleChangeTimeSelection}
                            >
                                <MenuItem key='week' value='week'>Weekly</MenuItem>
                                <MenuItem key='month' value='month'>Monthly</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
            </Box>
            
            {/* Print view of the spotlight */}
            <Box style={{display: 'none'}}>
                <SpotlightContent 
                    schoolId={schoolId}
                    districtId={districtId}
                    userId={userId}
                    startDate={startDate}
                    timeRangeSelection={timeRangeSelection}
                    districtOrSchool={selectedTab}
                    spotlightData={spotlightData}
                    tokenTypesChartLegend={tokenTypesChartLegend}
                    isLoadingSpotlightData={isLoadingSpotlightData}
                    ref={spotlightRef} // pass through the ref for printing
                    printView={true}
                />
            </Box>

            {/* Spotlight content - if district view is allowed, then show tabs, otherwise just show school data */}
            {!showDistrictData ?
                <Box >
                    <Box className='spotlight-description-text'>{spotlightDescriptionText}</Box>
                    <SpotlightContent 
                        schoolId={schoolId}
                        districtId={districtId}
                        userId={userId}
                        startDate={startDate}
                        timeRangeSelection={timeRangeSelection}
                        districtOrSchool={selectedTab}
                        spotlightData={spotlightData}
                        tokenTypesChartLegend={tokenTypesChartLegend}
                        isLoadingSpotlightData={isLoadingSpotlightData}
                        ref={undefined} // don't pass through the ref because we aren't using this for printing
                    /> 
                </Box>
            :
                <TabContext value={selectedTab}>
                    <Box className='spotlight-tabs'>
                        <TabList onChange={handleTabChange}>
                            <Tab label="School" value='school'/>
                            <Tab label="District" value='district'/>
                        </TabList>
                    </Box>
                    <TabPanel value='school'>
                    <Box >
                        <Box className='spotlight-description-text'>{spotlightDescriptionText}</Box>
                        <SpotlightContent 
                            schoolId={schoolId}
                            districtId={districtId}
                            userId={userId}
                            startDate={startDate}
                            timeRangeSelection={timeRangeSelection}
                            districtOrSchool={selectedTab}
                            spotlightData={spotlightData}
                            tokenTypesChartLegend={tokenTypesChartLegend}
                            isLoadingSpotlightData={isLoadingSpotlightData}
                            ref={undefined} // don't pass through the ref because we aren't using this for printing
                        /> 
                    </Box>
                    </TabPanel>
                    <TabPanel value='district'>
                    <Box >
                        <Box className='spotlight-description-text'>{spotlightDescriptionText}</Box>
                        <SpotlightContent 
                            schoolId={schoolId}
                            districtId={districtId}
                            userId={userId}
                            startDate={startDate}
                            timeRangeSelection={timeRangeSelection}
                            spotlightData={spotlightData}
                            districtOrSchool={selectedTab}
                            tokenTypesChartLegend={tokenTypesChartLegend}
                            isLoadingSpotlightData={isLoadingSpotlightData}
                            ref={undefined} // don't pass through the ref because we aren't using this for printing
                        /> 
                    </Box>
                    </TabPanel>
                </TabContext>
            }
             
        </div>
    )
}
