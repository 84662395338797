import React from 'react';
import { DataGrid,  GridActionsCellItem,  GridColDef, GridToolbarExport } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import RefreshIcon from '@mui/icons-material/Refresh';

import './ManageRewardsTable.css';
import { School, UserData } from '../../../../../../src/types/user';
import { ManageRewardData } from '../../../../../../src/types/rewards';
import { Box, CircularProgress, Typography } from '@mui/material';

export interface ManageRewardsTableProps {
    districtSchools: School[];
    districtRewardOwners: UserData[];
    rewardRows: ManageRewardData[];
    activeRewards: boolean; // whether this should be for displaying active rewards or deactivated rewards
    handleClickAction: (data: {
        reward: ManageRewardData,
        deactivate?: boolean,
        reactivate?: boolean,
    }) => void;
    rowsLoading: boolean;
}

export function ManageRewardsTable(props: ManageRewardsTableProps) {
    const {
        districtSchools,
        districtRewardOwners,
        rewardRows,
        activeRewards,
        handleClickAction,
        rowsLoading
    } = props;

    let columns: GridColDef[] = [
        {
            field: 'actions',
            type: 'actions',
            headerName: '',
            width: 90,
            cellClassName: 'actions',
            getActions: ({ id, row }) => {
                if (activeRewards) {
                    return [
                        <GridActionsCellItem 
                            icon={<EditIcon />}
                            label='Edit'
                            className='edit-reward-icon'
                            onClick={() => handleClickAction({reward: row})}
                        />,
                        <GridActionsCellItem 
                            icon={<DeleteIcon />}
                            label='Deactivate'
                            className='delete-reward-icon'
                            onClick={() => handleClickAction({reward: row, deactivate: true})}
                        />
                    ]
                } else {
                    return [
                        <GridActionsCellItem 
                            icon={<RefreshIcon />}
                            label='Edit'
                            className='edit-reward-icon'
                            onClick={() => handleClickAction({reward: row, reactivate: true})}
                        />,
                    ]
                }
                
            }
        },
        {
            field: 'displayName',
            headerName: 'Reward Name',
            width: 220
        },
        {
            field: 'description',
            headerName: 'Description',
            width: 130
        },
        {
            field: 'schoolId',
            headerName: 'School',
            width: 130,
            valueGetter: (schoolId: number) => {
                if (schoolId === 0) {
                    return 'District-wide'
                }
                const school = districtSchools.find(school => school.id === schoolId);
                if (school) {
                    return school.name;
                } else {
                    return '';
                }
            },
            // TO DO - hide if district_schools.length === 1
        },
        {
            field: 'cost',
            headerName: 'Cost',
            width: 80,
        },
        {
            field: 'availabilityText',
            headerName: 'Count Available',
            width: 130,
            valueGetter: (availabilityText?: string) => {
                return availabilityText || 'Unlimited';
            },
        },
        {
            field: 'countRedeemed',
            headerName: 'Count Redeemed',
            width: 130,
            align: 'center'
        },
        {
            field: 'ownerUserId',
            headerName: 'Reward Owner',
            width: 200,
            valueGetter: (ownerUserId: number) => {
                const rewardOwner = districtRewardOwners.find(user => user.id === ownerUserId);
                if (rewardOwner) {
                    return `${rewardOwner.firstName} ${rewardOwner.lastName}`;
                } else{
                    return '';
                }
            },
        },
    ];

    // remove countAvailable columns if we are showing deactivated
    if (!activeRewards) {
        columns = columns.filter(column => column.field !== 'availabilityText')
    }

    // remove school column if only one school in district
    if (districtSchools?.length <= 1) {
        columns = columns.filter(column => column.field !== 'schoolId');
    }

    const noRowsOverlay = () => {
        return (
            <Box>
                <Typography className='rewards-no-rows-text'>None</Typography>
            </Box>
        )
    }

    function CustomToolbar() {
        return (
            <div className='rewards-export'>
                <GridToolbarExport />
            </div>
        );
      }

    return (
        <div>
        <Box className='rewards-data-grid-box'>
            <DataGrid
                rows={rewardRows}
                columns={columns}
                initialState={{
                    pagination: {
                        paginationModel: {
                        pageSize: 15,
                        },
                    },
                }}
                slots={{
                    noRowsOverlay,
                    toolbar: CustomToolbar
                }}
                pageSizeOptions={[15, 25, 50]}
                loading={rowsLoading}
            />
        </Box>
    </div>
    )

}

// maybe click on the reward to view it and that's when you get to see the full redeem instructions and image
// and then that's where you can edit or deactivate it
// shouldn't allow clicking outside the box to close (so don't accidentally lose what you typed)