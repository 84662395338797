import Cookies from 'js-cookie';

import { Permission } from '../../../src/types/permissions';

export interface PermissionServiceApi {
    getPermissionsForDistrict: (districtId: number, token: string) => Promise<Permission[]>;
    getPermissionsForUser: (userId: number, token: string) => Promise<Permission[]>
}

export interface PermissionServiceApiFactory {
    (): PermissionServiceApi;
}

export const PermissionServiceApi: PermissionServiceApiFactory = (): PermissionServiceApi => {
    return {
        getPermissionsForDistrict: async (districtId: number, token: string): Promise<Permission[]> => {
            const response = await fetch(`/api/v1/permissions/district?districtId=${districtId}` , {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                    'X-XSRF-TOKEN': Cookies.get('XSRF-TOKEN') || '',
                }
            });

            if (response.ok) {
                return response.json();
            } else {
                throw new Error(`Error retrieving district level permissions. Error message: ${response.statusText}`);
            }
        },
        getPermissionsForUser: async (userId: number, token: string): Promise<Permission[]> => {
            const response = await fetch(`/api/v1/permissions/user?userId=${userId}` , {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                    'X-XSRF-TOKEN': Cookies.get('XSRF-TOKEN') || '',
                }
            });

            if (response.ok) {
                return response.json();
            } else {
                throw new Error(`Error retrieving user level permissions. Error message: ${response.statusText}`);
            }
        }
    }
}