import React, {useState, useEffect} from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { useSelector } from 'react-redux';
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, IconButton, Switch, Typography } from '@mui/material';
import { isMobile } from 'react-device-detect';
import * as Sentry from "@sentry/react";
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, {Dayjs} from 'dayjs';

import DownloadCSVButton from '../../DownloadToCSV/DownloadToCSV';
import { RecognitionData } from '../../../../../src/types/recognition';
import { MainServiceApi } from '../../../services/mainService';
import { authSelector } from '../../../store/authSlice';

import './ExportPopup.css';
import { RecognitionType } from '../../../util/type';

export interface ExportPopupProps {
    schoolId: number;
    districtId: number;
    userId: number;
    popupOpen: boolean;
    closePopup: () => void;
}

export default function ExportPopup(props: ExportPopupProps) {
    const {
        schoolId,
        districtId,
        userId,
        popupOpen,
        closePopup
    } = props;

    // Data store
    const authDataStore = useSelector(authSelector);
    const { token } = authDataStore;

    const [errorMessage, setErrorMessage] = useState<string | undefined>();
    const [loading, setLoading] = useState(false);
    const [exportReady, setExportReady] = useState(false);
    const [exportComplete, setExportComplete] = useState(false);
    const [showWarningMessage, setShowWarningMessage] = useState(false);
    
    // Export all toggle
    const [exportAllSelected, setExportAllSelected] = useState(false);

    // Date fields
    const today = new Date();
    const oneWeekAgo = new Date();
    oneWeekAgo.setDate(today.getDate() - 7);
    oneWeekAgo.setHours(0,0,0,0);
    const [startDate, setStartDate] = useState<Dayjs | null>(dayjs(oneWeekAgo));
    const [endDate, setEndDate] = useState<Dayjs | null>(dayjs(today));

    // Export data
    const [exportData, setExportData] = useState<RecognitionData[] | undefined>();

    const resetPopup = () => {
        setErrorMessage(undefined);
        setLoading(false);
        setExportReady(false);
        setExportComplete(false);
        setExportAllSelected(false);
        setStartDate(dayjs(oneWeekAgo));
        setEndDate(dayjs(today));
        setExportData(undefined);
        setShowWarningMessage(false);
    }

    const handleClickClose = () => {
        closePopup();
    }

    useEffect(() => {
        if (popupOpen) {
            resetPopup();
        }
    }, [popupOpen]);

    const handleClickPrepareExport = async () => {
        setLoading(true);
        try {
            if (token) {
                let actualStartDate = startDate?.toDate() || new Date ('01/01/2020');
                let actualEndDate = endDate?.toDate();
                if (exportAllSelected) {
                    // Start date has to be populated so set the start date to far enough in the past that it will capture everything
                    actualStartDate = new Date ('01/01/2020');
                    actualEndDate = undefined;
                }
                const mainServiceApi = MainServiceApi();
                const result = await mainServiceApi.getRecognitionPaginated({
                    recognitionType: RecognitionType.Student,
                    schoolId,
                    districtId,
                    userId,
                    page: 1,
                    pageSize: 1000, // max export size limit (for now)
                    token,
                    startDate: actualStartDate,
                    endDate: actualEndDate
                });

                // If the count returned is more than 1000, show a warning message to the user (result.count is the total rows - not the page size)
                if (result.count > 1000){
                    setShowWarningMessage(true);
                }
                setExportData(result.recognition);
            }

            setExportReady(true);
        } catch (err) {
            setErrorMessage('There was an error when trying to complete the export. Please close this window and try again. Hilight Support has been notified.');
            // log the error
			Sentry.captureException(err);
        }
        setLoading(false);
    }

    const handleClickExport = () => {
        setExportComplete(true);
    }

    let dialogContent = (
        <Box>
            <Typography>Select a date range or export all:</Typography>
            <Box className='export-popup-fields'>
                
                {exportAllSelected && 
                    <Typography className='export-popup-italic-text'>All of the student hilights will be exported.</Typography>
                }
                {!exportAllSelected && 
                    <Box className='export-date-selectors'>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                label="Start Date"
                                className='export-date-picker'
                                value={startDate}
                                onChange={(newValue: Dayjs | null) => setStartDate(newValue)}
                            />
                            <DatePicker
                                label="End Date"
                                value={endDate}
                                onChange={(newValue: Dayjs | null) => setEndDate(newValue)}
                            />
                        </LocalizationProvider>
                    </Box>
                }
                <FormControlLabel
                    control={
                        <Switch 
                            checked={exportAllSelected} 
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setExportAllSelected(event.target.checked);
                            }} 
                        />
                            }
                    label='Export all student hilights'
                />
            </Box>
        </Box>
    );
    
    if (errorMessage) {
        dialogContent = (
            <Typography>{errorMessage}</Typography>
        );
    }
    if (exportReady && exportData && exportData.length > 0) {
        dialogContent = (
            <div>
                {showWarningMessage ? 
                    <Typography>
                        <p>Your selection has returned our export limit of <span className='bold-text'>1000</span> rows. If you'd like to do a larger export, please reach out to the Support Team at <span className='bold-text'>support@hilightedu.com.</span></p> 
                        <p>If you'd like to export the first 1000 rows returned, you can click "Export" below. Otherwise, please close out of this window and try again using the date selectors to narrow your selection.</p>
                    </Typography>
                :
                    <Typography>{`Your export contains ${exportData?.length} records. Click "Export" below if you are ready to download the csv.`}</Typography>
                }
            </div>
        )
    }
    if (exportReady && (!exportData || exportData.length === 0)) {
        dialogContent = (
            <Typography>{`Your selection did not return any records. Please close out of this window and try again.`}</Typography>

            // TO DO
        )
    }
    if (exportComplete) {
        dialogContent = (
            <Typography>Check your downloads folder for the csv export!</Typography>
        )
    }
    if (loading) {
        dialogContent = <div className='export-loading'><CircularProgress/></div>
    }

    return (
        <Box>
           <Dialog open={popupOpen} onClose={handleClickClose}>
                <DialogTitle className='export-popup-title'>{errorMessage ? 'Whoops!' : 'Export Student Hilights'}</DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClickClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: 'grey',
                    }}
                    >
                    <CloseIcon />
                </IconButton>
                <DialogContent className={isMobile ? 'export-popup-content-mobile' : 'export-popup-content'}>
                    {dialogContent}
                </DialogContent>
                <DialogActions>
                    { !exportReady &&
                        <Button 
                            variant='contained'
                            onClick={handleClickPrepareExport}
                            disabled={loading}
                        >
                            Prepare CSV Export
                        </Button>
                    }
                    { exportReady && !exportComplete &&
                        <DownloadCSVButton 
                            data={exportData}
                            fileName='hilight_student_recognition'
                            buttonText='Export'
                            handleAfterExport={handleClickExport}
                        />
                    }
                    <Button 
                        variant='contained'
                        onClick={handleClickClose}
                        id='cancel-export-button'
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog> 
        </Box>
    )
}
