import { createAsyncThunk, createSlice, PayloadAction } from  '@reduxjs/toolkit';
import * as Sentry from "@sentry/react";
import Cookies from 'js-cookie';

import { RootState } from '../store/store';
import { UserData } from "../../../src/types/user";
import { LoginServiceApi } from '../services/loginService';

export interface AuthState {
    // The access token for this user
    token?: string,
    // The expiration time of the access token
    expiresAt?: number,
    // The user data
    user?: UserData, // This might actually be basic user data instead of the full user data?
    // Indicates that the Auth API call is in progress
    authLoading: boolean,
    // Authentication flag
    isAuthenticated: boolean,
    // Indicates that the user signIn API is in progress
    userLoginLoading: boolean,
    // Manages the error of the user signIn API
    loginError?: string
}

// First need to define the initial state of the auth reducer
const initialState: AuthState = {
    authLoading: true,
    isAuthenticated: false,
    userLoginLoading: false,
}

export const userLogin = createAsyncThunk('user/login', async (data: {username: string, password: string}) => {
    const loginService = LoginServiceApi();
    const result = await loginService.signInUser(data.username, data.password);

    return result;
});

export const userCleverLogin = createAsyncThunk('user/clever/login',  async (cleverCode: string) =>  {
    const loginService = LoginServiceApi();
    const result = await loginService.cleverSignIn(cleverCode);
   
    return result;
});

export const userGoogleLogin = createAsyncThunk('user/google/login',  async (userCredentials: string) =>  {
    const loginService = LoginServiceApi();
    const result = await loginService.googleSignIn(userCredentials);
   
    return result;
});

export const userLogout = createAsyncThunk('user/logout', async () => {
    const loginService = LoginServiceApi();
    await loginService.signOutUser();
    return;
});

export const verifyToken = createAsyncThunk('verifyTokenAuth/', async () => {
    const loginService = LoginServiceApi();
    const result = await loginService.verifyToken();

    return result;
});

export const verifyTokenSilentAuth = createAsyncThunk('verifyTokenSilentAuth/', async () => {
    const loginService = LoginServiceApi();
    const result = await loginService.verifyToken();

    return result;
})

const authSlice = createSlice({
    name: 'auth',
    initialState,
    extraReducers: (builder) => {
        // Handle userLogin //
        builder.addCase(userLogin.pending, (state: AuthState) => {
            state.authLoading = true;
            state.userLoginLoading = true;
        });
        builder.addCase(userLogin.fulfilled, (state, { payload }) => {
            const { token, expiresAt, userData, errorMessage } = payload;
            // the loginService will catch some errors instead of throwing them
            // so set the error message here and finish loading
            if (errorMessage || !token || !expiresAt || !userData) {
                state.loginError = errorMessage;
                state.userLoginLoading = false;
                state.authLoading = false;
                state.isAuthenticated = false;
            } else {
                state.token = token;
                state.expiresAt = expiresAt;
                state.user = userData;
                state.isAuthenticated = true;
                state.authLoading = false;
                state.userLoginLoading = false;
                state.loginError = undefined; // need to set this in case there was an error on previous sign in attempt
            }
            
        });
        builder.addCase(userLogin.rejected, (state, action) => {
            state.loginError = 'There was an error when logging you in. Please try again.';
            state.userLoginLoading = false;
            state.authLoading = false;

            // log the error to Sentry
            Sentry.captureMessage(action.error.message || 'Error when logging in user');
        });

        // Handle userCleverLogin
        builder.addCase(userCleverLogin.pending, (state) => {
            // Override any existing user session when we get a Clever auth request in our app
            state.token = undefined;
            state.expiresAt = undefined;
            state.isAuthenticated = false;
            state.user = undefined;
            state.loginError = undefined;
            Cookies.remove('XSRF-TOKEN');

            // Set loading to true
            state.authLoading = true;
            state.userLoginLoading = true;
        });
        builder.addCase(userCleverLogin.fulfilled, (state, { payload }) => {
            const { token, expiresAt, userData, errorMessage } = payload;
            // the loginService will catch some errors instead of throwing them
            // so set the error message here and finish loading
            if (errorMessage) {
                state.loginError = errorMessage;
                state.userLoginLoading = false;
                state.authLoading = false;
                state.isAuthenticated = false;
            } else if (!token || !expiresAt || !userData) {
                state.userLoginLoading = false;
                state.authLoading = false;
                state.isAuthenticated = false;
            } else {
                state.token = token;
                state.expiresAt = expiresAt;
                state.user = userData;
                state.isAuthenticated = true;
                state.authLoading = false;
                state.userLoginLoading = false;
            }  
        });
        builder.addCase(userCleverLogin.rejected, (state, action) => {
            const error = action.error.message;
            state.loginError = error;
            state.userLoginLoading = false;
            state.authLoading = false;

            // log the error to Sentry
            Sentry.captureMessage(action.error.message || 'Error when logging in user via Clever');
        });

        // Handle userGoogleLogin
        builder.addCase(userGoogleLogin.pending, (state) => {
            // Override any existing user session when we get a Google auth request in our app
            state.token = undefined;
            state.expiresAt = undefined;
            state.isAuthenticated = false;
            state.user = undefined;
            state.loginError = undefined;
            Cookies.remove('XSRF-TOKEN');

            // Set loading to true
            state.authLoading = true;
            state.userLoginLoading = true;
        });
        builder.addCase(userGoogleLogin.fulfilled, (state, { payload }) => {
            const { token, expiresAt, userData, errorMessage } = payload;
            // the loginService will catch some errors instead of throwing them
            // so set the error message here and finish loading
            if (errorMessage) {
                state.loginError = errorMessage;
                state.userLoginLoading = false;
                state.authLoading = false;
                state.isAuthenticated = false;
            } else if (!token || !expiresAt || !userData) {
                state.userLoginLoading = false;
                state.authLoading = false;
                state.isAuthenticated = false;
            } else {
                state.token = token;
                state.expiresAt = expiresAt;
                state.user = userData;
                state.isAuthenticated = true;
                state.authLoading = false;
                state.userLoginLoading = false;
            }  
        });
        builder.addCase(userGoogleLogin.rejected, (state, action) => {
            const error = action.error.message;
            state.loginError = error;
            state.userLoginLoading = false;
            state.authLoading = false;

            // log the error to Sentry
            Sentry.captureMessage(action.error.message || 'Error when logging in user via Google');
        });

        // Handle userLogout //
        builder.addCase(userLogout.pending, (state)  => {
            state.token = undefined;
            state.expiresAt = undefined;
            state.isAuthenticated = false;
            state.authLoading = false;
            state.userLoginLoading = false;
            state.user = undefined;
            state.loginError = undefined;
        });
        builder.addCase(userLogout.fulfilled, (state)  => {
            state.token = undefined;
            state.expiresAt = undefined;
            state.isAuthenticated = false;
            state.authLoading = false;
            state.userLoginLoading = false;
            state.user = undefined;
            state.loginError = undefined;
        });
        builder.addCase(userLogout.rejected, (state, action)  => {
            state.token = undefined;
            state.expiresAt = undefined;
            state.isAuthenticated = false;
            state.authLoading = false;
            state.userLoginLoading = false;
            state.user = undefined;
            state.loginError = undefined;

            // If the logout failed, make sure to at least manually remove the xsrf token from the cookies
            // (can't remove refreshToken because it's httpOnly but verifyToken will fail if it doesn't have the XSRF token, so this should be enough) 
            Cookies.remove('XSRF-TOKEN');
            
            // log the error to Sentry
            Sentry.captureMessage(action.error.message || 'Error when logging out the user');
        });

        // Handle verify token //
        builder.addCase(verifyToken.pending, (state) =>  {
            // Starting verifyToken request (not silent auth)
            // Since this is not the "silentAuth" version, then we need to reset the state to the initial state for auth Loading
            state = {...initialState};
        });
        builder.addCase(verifyToken.fulfilled, (state, { payload }) => {
            const { token, expiresAt, userData, errorMessage } = payload;

            // If the token is not valid (aka unauthorized) then errorMessage will be populated
            if (errorMessage || !token) {
                // leave the rest of the state as is but set auth Loading to false
                state.authLoading = false;
                // this is a valid error (unauthorized) so we don't need to log it
            } else {
                // verifyToken succeeded, so now we have to update the state with the new token and user data
                state.token = token;
                state.expiresAt = expiresAt;
                state.user = userData;
                state.isAuthenticated = true;
                state.authLoading = false;
                state.userLoginLoading = false;
            }
            
        });
        builder.addCase(verifyToken.rejected, (state, action) => {
            // If verify token failed, leave state as is, but set auth Loading to done
            state.authLoading = false;
            // This is all we have to do because we already reset to the initial state in the pending case
            // The user will be returned to the login page now that authLoading is set to false (and isAuthenticated is already false)

            // log the error to Sentry since this is an unexpected error
            Sentry.captureMessage(action.error.message || 'Error when verifying the token');
        });

        // Handle verify token but for silent auth (when we are just re-getting the token silently because the user is already logged in)
        builder.addCase(verifyTokenSilentAuth.pending, () => {
            // Don't need to do anything because we are just silently re-checking the auth
            // Leave the state as is
            
        });
        builder.addCase(verifyTokenSilentAuth.fulfilled, (state, { payload }) => {
            // verifyToken for silent auth succeeded, so now we have to update the state with the new token and user data
            const { token, expiresAt, userData,  errorMessage } = payload;
            // If the token is not valid (aka unauthorized) then errorMessage will be populated
            if (errorMessage || !token) {
                // leave the rest of the state as is but set auth Loading to false
                state.authLoading = false;
                // this is a valid error so we don't need to log it
            } else {
                state.token = token;
                state.expiresAt = expiresAt;
                state.user = userData;
                state.isAuthenticated = true;
                state.authLoading = false;
                state.userLoginLoading = false;
            }
        });
        builder.addCase(verifyTokenSilentAuth.rejected, (state, action) => {
            // If verify token failed for an unexpectted reason, leave state as is, but set auth Loading to done
            state.authLoading = false;
            // This is all we have to do because we already reset to the initial state in the pending case
            // The user will be returned to the login page now that authLoading is set to false (and isAuthenticated is already false)

           // log the error to Sentry because this is an unexpected error
           Sentry.captureMessage(action.error.message || 'Error when verifying the token silently');
        });
    },
    reducers: {
        updateUserData: (state, action: PayloadAction<UserData>) => {
            state.user = action.payload;
        }
    }
});

export const { updateUserData } = authSlice.actions;
export const authSelector = (state: RootState) => state.authReducer;
export default authSlice.reducer;