import * as React from 'react';

import { Box } from '@mui/material';
import { ResponsiveContainer, AreaChart, XAxis, YAxis, Area } from 'recharts';

import './ColleaguesRecognizedChart.css';


export interface ColleaguesRecognizedChartProps {
    data: {month: string, percentage: number}[]
}

export function ColleaguesRecognizedChart(props: ColleaguesRecognizedChartProps) {
    const { data } = props;

    return (
        <Box className='colleagues-recognized-chart-box'>
            <ResponsiveContainer width={'100%'} height={'100%'}>
                <AreaChart
                    width={350}
                    height={380}
                    data={data}
                    margin={{
                        left: 0
                    }}
                    key={Math.random()} // Used to make the animation work even with re-render
                >
                    <XAxis 
                        dataKey='month'
                    />
                    <YAxis 
                        tickFormatter={(tick) => `${tick * 100}%`}
                        ticks={[.2, .4, .6, .8,  1]}
                    />
                    <defs>
                        <linearGradient id="colorYellow" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor="#fcbe57" stopOpacity={0.8}/>
                        <stop offset="95%" stopColor="#fcbe57" stopOpacity={0}/>
                        </linearGradient>
                    </defs>   
                    <Area type='monotone' dataKey='percentage' stroke='#fcbe57' fill='#fcbe57' fillOpacity={0.4}  />
                </AreaChart>
            </ResponsiveContainer>
        </Box>
    )
}
