import React, {useState, useEffect} from 'react';

import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, IconButton, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import * as Sentry from "@sentry/react";
import { isMobile } from 'react-device-detect';

import { useAppSelector } from '../../../../store/hooks';
import { authSelector } from '../../../../store/authSlice';
import { ManageRewardData } from '../../../../../../src/types/rewards';
import { MainServiceApi } from '../../../../services/mainService';

import './DeactivateRewardPopup.css';

export interface DeactivateUserPopupProps {
    rewardToDeactivate?: ManageRewardData;
    popupOpen: boolean;
    closePopup: () => void;
    handleRemoveReward: (reward: ManageRewardData) => void
}

export default function DeactivateRewardPopup(props: DeactivateUserPopupProps) {
    const {
        rewardToDeactivate,
        popupOpen,
        closePopup,
        handleRemoveReward
    } = props;

    const authDataStore = useAppSelector(authSelector);
    const { token } = authDataStore;
    
    // Loading and error handling
    const [deactivateInProgress, setDeactivateInProgress] = useState(false);
    const [deactivateRewardSuccessful, setDeactivateRewardSuccessful] = useState(false);
    const [deactivateRewardError, setDeactivateRewardError] = useState(false);

    useEffect(() => {
        // resetThePopup
        setDeactivateInProgress(false);
        setDeactivateRewardSuccessful(false);
        setDeactivateRewardError(false);
    }, [rewardToDeactivate])

    const handleClickClose = async () => {
        setDeactivateInProgress(false);
        closePopup();
    }

    const handleClickConfirm = async () => {
        setDeactivateInProgress(true);
        const mainService = MainServiceApi();
        try {
            if (!rewardToDeactivate){
                throw new Error('No reward found to deactivate'); 
            }
            await mainService.deactivateReward(rewardToDeactivate.id, token!);
            handleRemoveReward(rewardToDeactivate);
            setDeactivateRewardSuccessful(true);
        } catch (err) {
            // show the error message
            setDeactivateRewardError(true);
            // log the error
            Sentry.captureException(err);
        }

        setDeactivateInProgress(false);
    }

    if (deactivateRewardError) {
        const errorMessage = 'Something went wrong when deactivating the reward. Please refresh this page and try again. Hilight Support has been notified.';
        return (
            <Box>
                <Dialog open={popupOpen} onClose={handleClickClose}>
                    <DialogTitle>Whoops!</DialogTitle>
                    <IconButton
                        aria-label="close"
                        onClick={handleClickClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: 'grey',
                        }}
                        >
                        <CloseIcon />
                    </IconButton>
                    <DialogContent className={isMobile ? 'deactivate-reward-popup-content-mobile' : 'deactivate-reward-popup-content'}>
                        <Typography className='deactivate-reward-popup-error-text'>{errorMessage}</Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button 
                            variant='contained'
                            onClick={handleClickClose}
                            id='cancel-deactivate-reward-button'
                        >
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </Box>
        )
    }

    if (deactivateRewardSuccessful) {
        return (
            <Box>
                <Dialog open={popupOpen} onClose={handleClickClose}>
                    <DialogTitle>Deactivate Reward</DialogTitle>
                    <IconButton
                        aria-label="close"
                        onClick={handleClickClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: 'grey',
                        }}
                        >
                        <CloseIcon />
                    </IconButton>
                    <DialogContent className={isMobile ? 'deactivate-reward-popup-content-mobile' : 'deactivate-reward-popup-content'}>
                        <Typography className='deactivate-reward-popup-success-text'>Reward was deactivated successfully!</Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button 
                            variant='contained'
                            onClick={handleClickClose}
                            id='cancel-deactivate-reward-button'
                        >
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </Box>
        );
    }

    return (
        <Box>
            <Dialog open={popupOpen} onClose={handleClickClose}>
                <DialogTitle>Deactivate Reward</DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClickClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: 'grey',
                    }}
                    >
                    <CloseIcon />
                </IconButton>
                <DialogContent className={isMobile ? 'deactivate-reward-popup-content-mobile' : 'deactivate-reward-popup-content'}>
                    {deactivateInProgress  ? <Box className='deactivate-reward-loading'><CircularProgress /> </Box>
                    : 
                    <Box>
                        {rewardToDeactivate &&
                        <Box>
                            <Typography className='deactivate-reward-popup-confirm-text'>
                                Please confirm that you want to deactivate the following reward:
                            </Typography>
                            <Typography className='deactivate-reward-bold-text' >
                                {rewardToDeactivate.displayName}
                            </Typography>
                        </Box>
                        }
                    </Box>
                    }
                </DialogContent>
                <DialogActions>
                    <Button 
                        variant='contained'
                        onClick={handleClickConfirm}
                        disabled={deactivateInProgress}
                    >
                        Confirm
                    </Button>
                    <Button 
                        variant='contained'
                        onClick={handleClickClose}
                        disabled={deactivateInProgress}
                        id='cancel-deactivate-user-button'
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    )
}