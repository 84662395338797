import React, {useState, useEffect} from 'react';

import * as Sentry from "@sentry/react";
import { Autocomplete, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormLabel, IconButton, InputLabel, MenuItem, Select, Switch, TextField, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { isMobile } from 'react-device-detect';

import { ManageRewardData } from '../../../../../../src/types/rewards';
import { School, UserData } from '../../../../../../src/types/user';
import { useAppSelector } from '../../../../store/hooks';
import { authSelector } from '../../../../store/authSlice';
import { RewardCardType, RewardsCard } from '../../Rewards/RewardsCard';

import './EditRewardPopup.css';
import { MainServiceApi } from '../../../../services/mainService';
import { SchoolOrDistrictReward } from '../../../../util/type';

export interface EditRewardPopupProps {
    rewardToUpdate?: ManageRewardData;
    districtSchools: School[];
    districtUsers: UserData[];
    districtId: number;
    popupOpen: boolean;
    closePopup: () => void;
    resetReward: (data: { reward: ManageRewardData, newlyCreatedReward: boolean}) => void;
    reactivateReward: boolean;
    defaultImageData?: {imagePath: string, imageUrl: string};
}

export default function EditRewardPopup(props: EditRewardPopupProps){
    const {
        rewardToUpdate,
        districtSchools,
        districtUsers,
        districtId,
        popupOpen,
        closePopup,
        resetReward,
        reactivateReward,
        defaultImageData
    } = props;
    
    const authDataStore = useAppSelector(authSelector);
    const { token, user } = authDataStore;

    // Reward fields
    const [rewardName, setRewardName] = useState<string | undefined>();
    const [rewardDescription, setRewardDescription] = useState<string | undefined>();
    const [selectedSchool, setSelectedSchool] = useState<School | undefined>();
    const [rewardCost, setRewardCost] = useState<number | undefined>();
    const [rewardLimit, setRewardLimit] = useState<number | undefined | null>();
    const [selectedUser, setSelectedUser] = useState<UserData | undefined>();
    const [redeemInstructions, setRedeemInstructions] = useState<string | undefined>();
    const [isDistrictWide, setIsDistrictWide] = useState<boolean>(false);

    // Reward Image
    const [imagePath, setImagePath] = useState<string | undefined>();
    const [selectedPhotoFile, setSelectedPhotoFile] = useState<File | undefined>(undefined);
    const [selectedPhotoFileLink, setSelectedPhotoFileLink] = useState<string | undefined>(); // I think this can be used to preview the file before uploading

    // Loading and error handling
    const [updateInProgress, setUpdateInProgress] = useState(false);
    const [updateSuccessful, setUpdateSuccessful] = useState(false);
    const [errorUpdatingReward, setErrorUpdatingReward] = useState(false);

    useEffect(() => {
        resetSelectedSchool();
    }, [districtSchools]);


    const resetSelectedSchool = () => {
        // if there's only one school, then set selectedSchool
        if (districtSchools?.length === 1) {
            setSelectedSchool(districtSchools[0]);
        } else {
            setSelectedSchool(undefined);
        }
    }

    const resetFields = () => {
        setUpdateInProgress(false);
        setUpdateSuccessful(false);
        setErrorUpdatingReward(false);
        setRewardName(undefined);
        setRewardDescription(undefined);
        resetSelectedSchool();
        setRewardCost(undefined);
        setRewardLimit(undefined);
        setSelectedUser(undefined);
        setRedeemInstructions(undefined);
        setImagePath(undefined);
        setSelectedPhotoFile(undefined);
        setSelectedPhotoFileLink(undefined);
        setIsDistrictWide(false);
    };

    const handleClickClose = async () => {
        closePopup();
        resetFields();
    }

    useEffect(() => {
        if (rewardToUpdate) {
            setRewardName(rewardToUpdate.displayName);
            setRewardDescription(rewardToUpdate.description);
            const rewardSchool = districtSchools.find((school) => school.id === rewardToUpdate.schoolId);
            setSelectedSchool(rewardSchool);
            setRewardCost(rewardToUpdate.cost);
            setRewardLimit(rewardToUpdate.limitCount || undefined);
            const rewardOwner = districtUsers.find(user => user.id === rewardToUpdate.ownerUserId);
            setSelectedUser(rewardOwner);
            setRedeemInstructions(rewardToUpdate.redeemInstructions);
            setImagePath(rewardToUpdate.imagePath);
            setIsDistrictWide(rewardToUpdate.schoolOrDistrictReward === SchoolOrDistrictReward.District);
        } else {
            resetFields();
        }

    }, [rewardToUpdate]);

    const handleClickSave = async () => {
        setUpdateInProgress(true);
        const mainServiceApi = MainServiceApi();
        try {
            // First handle the reward image
            // If it's a new image, upload it to S3
            let newImagePath: string | undefined = imagePath;
            if (selectedPhotoFile) {
                const newRewardPhotoImagePath = await mainServiceApi.uploadRewardImage({file: selectedPhotoFile, token: token!, districtId: user!.districtId});
                newImagePath = newRewardPhotoImagePath;
            }

            // Update or Create the Reward
            if (!rewardToUpdate) {
                // Create the reward
                const newReward = await mainServiceApi.createReward({
                    displayName: rewardName!,
                    description: rewardDescription,
                    schoolId: isDistrictWide ? 0 : selectedSchool!.id,
                    districtId,
                    schoolOrDistrictReward: isDistrictWide ? SchoolOrDistrictReward.District : SchoolOrDistrictReward.School,
                    cost: rewardCost!,
                    limit: rewardLimit || undefined,
                    ownerUserId: selectedUser!.id,
                    redeemInstructions,
                    imagePath: newImagePath,
                }, token!);

                if (!newReward)  {
                    throw new Error('Newly created reward not returned from request');
                }
                resetReward({reward: newReward, newlyCreatedReward: true});
            } else {
                // Update the reward
                const updatedReward = await mainServiceApi.updateReward(
                    rewardToUpdate.id,
                    {
                        displayName: rewardName!,
                        description: rewardDescription,
                        schoolId: isDistrictWide ? 0 : selectedSchool!.id,
                        districtId,
                        schoolOrDistrictReward: isDistrictWide ? SchoolOrDistrictReward.District : SchoolOrDistrictReward.School,
                        cost: rewardCost!,
                        limit: rewardLimit || undefined,
                        ownerUserId: selectedUser!.id,
                        redeemInstructions,
                        imagePath: newImagePath,
                    },
                    token!
                );
                if (!updatedReward)  {
                    throw new Error('Updateed reward not returned from request');
                }
                resetReward({reward: updatedReward, newlyCreatedReward: false});
            }

            setUpdateSuccessful(true);
        } catch (err) {
            // show error message
            setErrorUpdatingReward(true);
			// log the error
			Sentry.captureException(err);
        }

        setUpdateInProgress(false);
    }

    const handleImageFileInput = async (event: React.ChangeEvent<HTMLInputElement>) => {
        try {
            if (event?.target?.files && event.target.files.length > 0) {
                const file = event?.target?.files[0]
                setSelectedPhotoFile(file);
                const fileLink = URL.createObjectURL(file);
                setSelectedPhotoFileLink(fileLink);
    
            } else {
                setSelectedPhotoFile(undefined); 
                setSelectedPhotoFileLink(undefined);
            }
        } catch (err) {
            // show error message
            setErrorUpdatingReward(true);
            // log the error
            Sentry.captureException(err);
        }
    };

    if (errorUpdatingReward) {
        return (
            <Box>
                <Dialog open={popupOpen} onClose={handleClickClose}>
                    <DialogTitle>Whoops!</DialogTitle>
                    <IconButton
                        aria-label="close"
                        onClick={handleClickClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: 'grey',
                        }}
                        >
                        <CloseIcon />
                    </IconButton>
                    <DialogContent className={isMobile ? 'edit-reward-popup-content-mobile' : 'edit-reward-popup-content'}>
                        <Typography className='edit-reward-popup-error-text'>Something went wrong when updating the reward. Please refresh this page and try again. Hilight Support has been notified.</Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button 
                            variant='contained'
                            onClick={handleClickClose}
                            id='cancel-edit-reward-button'
                        >
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </Box>
        )
    }

    if (updateSuccessful) {
        return (
            <Box>
                <Dialog open={popupOpen} onClose={handleClickClose}>
                    <DialogTitle>{rewardToUpdate ? 'Edit Reward' : 'Create Reward'}</DialogTitle>
                    <IconButton
                        aria-label="close"
                        onClick={handleClickClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: 'grey',
                        }}
                        >
                        <CloseIcon />
                    </IconButton>
                    <DialogContent className={isMobile ? 'edit-rewarrd-popup-content-mobile' : 'edit-reward-popup-content'}>
                        <Typography className='edit-reward-popup-success-text'>{rewardToUpdate ? 'Reward updated successfully!' : 'Reward created successfully!'}</Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button 
                            variant='contained'
                            onClick={handleClickClose}
                            id='cancel-edit-reward-button'
                        >
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </Box>
        );
    }

    return (
        <Box>
            <Dialog open={popupOpen} onClose={handleClickClose}>
                <DialogTitle>{reactivateReward ? 'Reactivate Reward' : (rewardToUpdate ? 'Edit Reward' : 'Create Reward') }</DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClickClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: 'grey',
                    }}
                    >
                    <CloseIcon />
                </IconButton>
                <DialogContent className={isMobile ? 'edit-reward-popup-content-mobile' : 'edit-reward-popup-content'}>
                    {updateInProgress ? 
                        <Box className='edit-reward-loading'><CircularProgress /> </Box>
                    : 
                    <div>
                        {reactivateReward &&
                            <Typography className='reactivate-reward-text'>
                                Please confirm that you want to reactivate this reward and make any updates to the reward info as needed.
                            </Typography>}
                        {!rewardToUpdate &&
                            <div className='create-reward-instructions'>
                                <span>{`Please provide the following information to create a new reward. If you'd like to create rewards in bulk (more than one at a time), or manage rewards at the district level (for all schools) you can email `}</span>
                                <span className='edit-reward-bold-text'>support@hilightedu.com</span>
                                <span>. The Support Team will get back to you shortly!</span>
                            </div>
                        }
                        <Box className='edit-reward-top-box'>
                            <Box>
                                <Box>
                                    <TextField 
                                        label='Reward Name'
                                        variant='outlined'
                                        className='edit-reward-field edit-reward-text-field'
                                        value={rewardName || ''}
                                        type='text'
                                        onChange={(e) => setRewardName(e.target.value)}
                                    />
                                    <TextField 
                                        label='Description (optional)'
                                        variant='outlined'
                                        className='edit-reward-field edit-reward-text-field'
                                        id='edit-description-field'
                                        value={rewardDescription || ''}
                                        type='text'
                                        multiline
                                        rows={7}
                                        onChange={(e) => setRewardDescription(e.target.value)}
                                        inputProps={{
                                            maxLength: 1000
                                        }}
                                    />
                                    </Box>
                                <Box>
                                    <TextField 
                                        label='Cost'
                                        variant='outlined'
                                        className='edit-reward-field edit-reward-cost-field'
                                        value={rewardCost || ''}
                                        type='number'
                                        onChange={(e) => {
                                            try {
                                                setRewardCost(parseInt(e.target.value));
                                            } catch {
                                                setRewardCost(undefined)
                                            }
                                        }}
                                    />
                                    <TextField 
                                        label='Limit (optional)'
                                        variant='outlined'
                                        className='edit-user-field edit-reward-cost-field'
                                        value={rewardLimit || ''}
                                        type='number'
                                        onChange={(e) => {
                                            try {
                                                setRewardLimit(parseInt(e.target.value))
                                            } catch {
                                                setRewardLimit(null);
                                            }
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Box>
                            </Box>
                            <Box className='edit-reward-preview'>
                                <h4 className='edit-reward-preview-header'>Preview:</h4>
                                <RewardsCard 
                                    imageUrl={selectedPhotoFileLink || rewardToUpdate?.imageUrl || defaultImageData?.imageUrl || ''}
                                    rewardDisplayName={rewardName || ''}
                                    rewardDescription={rewardDescription || ''}
                                    rewardCost={rewardCost || 0}
                                    rewardId={rewardToUpdate?.id || 1}
                                    rewardCardType={RewardCardType.Available}
                                    rewardLimitCount={rewardLimit || undefined}
                                    key={1} // only one on the popup so we don't need to worry about this
                                    userId={1} // not used
                                    userPointsToSpend={0} // set to zero so they can't redeem
                                    handleRedeemReward={() => {}} // don't do anything if they click the button
                                />
                                <Button 
                                    variant={'contained'}
                                    id='change-reward-photo-button'
                                    size='small'
                                    component='label'
                                >
                                    Change Photo
                                    <input 
                                        type='file'
                                        hidden
                                        onChange={handleImageFileInput}
                                        accept={'image/x-png,image/gif,image/jpeg'}
                                    />
                                </Button>
                            </Box>
                        </Box>
                            {districtSchools?.length > 1 &&
                                 <Box className='edit-reward-box edit-reward-school'>
                                    <FormControlLabel
                                        control={
                                            <Switch 
                                                checked={isDistrictWide} 
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                    setIsDistrictWide(event.target.checked);
                                                }} 
                                                
                                            />
                                                }
                                        label='Offer at all schools in district'
                                    />
                                    {
                                        !isDistrictWide &&
                                        <Box className='edit-reward-wide-field'>
                                            <Autocomplete
                                                disablePortal
                                                id='edit-rewards-school-field'
                                                className='edit-reward-field'
                                                options={districtSchools}
                                                value={selectedSchool || null}
                                                getOptionLabel={(option) => option.name}
                                                renderInput={(params) => <TextField {...params} label='Select School' />}
                                                onChange={(event: any, value: School | null) => {
                                                    setSelectedSchool(value || undefined)
                                                }}
                                            />
                                        </Box>
                                    }
                                </Box>
                            }
                        <Box className='edit-reward-wide-field'>
                            <Autocomplete
                                disablePortal
                                id='edit-reward-owner-field'
                                className='edit-reward-field'
                                options={districtUsers}
                                value={selectedUser || null}
                                getOptionLabel={(option) => {return `${option.firstName} ${option.lastName}`}}
                                renderInput={(params) => <TextField {...params} label='Select Reward Owner' />}
                                onChange={(event: any, value: UserData | null) => {
                                    setSelectedUser(value || undefined)
                                }}
                            />
                        </Box>
                        <Box className='edit-reward-redeem-instructions'>
                            <TextField 
                                label='Redeem Instructions'
                                variant='outlined'
                                className='edit-reward-field'
                                id='edit-reward-instructions-field'
                                value={redeemInstructions || ''}
                                type='text'
                                multiline
                                rows={3}
                                onChange={(e) => setRedeemInstructions(e.target.value)}
                                inputProps={{
                                    maxLength: 1000
                                }}
                            />
                        </Box>
                    </div>
                    }
                </DialogContent>
                <DialogActions>
                    <Button 
                        variant='contained'
                        onClick={handleClickSave}
                        disabled={
                            updateInProgress ||
                            !(rewardName && rewardName !== '' 
                            && rewardCost && rewardCost > 0 
                            && (selectedSchool || isDistrictWide)
                            && selectedUser)
                        }
                    >
                        {reactivateReward ? 'Reactivate' : 'Save'}
                    </Button>
                    <Button 
                        variant='contained'
                        onClick={handleClickClose}
                        disabled={updateInProgress}
                        id='cancel-edit-reward-button'
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    )
}