import React, {useState} from 'react';
import { Box, Tab } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Bar, BarChart, ResponsiveContainer, XAxis, YAxis } from 'recharts';

import './OverallStatistics.css';

export interface OverallStatisticsChartProps {
    countRecognitionEarnedData: { month: string, count: number}[],
    countRecognitionGivenData: { month: string, count: number}[]
}

export function OverallStatisticsChart(props: OverallStatisticsChartProps) {
    const { countRecognitionEarnedData, countRecognitionGivenData} = props;
    const [tabValue, setTabValue] = useState('1');

    const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
    	setTabValue(newValue);
  	};

    return (
        <Box className='overall-statistics-chart-box'>
            <TabContext value={tabValue}>
                <Box className='overall-statistics-chart-tabs'>
                    <TabList onChange={handleChangeTab} aria-label="basic tabs example">
                        <Tab label="Total Earned" value='1' />
                        <Tab label="Total Given" value='2' />
                    </TabList>
                </Box>
                <TabPanel value='1'>
                    <Box className='overall-statistics-chart-tab-box'>
                        <ResponsiveContainer width={'100%'} height={'100%'}>
                            <BarChart
                                width={450}
                                height={300}
                                data={countRecognitionEarnedData}
                                margin={{left: -30}}
                                key={Math.random()} // Used to make the animation work even with re-render
                            >
                                <XAxis dataKey='month' ticks={['Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun']}/>
                                <YAxis allowDecimals={false}/>
                                <Bar dataKey='count' fill='#7B2BBE'/>
                            </BarChart>
                        </ResponsiveContainer>
                    </Box>
                </TabPanel>
                <TabPanel value='2'>
                    <Box className='overall-statistics-chart-tab-box'>
                        <ResponsiveContainer width={'100%'} height={'100%'}>
                            <BarChart
                                width={450}
                                height={350}
                                data={countRecognitionGivenData}
                                margin={{left: -30}}
                                key={Math.random()} // Used to make the animation work even with re-render
                            >
                                <XAxis dataKey='month' ticks={['Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun']}/>
                                <YAxis allowDecimals={false}/>
                                <Bar dataKey='count' fill='#7B2BBE'/>
                            </BarChart>
                        </ResponsiveContainer>
                    </Box>
                </TabPanel>
            </TabContext>
            
        </Box>
    )
}