import { useEffect, useState } from "react";

import { Bar, BarChart, ResponsiveContainer, XAxis, YAxis, Tooltip, Legend } from "recharts";
import { Box, FormControl, MenuItem, Select } from "@mui/material";

import { UserTokensByType } from "../../../../../../src/types/dataDashboard";

export interface StaffKredsChartProps {
    data: UserTokensByType[],
    chartLegend: {
        id: string,
        color: string,
        value: string
    }[],
    usersVisibleCount: number,
}

export function CoreValuesChart(props: StaffKredsChartProps) {
    const {chartLegend, data, usersVisibleCount} = props;

    // staff tokens earned data
    const [userTokensData, setUserTokensData] = useState<UserTokensByType[]>([]);
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);
    const [userTokensDataGroups, setUserTokensDataGroups] = useState<UserTokensByType[][]>([]);
    const [dropdownOptions, setDropDownOptions] = useState<{value: number, displayText: string}[]>([]);
    const [selectedDropdownValue, setSelectedDropdownValue] = useState<number | undefined>();

    const changeGroupVisible = (groupValue: number) => {
        setSelectedDropdownValue(groupValue);
        setUserTokensData(userTokensDataGroups[groupValue]);
    }

    useEffect(() => {
        // If there are more than 15 staff, show the top 15, and then show a drop down to view the rest
        // We know the data is already ordered greatest to least based on the API contract
        // Points Earned Data
        // Break the list of staff into segments of 15 and set up the dropdown
        const dropDownData = getChartDropDownValues(data, usersVisibleCount);
        setDropDownOptions(dropDownData.dropDownValues);
        setUserTokensDataGroups(dropDownData.groups);
        setIsDropdownVisible(dropDownData.groups.length > 1);
    }, [data]);

    // Always set the default group being shown to the first one when the group options are loaded
    useEffect(() => {
        changeGroupVisible(0);
    }, [userTokensDataGroups]);

    return (
        <Box className='staff-kreds-chart-panel-box'>
            <div className='staff-tokens-dropdown'>
                {isDropdownVisible &&
                    <FormControl size={'small'}>
                        <Select
                            id='staff-tokens-earned-group'
                            onChange={(data) => {
                                const newSelectedValue = data.target?.value as number | undefined;
                                if (newSelectedValue !== undefined) {
                                    changeGroupVisible(newSelectedValue);
                                }
                            }}
                            value={selectedDropdownValue}
                        >
                            {dropdownOptions?.map(option => {
                                return (<MenuItem key={option.value} value={option.value}>
                                    {option.displayText}
                                </MenuItem>
                                )
                                
                            })}
                        </Select> 
                    </FormControl> 
                    
                }
            </div>
            <ResponsiveContainer width={'100%'} height={500}>
                <BarChart
                    width={450}
                    data={userTokensData}
                    layout={'vertical'}
                >
                    <XAxis type={'number'} allowDecimals={false}/>
                    <YAxis dataKey='userFullName' type={'category'} interval={0} width={125}/>
                    <Tooltip />
                    {
                        chartLegend.map((tokenType) => {
                            return <Bar key={tokenType.value} dataKey={tokenType.value} stackId='a' barSize={20} fill={tokenType.color}/>
                        })
                    }
                    <Legend payload={chartLegend}/>
                </BarChart>
            </ResponsiveContainer>
        </Box>
            
    );
}

/**
 * Splits a list up into chunks of a given length
 * @param list 
 * @param chunkSize 
 * @returns 
 */
export function chunk(list: any[], chunkSize: number): any[][] {
    if (!list || list.length === 0 || chunkSize <= 0) {
        return [];
    }

    const chunks = [];
    let i = 0;

    while (i < list.length) {
        chunks.push(list.slice(i, i += chunkSize))
    }
    return chunks;
}

export function getChartDropDownValues(data: UserTokensByType[], usersVisibleCount: number): {
    dropDownValues: {
        value: number,
        displayText: string
    }[],  
    groups: any[][]
} {
    const groups = chunk(data, usersVisibleCount);
    const dropDownValues = groups.map((group, index) => {
        let groupDisplayText = '';
        if (index === 0) {
            groupDisplayText = `Top ${usersVisibleCount} staff members`
        } else if (index === (groups.length -1)) {
            groupDisplayText = 'Last set of staff members'
        } else {
            const firstNumber = index * usersVisibleCount + 1;
            const secondNumber = firstNumber + usersVisibleCount;
            groupDisplayText = `Staff ${firstNumber} - ${secondNumber - 1}`;
        }
        return {
            value: index,
            displayText: groupDisplayText,
        }
    });

    return {
        dropDownValues,
        groups
    }
}