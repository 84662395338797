import React, { useState } from 'react';
import * as Sentry from "@sentry/react";
import { Avatar, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
// import Resizer from "react-image-file-resizer";
import { useSelector } from 'react-redux';

import { UserServiceApi } from '../../../services/userService';
import { authSelector } from '../../../store/authSlice';

import './ChangeProfilePhotoPopup.css';

export interface ChangeProfilePhotoPopupProps {
    userId: number;
    popupOpen: boolean;
    closePopup: () => void;
    handleSaveUserData: (changedUserData: {newProfilePhotoImagePath?: string}) => Promise<void>
}
export default function ChangeProfilePhotoPopup(props: ChangeProfilePhotoPopupProps) {
    const { userId, popupOpen, closePopup, handleSaveUserData} = props;

    const [selectedPhotoFile, setSelectedPhotoFile] = useState<File | undefined>(undefined);
    const [selectedPhotoFileLink, setSelectedPhotoFileLink] = useState<string | undefined>(); // I think this can be used to preview the file before uploading
    const [photoUploadInProgress, setPhotoUploadInProgress] = useState(false);

    const authDataStore = useSelector(authSelector);
	const { token } = authDataStore;

    // const resizeFile = async (file: File): Promise<string | File | Blob | ProgressEvent<FileReader>> => {
    //     return new Promise((resolve) => {
    //         Resizer.imageFileResizer(file, 300, 300, 'JEPG', 100, 0, (uri) => { resolve(uri);}, 'base64');
    //     });
    // }

    const handleFileInput = async (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event?.target?.files && event.target.files.length > 0) {
            const file = event?.target?.files[0]
            setSelectedPhotoFile(file);
            const fileLink = URL.createObjectURL(file);
            setSelectedPhotoFileLink(fileLink);

        } else {
            setSelectedPhotoFile(undefined); 
            setSelectedPhotoFileLink(undefined);
        }
       
    }

    const handleClickUpload = async (file?: File | null) => {
        setPhotoUploadInProgress(true);
        if (userId && file) {

            try {
                const userServiceApi = UserServiceApi()
                const newProfilePhotoImagePath = await userServiceApi.uploadProfilePhoto(userId, file, token!);
                // Save the new imagePath for the user
                await handleSaveUserData({newProfilePhotoImagePath})

            } catch (err) {
                // log the error
			    Sentry.captureException(err);
            }
        }   

        setSelectedPhotoFile(undefined);
        setSelectedPhotoFileLink(undefined);
        setPhotoUploadInProgress(false);
        closePopup();
    }

    const handleClickCancel = () => {
        setSelectedPhotoFile(undefined);
        setSelectedPhotoFileLink(undefined);
        closePopup();
    }

    return (
        <Box>
            <Dialog open={popupOpen} onClose={handleClickCancel}>
                <DialogTitle>Change Profile Photo</DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClickCancel}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: 'grey',
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent className='change-profile-photo-content'>
                    <Typography> Allowed file types are PNG and JPEG</Typography>
                    
                    {photoUploadInProgress ? 
                        <div className='profile-photo-loading-progress'><CircularProgress /></div>
                    : 
                        <div className='change-profile-photo-input'>
                            {!selectedPhotoFileLink ?
                                <div className='select-profile-photo'>
                                    <Box className='no-profile-photo-selected-circle'>
                                        <Button 
                                            component='label'
                                            id='select-new-profile-photo-button'
                                        >
                                            Select Photo
                                            <input 
                                                type='file'
                                                hidden
                                                onChange={handleFileInput}
                                                accept={'image/x-png,image/gif,image/jpeg'}
                                            />
                                        </Button>
                                    </Box>
                                </div>
                            :
                                <div className='select-profile-photo'>
                                    <Avatar
                                        alt='New User avatar'
                                        className='update-photo-avatar'
                                        src={selectedPhotoFileLink}
                                    />
                                    <Button 
                                        component='label'
                                        id='select-different-profile-photo-button'
                                        size='small'
                                        variant='outlined'
                                    >
                                        Change Photo
                                        <input 
                                            type='file'
                                            hidden
                                            onChange={handleFileInput}
                                            accept={'image/x-png,image/gif,image/jpeg'}
                                        />
                                    </Button>
                                </div>
                            }
                            
                        </div>
                    }
                    
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {handleClickUpload(selectedPhotoFile)}}
                        variant='contained'
                        disabled={!selectedPhotoFile || photoUploadInProgress}
                    > Save</Button>
                    <Button 
                        onClick={handleClickCancel}
                        variant='contained'
                        id='cancel-change-profile-photo-button'
                    >Cancel</Button>
                </DialogActions>
            </Dialog>
        </Box>
    )


}
