import React, { useEffect, useState } from 'react';
import * as Sentry from '@sentry/react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles';

import { PublicRoute } from './PublicRoute';
import { PrivateRoute } from './PrivateRoute';
import Login from './Login/Login';
import CleverLogin from './Login/CleverLogin';
import TeacherDashboard from './Dashboards/TeacherDashboard';
import ScrollToTop from './Teachers/ScrollToTop';
import { authSelector, verifyToken } from '../store/authSlice';
import { useAppDispatch } from '../store/hooks';
import AddToHomeScreenPopup from './AddToHomeScreenPopup';
import { Home } from './Home';
import Loading from './Loading';

import './App.css';

export const theme = createTheme({ // TO DO - this should be at a higher level
	typography: {
	  fontFamily: 'Catamaran',
	},
	palette: {
		secondary: {
			main:  '#240145'
		},
		primary: {
			dark:  '#240145',
			main:  '#240145'
		},
		info:{
		  main: '#fcbe57',
		  dark: '#f09704'
		},
	}
});

function App() {
	const authDataStore = useSelector(authSelector);
	const dispatch = useAppDispatch();

	let { authLoading, isAuthenticated } = authDataStore;

	// verify token on app load and page refresh
	useEffect(() => {
		dispatch(verifyToken()); // this should set authLoading to false  
	}, []);

	// Handle mobile install (aka save to homescreen)
	// TO DO - manage the state better instead of having to pass it down into components
	// NOTE - beforeinstallprompt is not supported by all browsers. Safari needs a different approach
	const [showMobileInstall, setShowMobileInstall ] = useState<boolean>(false);
	const [installPrompt, setInstallPrompt] = useState<any | undefined>();
	const [isIos, setIsIos] = useState(false);
	const [showAddToHomeScreenPopup, setShowAddToHomeScreenPopup] = useState(false);
	
	// trigger whether the install mobile button should be visible
	// If using chrome on a non-ios device, we can use the beforeinstallprompt which will only show if they haven't installed it already
	// if on an ios device, we will know the user hasn't installed it if they are not in standalone mode
	useEffect(() => {
		// handle non-ios devices
		// chrome will only trigger the beforeInstall prompt if this url is not installed already
		window.addEventListener("beforeinstallprompt", (event) => {
			event.preventDefault();
			setInstallPrompt(event);
			setShowMobileInstall(true);
		});

		// handle ios devices
		// check if we are already in standalone mode
    	const iOS = navigator.userAgent.match(/iPhone|iPad|iPod/i);
		// @ts-ignore: Property 'standalone' does not exist on type 'Navigator'.
		if (iOS && !(("standalone" in navigator) && navigator.standalone)) {
			setIsIos(true);
			setShowMobileInstall(true);
		}
	}, []);

	const handleClickMobileInstallNonIos = async () => {
		if (!installPrompt) {
			return;
		}
		await installPrompt.prompt();
		setInstallPrompt(undefined);
		setShowMobileInstall(false);
	}

	const handleClickMobileInstallIos = async () => {
		// show popup
		setShowAddToHomeScreenPopup(true);
		// Hide install button - TO DO - maybe only hide it if we add it?
		// setShowMobileInstall(false);
	}

	const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

	if (authLoading) {
		return (
			<Loading/>
		)
	}

	return (
		<ThemeProvider theme={theme}>
		<StyledEngineProvider injectFirst>
		<div className="App">
			<BrowserRouter>
				<ScrollToTop />
				<AddToHomeScreenPopup popupOpen={showAddToHomeScreenPopup} closePopup={() => {setShowAddToHomeScreenPopup(false)}}/>
				<div>
					<div className="content">
						<SentryRoutes>
							{/* Public routes: Aka the login page - both / and /login go to Login */}
							<Route path="/" element={
								<PublicRoute 
									isAuthenticated={isAuthenticated}
								>
									<Home/>
								</PublicRoute>
							}/>
							<Route path="/login" element={
								<PublicRoute 
									isAuthenticated={isAuthenticated}
								>
									<Login 
										showMobileInstall={showMobileInstall} 
										handleClickMobileInstall={isIos ? handleClickMobileInstallIos : handleClickMobileInstallNonIos}
									/>
								</PublicRoute>
							}/>
							<Route path="/privacypolicy" element={	
								<PublicRoute 
									isAuthenticated={isAuthenticated}
								>
									<Home 
										privacyPopupOpen={true}
									/>
								</PublicRoute>
							}/>
							
							{/* Route for Clever login */}
							<Route path="/oath/clever" element={<CleverLogin/> }  />

							{/* Private routes */}
							{/* <Route path="/adminDashboard" element={<PrivateRoute isAuthenticated={isAuthenticated}> <AdminDashboard/></PrivateRoute>}  /> */}
							<Route path="/teacherDashboard/*" element={
								<PrivateRoute 
									isAuthenticated={isAuthenticated}
								>
									<TeacherDashboard 
										showMobileInstall={showMobileInstall} 
										handleClickMobileInstall={isIos ? handleClickMobileInstallIos : handleClickMobileInstallNonIos}
									/>
								</PrivateRoute> 
							}/>
							
							{/* By default, redirect to login if path doesn't exist */}
							<Route path="*" element={<Navigate to='/login' replace />}/>
						</SentryRoutes>
					</div>
				</div>

			</BrowserRouter>
		</div>
		</StyledEngineProvider >
		</ThemeProvider>
	);
}

export default App;
