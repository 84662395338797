import React from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import './UnsuccessfulPopup.css';

export interface UnsuccessfulPopupProps {
    popupOpen: boolean;
    closePopup: () => void;
}

export default function UnsuccessfulPopup(props: UnsuccessfulPopupProps) {
    const { popupOpen, closePopup} = props;

    return (
        <Box>
            <Dialog open={popupOpen} onClose={closePopup}>
                <DialogTitle>Whoops, something went wrong!</DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={closePopup}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: 'grey',
                    }}
                    >
                    <CloseIcon />
                </IconButton>
                <DialogContent>
                    <Typography>{`Something when wrong when trying to send your hilight. Please try again! If the issue persists, please copy the message you were trying to send (so you don't lose it!) and refresh this page.`}</Typography>
                    <Typography className='support-notify-text'>The Hilight Support team has been notified of this error.</Typography>
                </DialogContent>
                
                <DialogActions>
                    <Button
                        variant='contained'
                        onClick={closePopup}
                        id='close-recognition-popup-button'
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    )
}