import React from 'react';

import { Button } from '@mui/material';

import { RecognitionData } from '../../../../src/types/recognition';

export interface DownloadToCSVButtonProps {
    data: any;
    fileName: string;
    buttonText: string;
    handleAfterExport: () => void;
}

export default function DownloadCSVButton(props: DownloadToCSVButtonProps) {
    const {
        data,
        fileName,
        buttonText,
        handleAfterExport
    } = props;

    const downloadCSV = () => {
        const csvData = new Blob([convertRecognitionDataToCSVString(data)], { type: 'text/csv' });
        const csvURL = URL.createObjectURL(csvData);
        const link = document.createElement('a');
        link.href = csvURL;
        link.download = `${fileName}.csv`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        handleAfterExport();
    };

    return (
        <Button onClick={downloadCSV} variant='contained'>{buttonText}</Button>
    );
}

export function convertRecognitionDataToCSVString(recognitionData: RecognitionData[]): string {
    // Convert the data array into a CSV string
    const csvString = [
        // specify the headers
        [
            "Receiver First Name", // 1
            "Receiver Last Name", // 2
            "Receiver School Name", // 3
            "Sender First Name", // 4 
            "Sender Last Name", // 5
            "Core Value", // 6
            "Hilight Text", // 7
            "Date", // 8
        ],
        // map the recognition fields
        ...recognitionData.map(item => [
            item.userReceiverName.firstName, // 1
            item.userReceiverName.lastName, // 2
            item.userReceiverName.schoolName, // 3
            item.userSenderName.firstName, // 4
            item.userSenderName.lastName, // 5
            item.tokenType.tokenCoreValue, // 6
            item.text || '', // 7
            item.created, // 8
        ])
    ]
    .map(row => row.join(","))
    .join("\n");
      
    return csvString;
};