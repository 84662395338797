import React, {useEffect, useState} from 'react';
import { useSearchParams, useNavigate } from  'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { authSelector, userCleverLogin } from '../../store/authSlice';

// After successfully logging in, Clever redirects them to the uri we provided, 
// such as https://www.hilightdashboard.com/oath/clever, 
// but with a code appended to it like: 
// https://www.hilightdashboard.com/oath/clever/?code=5e87ec5070ac5ce87da97daac069a82f6c95818d
export default function CleverLogin() {
    const [searchParams, setSearchParams] = useSearchParams();
    const [cleverCode, setCleverCode] = useState<string | null | undefined>(searchParams.get("code"));

    const authDataStore = useAppSelector(authSelector);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const { loginError } = authDataStore;

    // Whenever we reach this page, if the user is already logged in, Clever wants us to override the login session and re-log in the user

    // If there's a login error, return to login page
    useEffect(() => {
        if (loginError ) {
            navigate('/login');  
        }
    }, [loginError])



    useEffect(() =>  {
        // Make the call to clever with the code from the url
        // Note that this call handles removing the current user session if a user is already logged in
        if (cleverCode && !loginError)  { 
            dispatch(userCleverLogin(cleverCode)); // errors are caught in the authSlice
            setCleverCode(undefined); // set the clever code so we don't keep trying running this
        }

        // by default, just send the user back to login
        // this will redirect them to the dashboard if the clever login was successful
        navigate('/login');
    }, [cleverCode]); // This should only run once on page load so that we aren't trying to use the same clever code twice

    return (
        <span>Loading Clever Auth</span> // Should never show, instead the auth loading page shows 
    )
}