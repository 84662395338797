import React, { useEffect, useState } from 'react';
import { Typography, AppBar, Toolbar, Box, Button,Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Logo from '../images/hilight_hi_logo.png';
import FullLogo from '../images/hilight_white_logo.png'
import { Copyright } from './Copyright';
import { authSelector } from '../store/authSlice';
import LaptopImage from '../images/laptop.png';
import PrivacyPolicyPopup from './PrivacyPolicy/PrivacyPolicyPopup';
import Loading from './Loading';

import './Home.css';

export interface HomeProps {
	privacyPopupOpen?: boolean;
}

export function Home(props: HomeProps) {
	const { privacyPopupOpen } = props;
    const navigate = useNavigate();
	const authDataStore = useSelector(authSelector);
	const { authLoading, isAuthenticated } = authDataStore;

	useEffect(() => {
		if (isAuthenticated)  {
			privacyPopupOpen ? navigate('/teacherDashboard/privacypolicy') :
			navigate('/teacherDashboard/recognition')
		}
	}, [isAuthenticated]);

	const [showPrivacyPopup, setShowPrivacyPopup] = useState(false);
	useEffect(() => {
		// moved this in here so that the popup doesn't flicker on load
		setShowPrivacyPopup(privacyPopupOpen || false);
	}, [privacyPopupOpen]);

    const handleClickLogin = () => {
        navigate('/login');
    }

	const handleClickPrivacyPolicy = () => {
        setShowPrivacyPopup(true);
    }

	const closePrivacyPopup = () => {
		setShowPrivacyPopup(false);
	}

	if (authLoading || isAuthenticated) {
		return (
			<Loading/>
		)
	}

    return (
        <Box sx={{ flexGrow: 1 }} className='home-page'>
			<PrivacyPolicyPopup popupOpen={showPrivacyPopup} closePopup={closePrivacyPopup} />
            <AppBar position="static" >
            <Toolbar sx={{ background: "#240145"}}>
                <Box
                    component="img"
                    className='home-logo'
                    alt="hilight logo."
                    src={Logo}
                />
				<Box
					className='home-full-logo-box'
				>
					<Box 
						component="img"
						className='home-full-logo'
						src={FullLogo}
						paddingLeft={'4px'}
					/>
				</Box>
                <Button color="inherit" onClick={handleClickLogin}>Login</Button>
            </Toolbar>
            </AppBar>
			<Box className='home-page-body'>
				<Grid container component='main' className='home-grid'>
					<Grid 
						item
						xs={12}
						sm={12}
						md={6}
						className='home-grid-item'
					>
						<Box className='home-page-text'>
							<Typography className='welcome-text'>Welcome to Hilight</Typography>
							<Typography className='medium-text'>A tool that lets you, an educator, see, share, and celebrate the work that you and your fellow educators are doing every day.</Typography>
							<Typography className='smaller-text'>Hilight helps schools and districts make sure their teams feel seen, valued, and connected by celebrating the small wins and building a culture of gratitude all year long. </Typography>
							<Box className='home-page-big-login'>
								<Button 
									onClick={handleClickLogin}
									variant='contained'
									className='home-page-big-login-button'
									color='primary'
								>
									Login now and send a hilight!
								</Button>
							</Box>
							<Typography className='home-sub-text'>
								<span>Looking for more information or want to request a demo? Check out our website at </span>
								<a className='bold-text' href="https://www.hilightedu.com">www.hilightedu.com</a>
							</Typography>
							<Typography className='home-sub-text'>
								<span>Need help? Contact our support team at </span>
								<span className='bold-text'>support@hilightedu.com</span>
							</Typography>
						</Box>
					</Grid>
					<Grid 
						item
						xs={12}
						sm={12}
						md={6}
						className='home-grid-item'
					>
						<Box className='home-page-images'>
							<Box 
								component="img"
								src={LaptopImage}
								className='home-page-laptop-image'
							/>
						</Box>
					</Grid>
				</Grid>
            </Box>
			<Box className='footer'>
				<Button className='privacy-policy-button' size='small' onClick={handleClickPrivacyPolicy}>Privacy Policy</Button>
				<Typography> | </Typography>
				<Copyright className='copy-right-footer' />
			</Box>
            
        </Box> 
    )
}