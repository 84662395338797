import './sentry'; // needs to be imported first

import React from 'react';
import { createRoot } from 'react-dom/client';
import { createTheme, ThemeProvider } from '@mui/material';
import type {} from '@mui/x-data-grid/themeAugmentation';
import { Provider } from 'react-redux/es/exports';
import { GoogleOAuthProvider } from "@react-oauth/google"

import App from './components/App';
import { store } from './store/store';

import './index.css';

const theme = createTheme({
	typography: {
		fontFamily: 'Catamaran',
  	},
	components: {
		MuiDataGrid: {
			styleOverrides: {
				root: {
					backgroundColor: 'red',
				},
			},
		},
	}
});

const container = document.getElementById('root');
const root = createRoot(container!);
const googleClientId = '1024660301112-ff1v1iueov39spqu0lu1s5r0ou17ks9u.apps.googleusercontent.com';
root.render(
	<GoogleOAuthProvider clientId={googleClientId}>
		<Provider store={store}>
			<ThemeProvider theme={theme} >
				<App />
			</ThemeProvider>
		</Provider>
	</GoogleOAuthProvider>
);
