import React, { useEffect, useState } from 'react';
import { Box, FormControl, Grid, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { isMobile } from 'react-device-detect';
import { useSelector } from 'react-redux';
import * as Sentry from "@sentry/react";

import RecognitionFeedTabs from './FeedTabs';
import { SchoolYear } from '../../../../../src/util/dates';
import { MainServiceApi } from '../../../services/mainService';
import { authSelector } from '../../../store/authSlice';

import './Feed.css';

export interface RecognitionFeedProps {
	schoolId: number,
    userId: number
}

export function Feed(props: RecognitionFeedProps) {
    const {
        schoolId,
        userId
    } = props;

    const authDataStore = useSelector(authSelector);
	const { token } = authDataStore;

    const [ timeRangeSelection, setTimeRangeSelection] = useState<string>('');
    const [ startDate, setStartDate ] = useState<Date>();
    const [ endDate, setEndDate ] = useState<Date | undefined>(); // leave empty by default so we get up to and including today

    // Based on when the school was created in Hilight, generate the list of school years we should show
    const [ schoolYearOptions, setSchoolYearOptions] = useState<SchoolYear[]>([]);
    const getSchoolYearOptions = async (schoolId: number, token: string) => {
        try {
            const mainService = MainServiceApi();
            const schoolYears = await mainService.getSchoolYearsList({schoolId, token});
            setSchoolYearOptions(schoolYears);
            // By default, should be showing the current school year
            const currentSchoolYear = schoolYears.find(option => option.key === 'current');
            setTimeRangeSelection('current');
            setStartDate(currentSchoolYear?.startDate);
            setEndDate(undefined) // By defaul will get up and including to today if left undefined

        } catch (err) {
            // log the error
			Sentry.captureException(err);
        }
    }
    // Only re-fetch the school year options if the school id changes
    useEffect(() => {
        getSchoolYearOptions(schoolId, token!);
    }, [schoolId]);

    const handleChangeTimeSelection = (event: SelectChangeEvent) => {
        const newSelectedSchoolYear = schoolYearOptions.find(option  => option.key === event.target?.value);
        if (newSelectedSchoolYear) {
            setTimeRangeSelection(newSelectedSchoolYear.key);
            setStartDate(newSelectedSchoolYear.startDate);
            setEndDate(newSelectedSchoolYear.endDate);
        }
    }
    return (
        <div className='feed-page'>
            <Box className='feed-page-header'>
                <Box className='page-heading'>Recognition Bulletin</Box>
                {/* Only show the school year drop down for schools that have more than one year*/}
                {schoolYearOptions?.length > 1 &&
                    <FormControl size='small' className='bulletin-dropdown' sx={{ m: 1, minWidth: 120 }}>
                        <Select
                            id='spotlight-time-select'
                            value={timeRangeSelection}
                            onChange={handleChangeTimeSelection}
                        >
                           {schoolYearOptions.map((schoolYear) => <MenuItem key={schoolYear.key} value={schoolYear.key}>{schoolYear.displayText}</MenuItem>)}                           
                        </Select>
                    </FormControl> 
                }
                
            </Box>
            {startDate && 
            <Box className={`${isMobile ? 'feed-grid-box-mobile' : 'feed-grid-box'}`}>
                <Grid container spacing={3} className='feed-grid'>
                    <Grid item xs={12} className={`${isMobile ? 'feed-grid-item-mobile' : 'feed-grid-item'}`}>
                        <div className='feed'>
                            <RecognitionFeedTabs
                                schoolId={schoolId}
                                userId={userId}
                                startDate={startDate}
                                endDate={endDate}
                            />
                        </div>
                    </Grid>
                </Grid>
            </Box>
            }
            
        </div>
    )
}