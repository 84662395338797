import React from 'react';
import { Box, Dialog, DialogContent, DialogTitle, Typography, IconButton, List, ListItem } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { privacyHtml } from './privacy';

import './PrivacyPolicyPopup.css';

export interface PrivacyPolicyPopupProps {
    popupOpen: boolean;
    closePopup: () => void;
}

export default function PrivacyPolicyPopup(props: PrivacyPolicyPopupProps) {
    const { popupOpen, closePopup } = props;

    return (
        <Box>
            <Dialog open={popupOpen} onClose={closePopup}>
                <DialogTitle>Hilight Privacy Policy</DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={closePopup}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: 'grey',
                    }}
                    >
                    <CloseIcon />
                </IconButton>
                <DialogContent>
                    <div dangerouslySetInnerHTML={{__html: privacyHtml}} />
                    {/* <Typography className='popup-italic'>Last updated July 17, 2024</Typography>
                    <Typography>
                        <p>This privacy notice for KidKred Inc, ("Company," "we," "us," or "our"), describes how and why we might collect, store, use, and/or share ("process") your information when you use our services ("Services"), such as when you:</p>
                        <List>
                            <ListItem> - Visit our website at https://www.hilightedu.com, our dashboard at https://www.hilightdashboard.com, or any website of ours that links to this privacy notice </ListItem>
                            <ListItem> - Engage with us in other related ways, including any sales, marketing, or events</ListItem>
                        </List>
                        <p>Questions or concerns? Reading this privacy notice will help you understand your privacy rights and choices. If you do not agree with our policies and practices, please do not use our Services. If you still have any questions or concerns, please contact us at support@kidkred.com.</p>
                    </Typography>
                    <Typography variant='h5'>SUMMARY OF KEY POINTS</Typography>
                    <Typography>
                        <p>What personal information do we process? When you visit, use, or navigate our Services, we may process personal information depending on how you interact with KidKred and the Services, the choices you make, and the products and features you use. Learn more about personal information you disclose to us.</p>
                        <p>Do we process any sensitive personal information? We do not process sensitive personal information.</p>
                        <p>Do we receive any information from third parties? We do not receive any information from third parties.</p>
                        <p>How do we process your information? We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent. We process your information only when we have a valid legal reason to do so. Learn more about how we process your information below.</p>
                        <p>In what situations and with which parties do we share personal information? We may share information in specific situations and with specific third parties. Learn more about when and with whom we share your personal information below.</p>
                        <p>How do we keep your information safe? We have organizational and technical processes and procedures in place to protect your personal information. However, no electronic transmission over the internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information. Learn more about how we keep your information safe below.</p>
                        <p> What are your rights? Depending on where you are located geographically, the applicable privacy law may mean you have certain rights regarding your personal information. Learn more about your privacy rights below.</p>
                        <p>How do you exercise your rights? The easiest way to exercise your rights is by submitting a data subject access request, or by contacting us. We will consider and act upon any request in accordance with applicable data protection laws below.</p>
                        <p>Want to learn more about what KidKred does with any information we collect? Review the privacy notice in full.</p>
                    </Typography>
                    <Typography>For our full privacy policy, please visit </Typography>
                    <a className='privacy-policy-link' href='https://www.hilightedu.com/privacy-policy'>https://www.hilightedu.com/privacy-policy</a> */}
                    {/* <Typography variant='h5'>1. WHAT INFORMATION DO WE COLLECT?</Typography>
                    <p className='popup-bold'>Personal information you disclose to us</p>
                    <p>In Short: We collect personal information that you provide to us.</p>
                    <p>We collect personal information that you voluntarily provide to us when you register on the Services, express an interest in obtaining information about us or our products and Services, when you participate in activities on the Services, or otherwise when you contact us.</p>
                    <p>Personal Information Provided by You. The personal information that we collect depends on the context of your interactions with us and the Services, the choices you make, and the products and features you use. The personal information we collect may include the following:</p>
                    <List>
                        <ListItem>- names</ListItem>
                        <ListItem>- usernames</ListItem>
                        <ListItem>- passwords</ListItem>
                        <ListItem>- contact preferences</ListItem>
                        <ListItem>- contact or authentication data</ListItem>
                        <ListItem>- email addressese</ListItem>
                    </List>
                    <p>Sensitive Information. We do not process sensitive information.</p>
                    <p>All personal information that you provide to us must be true, complete, and accurate, and you must notify us of any changes to such personal information.</p>
                    <p className='popup-bold'>Information automatically collected</p>
                    <p>In Short: Some information — such as your Internet Protocol (IP) address and/or browser and device characteristics — is collected automatically when you visit our Services.</p>
                    <p>We automatically collect certain information when you visit, use, or navigate the Services. This information does not reveal your specific identity (like your name or contact information) but may include device and usage information, such as your IP address, browser and device characteristics, operating system, language preferences, referring URLs, device name, country, location, information about how and when you use our Services, and other technical information. This information is primarily needed to maintain the security and operation of our Services, and for our internal analytics and reporting purposes.</p>
                    <p>Like many businesses, we also collect information through cookies and similar technologies.</p>
                    <p>The information we collect includes:</p>
                    <List>
                        <ListItem>- Log and Usage Data. Log and usage data is service-related, diagnostic, usage, and performance information our servers automatically collect when you access or use our Services and which we record in log files. Depending on how you interact with us, this log data may include your IP address, device information, browser type, and settings and information about your activity in the Services (such as the date/time stamps associated with your usage, pages and files viewed, searches, and other actions you take such as which features you use), device event information (such as system activity, error reports (sometimes called "crash dumps"), and hardware settings).</ListItem>
                        <ListItem>- Device Data. We collect device data such as information about your computer, phone, tablet, or other device you use to access the Services. Depending on the device used, this device data may include information such as your IP address (or proxy server), device and application identification numbers, location, browser type, hardware model, Internet service provider and/or mobile carrier, operating system, and system configuration information.</ListItem>
                        <ListItem>- Location Data. We collect location data such as information about your device's location, which can be either precise or imprecise. How much information we collect depends on the type and settings of the device you use to access the Services. For example, we may use GPS and other technologies to collect geolocation data that tells us your current location (based on your IP address). You can opt out of allowing us to collect this information either by refusing access to the information or by disabling your Location setting on your device. However, if you choose to opt out, you may not be able to use certain aspects of the Services.</ListItem>
                    </List>
                    <Typography variant='h5'>HOW DO WE PROCESS YOUR INFORMATION?</Typography>
                    <p>In Short: We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent.</p>
                    <p>We process your personal information for a variety of reasons, depending on how you interact with our Services, including:</p>
                    <List>
                        <ListItem>- To facilitate account creation and authentication and otherwise manage user accounts. We may process your information so you can create and log in to your account, as well as keep your account in working order.</ListItem>
                        <ListItem>- To identify usage trends. We may process information about how you use our Services to better understand how they are being used so we can improve them.</ListItem>
                    </List>
                    <Typography variant='h5'>WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</Typography>
                    <p>In Short: We may share information in specific situations described in this section and/or with the following third parties.</p>
                    <p>Vendors, Consultants, and Other Third-Party Service Providers. We may share your data with third-party vendors, service providers, contractors, or agents ("third parties") who perform services for us or on our behalf and require access to such information to do that work. We have contracts in place with our third parties, which are designed to help safeguard your personal information. This means that they cannot do anything with your personal information unless we have instructed them to do it. They will also not share your personal information with any organization apart from us. They also commit to protect the data they hold on our behalf and to retain it for the period we instruct. The third parties we may share personal information with are as follows:</p>
                    <List>
                        <ListItem>- Advertising, Direct Marketing, and Lead Generation: None</ListItem>
                        <ListItem>- Affiliate Marketing Programs: None</ListItem>
                        <ListItem>- Allow Users to Connect to Their Third-Party Accounts: Google</ListItem>
                        <ListItem>- Cloud Computing Services: AWS</ListItem>
                        <ListItem>- Communicate and Chat with Users: None</ListItem>
                        <ListItem>- Content Optimization: None</ListItem>
                        <ListItem>- Data Backup and Security: None</ListItem>
                        <ListItem>- Functionality and Infrastructure Optimization: None</ListItem>
                        <ListItem>- Invoice and Billing: None</ListItem>
                        <ListItem>- Retargeting Platforms: None</ListItem>
                        <ListItem>- Social Media Sharing and Advertising: None</ListItem>
                        <ListItem>- User Account Registration and Authentication: Clever and Google</ListItem>
                        <ListItem>- User Commenting and Forums: None</ListItem>
                        <ListItem>- Web and Mobile Analytics: Metabase</ListItem>
                        <ListItem>- Website Hosting: Heroku and AWS</ListItem>
                        <ListItem>- Website Performance Monitoring: Sentry</ListItem>
                        <ListItem>- Website Testing: None</ListItem>
                    </List>
                    <p>We also may need to share your personal information in the following situations:</p>
                    <List>
                        <ListItem>- Business Transfers. We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.</ListItem>
                    </List>
                    <Typography variant='h5'>DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</Typography>
                    <p>In Short: We may use cookies and other tracking technologies to collect and store your information.</p>
                    <p>We may use cookies and similar tracking technologies (like web beacons and pixels) to access or store information. Specific information about how we use such technologies and how you can refuse certain cookies is set out in our Cookie Notice.</p>
                    <Typography variant='h5'>HOW LONG DO WE KEEP YOUR INFORMATION?</Typography>
                    <p>In Short: We keep your information for as long as necessary to fulfill the purposes outlined in this privacy notice unless otherwise required by law.</p>
                    <p>We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy notice, unless a longer retention period is required or permitted by law (such as tax, accounting, or other legal requirements). No purpose in this notice will require us keeping your personal information for longer than the period of time in which users have an account with us.</p>
                    <p>When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize such information, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.</p>
                    <Typography variant='h5'>HOW DO WE KEEP YOUR INFORMATION SAFE?</Typography>
                    <p>In Short: We aim to protect your personal information through a system of organizational and technical security measures.</p>
                    <p>We have implemented appropriate and reasonable technical and organizational security measures designed to protect the security of any personal information we process. However, despite our safeguards and efforts to secure your information, no electronic transmission over the Internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information. Although we will do our best to protect your personal information, transmission of personal information to and from our Services is at your own risk. You should only access the Services within a secure environment.</p>
                    <Typography variant='h5'>DO WE COLLECT INFORMATION FROM MINORS?</Typography>
                    <p>In Short: We do not knowingly collect data from or market to children under 18 years of age.</p>
                    <p>We do not knowingly solicit data from or market to children under 18 years of age. By using the Services, you represent that you are at least 18 or that you are the parent or guardian of such a minor and consent to such minor dependent’s use of the Services. If we learn that personal information from users less than 18 years of age has been collected, we will deactivate the account and take reasonable measures to promptly delete such data from our records. If you become aware of any data we may have collected from children under age 18, please contact us at claire@kidkred.com</p>
                    <Typography variant='h5'>8. WHAT ARE YOUR PRIVACY RIGHTS?</Typography> */}
                </DialogContent>
            </Dialog>
        </Box>
    )
}