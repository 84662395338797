import React, {useState, useEffect} from 'react';

import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import * as Sentry from "@sentry/react";
import { isMobile } from 'react-device-detect';

import { UserServiceApi } from '../../../../services/userService';
import { UserData } from '../../../../../../src/types/user';
import { useAppSelector } from '../../../../store/hooks';
import { authSelector } from '../../../../store/authSlice';

import './DeactivateUserPopup.css';

export interface DeactivateUserPopupProps {
    userToDeactivate?: UserData;
    popupOpen: boolean;
    closePopup: () => void;
    handleRemoveUser: (user: UserData) => void
}

export default function DeactivateUserPopup(props: DeactivateUserPopupProps) {
    const {
        userToDeactivate,
        popupOpen,
        closePopup,
        handleRemoveUser
    } = props;

    const authDataStore = useAppSelector(authSelector);
    const { token } = authDataStore;
    const currentLoggedInUser = authDataStore.user;
    
    // Loading and error handling
    const [deactivateInProgress, setDeactivateInProgress] = useState(false);
    const [deactivateUserSuccessful, setDeactivateUserSuccessful] = useState(false);
    const [deactivateUserError, setDeactivateUserError] = useState(false);
    const [isCleverUser, setIsCleverUser] = useState(userToDeactivate?.cleverUserId !== undefined && userToDeactivate.cleverUserId !== null)

    useEffect(() => {
        // resetThePopup
        setDeactivateInProgress(false);
        setDeactivateUserSuccessful(false);
        setDeactivateUserError(false);
        setIsCleverUser(userToDeactivate?.cleverUserId !== undefined && userToDeactivate.cleverUserId !== null);

        if (userToDeactivate && userToDeactivate?.id === currentLoggedInUser?.id) {
            // cant allow the current logged in user to deactivate themselves
            setDeactivateUserError(true);
        }
        
        
    }, [userToDeactivate])

    const handleClickClose = async () => {
        setDeactivateInProgress(false);
        closePopup();
    }

    const handleClickConfirm = async () => {
        setDeactivateInProgress(true);
        const userService = UserServiceApi();
        try {
            if (!userToDeactivate){
                throw new Error('No user found to deactivate'); 
            }
            await userService.deactivateUser(userToDeactivate.id, token!);
            handleRemoveUser(userToDeactivate);
            setDeactivateUserSuccessful(true);
        } catch (err) {
            // show the error message
            setDeactivateUserError(true);
            // log the error
            Sentry.captureException(err);
        }

        setDeactivateInProgress(false);
    }

    if (deactivateUserError) {
        const errorMessage = userToDeactivate?.id !== currentLoggedInUser?.id ? 
            'Something went wrong when deactivating the user. Please refresh this page and try again. Hilight Support has been notified.'
            : 'You cannot deactivate yourself while you are logged in. Please reach out to Hilight Support at support@hilightedu.com if you need to deactivate your account.'
        return (
            <Box>
                <Dialog open={popupOpen} onClose={handleClickClose}>
                    <DialogTitle>Whoops!</DialogTitle>
                    <IconButton
                        aria-label="close"
                        onClick={handleClickClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: 'grey',
                        }}
                        >
                        <CloseIcon />
                    </IconButton>
                    <DialogContent className={isMobile ? 'deactivate-user-popup-content-mobile' : 'deactivate-user-popup-content'}>
                        <Typography className='deactivate-user-popup-error-text'>{errorMessage}</Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button 
                            variant='contained'
                            onClick={handleClickClose}
                            id='cancel-deactivate-user-button'
                        >
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </Box>
        )
    }

    if (deactivateUserSuccessful) {
        return (
            <Box>
                <Dialog open={popupOpen} onClose={handleClickClose}>
                    <DialogTitle>Deactivate User</DialogTitle>
                    <IconButton
                        aria-label="close"
                        onClick={handleClickClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: 'grey',
                        }}
                        >
                        <CloseIcon />
                    </IconButton>
                    <DialogContent className={isMobile ? 'deactivate-user-popup-content-mobile' : 'deactivate-user-popup-content'}>
                        <Typography className='deactivate-user-popup-success-text'>User was deactivated successfully!</Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button 
                            variant='contained'
                            onClick={handleClickClose}
                            id='cancel-deactivate-user-button'
                        >
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </Box>
        );
    }

    return (
        <Box>
            <Dialog open={popupOpen} onClose={handleClickClose}>
                <DialogTitle>Deactivate User</DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClickClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: 'grey',
                    }}
                    >
                    <CloseIcon />
                </IconButton>
                <DialogContent className={isMobile ? 'deactivate-user-popup-content-mobile' : 'deactivate-user-popup-content'}>
                    {deactivateInProgress  ? <Box className='deactivate-user-loading'><CircularProgress /> </Box>
                    : 
                    <Box>
                        {userToDeactivate &&
                        <Box>
                            <Typography className='deactivate-user-popup-confirm-text'>
                                Please confirm that you want to deactivate 
                                <span className='deactivate-user-bold-text'> {userToDeactivate.firstName} {userToDeactivate.lastName}'s </span>
                                Hilight account.
                            </Typography>
                            <Typography className='deactivate-user-popup-sub-text'>
                                They will no longer be able to login to Hilight, but their hilights will still be visible on the Bulletin Board. You can reactivate their account in the <span className='deactivate-user-bold-text'>Deactivated Users</span> table.
                            </Typography>
                        </Box>
                        
                        }
                        {isCleverUser &&
                            <Box className='clever-manage-users-warning deactivate-user-popup-sub-text'>
                                <ErrorOutlineIcon />
                                <Typography className='clever-manage-users-text'>
                                    This is a user imported from Clever. By deactivating this user, they will no longer be updated via the Clever sync.
                                </Typography>
                            </Box>
                        }
                    </Box>
                    }
                </DialogContent>
                <DialogActions>
                    <Button 
                        variant='contained'
                        onClick={handleClickConfirm}
                        disabled={deactivateInProgress}
                    >
                        Confirm
                    </Button>
                    <Button 
                        variant='contained'
                        onClick={handleClickClose}
                        disabled={deactivateInProgress}
                        id='cancel-deactivate-user-button'
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    )
}