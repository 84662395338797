import React, { useEffect, useState} from 'react';
import * as Sentry from "@sentry/react";
import { Box, Tab, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { isMobile } from 'react-device-detect';

import { RecognitionType } from '../../../util/type';
import FeedList from './FeedList';
import { authSelector } from '../../../store/authSlice';
import { PermissionServiceApi } from '../../../services/permissionService';
import { UserType } from '../../../util/type';

import './FeedTabs.css';


export interface RecognitionFeedTabsProps {
	schoolId: number;
	userId: number;
	startDate: Date;
	endDate?: Date;
}

export default function RecognitionFeedTabs(props: RecognitionFeedTabsProps) {
	const {
		schoolId,
		userId,
		startDate,
		endDate
	} = props;
	const navigate = useNavigate();
	const authDataStore = useSelector(authSelector);
	const { user, token } = authDataStore;
	
	if (!user) {
		navigate('/login');
	}
	const districtId = user!.districtId;

	// Permissions
	// District feed is hidden by default
	const [showDistrictFeedTab, setShowDistrictFeedTab] = useState(false);
	// School feed is visible by default
	const [showSchoolFeedTab, setShowSchoolFeedTab] = useState(true);
	const userIsFreeUser = user!.userType === UserType.Free;
	// Student feed is visible (user-level permission)
	const [showStudentFeedTab, setShowStudentFeedTab] = useState(false);

	// Selected Tabs 
	// Default is School tab unless this is a free user
	// but note the useEffect below that also updates the selected tab if school feed is disabled for non-admin users in the district
	const [selectedTab, setSelectedTab] = useState(userIsFreeUser ? 'personal' : 'school');
	const [myFeedSelection, setMyFeedSelection ] = useState<string>('Given')

  	const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    	setSelectedTab(newValue);
  	};

	const handleChangeMyFeedSelection = (event: React.MouseEvent<HTMLElement>, newSelection: string | null) => {
		if (newSelection) {
			setMyFeedSelection(newSelection);
		}
	}

	useEffect(() => {
		if (districtId) {
			getDistrictLevelPermissions(districtId);
		}
	}, [districtId]);

	const getDistrictLevelPermissions = async (districtId: number) => {
		const permissionsServiceApi = PermissionServiceApi();

		try {
			const districtPermissions = await permissionsServiceApi.getPermissionsForDistrict(districtId, token!);
			
			// Should the district feed be visible? (district-level permission)
			const districtFeedKey = 'DISTRICT_VIEW_FEED';
			const districtHasPermissionToViewDistrictFeed = districtPermissions.find(permission => permission.permissionKey === districtFeedKey);
			if (districtHasPermissionToViewDistrictFeed) {
				setShowDistrictFeedTab(true);
			} else {
				setShowDistrictFeedTab(false);
			}

			// Should the school feed only be accessible to admin? (district-level permission)
			const schoolFeedHiddenKey = 'SCHOOL_FEED_ADMIN_ONLY';
			const schoolFeedAdminOnly = districtPermissions.find(permission => permission.permissionKey === schoolFeedHiddenKey);
			if (schoolFeedAdminOnly && user!.userType !== UserType.Admin) {
				setShowSchoolFeedTab(false);
				setSelectedTab('personal');
			} else {
				setShowSchoolFeedTab(true);
				setSelectedTab('school');
			}

			// Should the Student feed be visible? (user-level permission)
			const userPermissions = await permissionsServiceApi.getPermissionsForUser(userId, token!);
			const studentFeedKey = 'STUDENT_FEED';
			const userHasPermissionToViewStudentFeed = userPermissions.find(permission => permission.permissionKey === studentFeedKey);
			if (userHasPermissionToViewStudentFeed) {
				setShowStudentFeedTab(true);
			} else {
				setShowStudentFeedTab(false);
			}
		} catch (err) {
			// log the error
			Sentry.captureException(err);
		}
	}

  	return (
		
		<Box className='feed-box'>
			<TabContext value={selectedTab}>
				<Box className='feed-tabs'>
					<TabList 
						onChange={handleChange} 
						aria-label="basic tabs example"
						variant={isMobile ? "scrollable" : 'standard'}
						scrollButtons={isMobile ? true : false}
						allowScrollButtonsMobile
					>
						{showSchoolFeedTab && <Tab label="School Feed" value='school' />}
						{!userIsFreeUser && showDistrictFeedTab && <Tab label="District Feed" value='district'/>}
						{showStudentFeedTab && <Tab label='Student Feed' value='student' />}
						<Tab label="My Feed" value='personal' />
					</TabList>
				</Box>
				{showSchoolFeedTab &&
					<TabPanel value='school'>
						<FeedList
							schoolId={schoolId}
							userId={userId}
							recognitionType={RecognitionType.School}
							startDate={startDate}
							endDate={endDate}
							disabledForFreeUser={userIsFreeUser}
						/>	
					</TabPanel>
				}
				{showDistrictFeedTab && !userIsFreeUser &&
					<TabPanel value='district'>
						<FeedList
							districtId={districtId}
							userId={userId}
							recognitionType={RecognitionType.District}
							startDate={startDate}
							endDate={endDate}
						/>
					</TabPanel>
				}
				{showStudentFeedTab &&
					<TabPanel value='student'>
						<FeedList
							districtId={districtId}
							schoolId={schoolId}
							userId={userId}
							recognitionType={RecognitionType.Student}
							startDate={startDate}
							endDate={endDate}
						/>
					</TabPanel>
				}
				<TabPanel value='personal'>
					<ToggleButtonGroup
						value={myFeedSelection}
						onChange={handleChangeMyFeedSelection}
						exclusive
						aria-label="text formatting"
						size={'medium'}
						className='my-feed-toggle-button-group'
					>
						<ToggleButton value="Given" className='my-feed-toggle-button'>
							<span>Given By You</span>
						</ToggleButton>
						<ToggleButton value="Received" className='my-feed-toggle-button'>
							<span>Given To You</span>
						</ToggleButton>
					</ToggleButtonGroup>
					<FeedList
						schoolId={schoolId}
						userId={userId}
						recognitionType={myFeedSelection === 'Given' ? RecognitionType.UserSent : RecognitionType.UserReceived}
						startDate={startDate}
						endDate={endDate}
					/>
				</TabPanel>
			</TabContext>
		</Box>
  	);
  }
