import React from 'react';
import  { Navigate, useLocation } from 'react-router-dom';



/**
 * Handles the Private routes
 * Use to access the routes with login token only. So if user is not logged-in then we will redirect back it to the login page.
 */
export function PrivateRoute({children,  isAuthenticated}: {children: any, isAuthenticated: boolean}): JSX.Element {
    // Take note of the current url - if the user isn't logged in, we will want to save the url to navigate to after login
    const currentLocation = useLocation();

    // If the user is authenticated, pass through the children
    // If the user is not authenticated and they are trying to access a private route, send them back to login
    return isAuthenticated ? children : <Navigate to="/login" state={{previousUrl: currentLocation.pathname}}/>;
}