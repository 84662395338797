import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import * as Sentry from "@sentry/react";

import { UserData, UserKredsCount } from '../../../../../src/types/user';
import { MainServiceApi } from '../../../services/mainService';
import { authSelector } from '../../../store/authSlice';
import { TokenTypeDisplayData } from '../../../../../src/types/recognition';
import { Box, CircularProgress} from '@mui/material';
import { RecognitionForm } from './RecognitionForm';

import './RecognitionForm.css';
import { RecognitionFormType } from './Recognition';

export interface DistrictRecognitionFormProps {
    userId: number;
    districtId: number;
    handleRecognitionFormSubmitted: (result: {success: boolean, bonusPointAwarded?: boolean}) => void;
    userKreds?: UserKredsCount;
    currentSchoolId: number; // This is the school that the user is currently logged into. Different than "selectedSchool" below
    rewardsPointsDisabled: boolean;
    hideAnonymous: boolean;
}

export function DistrictRecognitionForm(props: DistrictRecognitionFormProps) {
    const {
        userId,
        districtId,
        handleRecognitionFormSubmitted,
        userKreds,
        rewardsPointsDisabled,
        hideAnonymous
    } = props;

    const authDataStore = useSelector(authSelector);
	const {token } = authDataStore;

    const [isLoadingFormFields, setIsLoadingFormFields] = useState(true);
    const [staffList, setStaffList] = useState<UserData[]>([]);
    const [districtTokens, setDistrictTokens] = useState<TokenTypeDisplayData[]>([]);


    const loadDistrictData = async (): Promise<void> => {
        setIsLoadingFormFields(true);

        try {
            const mainService = MainServiceApi();
            const districtTokens = await mainService.getDistrictTokenTypes(districtId, token!);
            setDistrictTokens(districtTokens);

            const staff = await mainService.getDistrictUsers({districtId, token: token!});
            setStaffList(staff);
        } catch (err) {
            // log the error
			Sentry.captureException(err);
        } 

        setIsLoadingFormFields(false);
    }

    useEffect(() => {
        loadDistrictData();
    }, [districtId]);

    return (
        <div className='district-recognition-form'>
            {isLoadingFormFields &&
                <Box className='recognition-form-loading'><CircularProgress/></Box>
            }
            <RecognitionForm 
                userId={userId}
                userKreds={userKreds}
                handleRecognitionFormSubmitted={handleRecognitionFormSubmitted}
                tokenTypes={districtTokens}
                schoolStaff={staffList}
                rewardsPointsDisabled={rewardsPointsDisabled}
                recognitionFormType={RecognitionFormType.District}
                hideAnonymous={hideAnonymous}
            />
        </div>
    )
}