/* 
* Generates a new file name based on a the given prefix and the current date time. 
* Returns a file name of the patter: [prefix]_2022-03-19T23:15:30.[fileType] where the date in that case is just an example.
*/
export function generateFileNameWithDate(prefix: string | number, oldFileName: string): string {
    const newFileName = `${prefix}_${new Date().toJSON().slice(0, 20)}`;

    // Now add back the file type extension
    const fileType = oldFileName.split('.')?.pop();
    if (!fileType) {
        throw new Error('Unable to parse fileName');
    }

    return newFileName + fileType;
}


export function getUserAvatar(s3ImageUrl?: string) {
    let imageSrc = s3ImageUrl || 'none'; // had to give an actual string of "none" if no url in order to get it to use the Avatar default for some reason

    return {
        src: imageSrc, 
        sx: {
            bgcolor: '#FCBE57', // hilight yellow
        },
    }
}