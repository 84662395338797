import React from 'react';
import { FadingBalls } from "react-cssfx-loading";
import { Box, Grid } from '@mui/material';

import Logo from '../images/hilight_hi_logo.png';

export default function Loading() {

    return (
			<Grid
				container
				spacing={0}
				style={{ minHeight: '100vh' }}
				className='loading-grid'
				direction={'column'}
				alignItems={'center'}
				justifyContent={'center'}
			>
				<Grid item  >
					<div className='loading-grid-item'>
						<Box
							component="img"
							className='loading-logo'
							alt="hilight logo."
							src={Logo}
						/>
						<div className="loading-text">Loading your Dashboard</div>
						<FadingBalls color='#240145' className='loading-animation'/>
					</div>
				</Grid>
			</Grid>	
        
    )
}