import React from 'react';

import { Avatar, Box, CircularProgress, Grid, Typography } from '@mui/material';
import StarsIcon from '@mui/icons-material/Stars';
import { isMobile } from 'react-device-detect';

import { SpotlightData } from '../../../../../src/types/spotlight';
import FeedList from '../Feed/FeedList';
import { UserKredsPieChart } from '../Data/UserKredsPieChart';
import { getUserAvatar } from '../../../util/images';
import { RecognitionType } from '../../../util/type';

import './SpotlightContent.css';

export interface SpotlightContentProps {
    schoolId: number;
    districtId: number;
    userId: number;
    startDate: Date;
    timeRangeSelection: string;
    districtOrSchool: string;
    printView?: boolean;
    spotlightData?: SpotlightData;
    isLoadingSpotlightData: boolean;
    tokenTypesChartLegend: {id: string,
        color: string,
        value: string
    }[];
}
export const SpotlightContent = React.forwardRef((props: SpotlightContentProps, ref) => {
    const { schoolId, districtId, userId, startDate, timeRangeSelection,  printView, spotlightData, isLoadingSpotlightData, tokenTypesChartLegend, districtOrSchool } = props;
    const maxNumberOfHilightsDisplayedInPrintView = 50;
    return (
        <Box>
            { (isLoadingSpotlightData || !spotlightData) ? 
            <CircularProgress></CircularProgress>
            :
            <Box ref={ref} className='spotlight-grid'>
                {printView && 
                    <Box className='print-view-header'>
                        <Typography className='print-view-header-title'>Spotlight</Typography>
                        <Typography>{`The ${timeRangeSelection}ly spotlight for ${spotlightData.nameOfSchoolOrDistrict} (${startDate.toLocaleDateString('en-us', {weekday: 'long', year: 'numeric', month: 'short', day: 'numeric'})} - ${new Date().toLocaleDateString('en-us', {weekday: 'long', year: 'numeric', month: 'short', day: 'numeric'})}).`}</Typography>
                    </Box>
                }
                <Grid container spacing={1}>
                    <Grid item className={`spotlight-grid-item${isMobile ? '-mobile' : ''}`}>
                        <Box className={`spotlight-grid-item-border spotlight-grid-item-box${isMobile || printView ? '-mobile' : ''}`}>
                            <Typography className='spotlight-item-header'><span className='spotlight-count'>{spotlightData.hilightCount}</span> {`hilights were sent within your ${districtOrSchool} this ${timeRangeSelection}!`}</Typography>
                        </Box>
                        {spotlightData.mostCommonTokenType &&
                            <Box className='spotlight-grid-item-border' id={isMobile || printView  ? 'spotlight-token-type-mobile' : 'spotlight-token-type'} hidden={true}>
                                <StarsIcon className='spotlight-token-icon' fontSize='large' />
                                <Typography className='spotlight-token-type-header'>{spotlightData.mostCommonTokenType}</Typography>
                                <Typography className={isMobile ? 'spotlight-token-type-text-mobile' : 'spotlight-token-type-text'}>{` was the most frequently used core value this ${timeRangeSelection}`}</Typography>
                            </Box>
                        }
                        <Box className={`spotlight-grid-item-border spotlight-grid-item-box${isMobile  || printView  ? '-mobile' : ''}`}>
                            <Typography className='spotlight-item-header'>{`Top Hilight Senders for the last ${timeRangeSelection}:`}</Typography>
                                    {spotlightData.topSenders && spotlightData.topSenders.length > 0 ?
                                    spotlightData.topSenders.map((sender) => 
                                        <Box className='spotlight-top-sender-box'> 
                                            <Avatar
                                                    className={`top-sender-avatar${isMobile  || printView  ? '-mobile' : ''}`}
                                                    {...getUserAvatar(sender.profilePhotoUrl)}
                                            >{sender.firstName[0]}{sender.lastName[0]}</Avatar>
                                            <Box className={ `spotlight-top-sender-text${isMobile  || printView  ? '-mobile' : ''}`}>
                                                <div className='spotlight-top-sender-name-wrap'>
                                                    <span className='top-sender-name'>
                                                        {sender.firstName} {sender.lastName}
                                                    </span>
                                                </div>
                                                <div className='spotlight-top-sender-name-wrap sent-text'>
                                                    sent <span className='spotlight-count'>
                                                        {sender.sendCount}
                                                    </span>  {sender.sendCount === 1 ? 'hilight' : 'hilights'}
                                                </div>
                                            </Box>


                                        </Box>

                                    ) :
                                    <Typography className='spotlight-no-data-text'>No data yet! Start sending some hilights so you can appear here!</Typography>
                                }
                        </Box>
                    </Grid>
                    <Grid item className={`spotlight-grid-item${isMobile || printView  ? '-mobile' : ''}`}>
                        <Box className='spotlight-percentages'>
                            <Box className='spotlight-grid-item-border spotlight-percentage-item' >
                                <Typography className='spotlight-item-smaller-header'>{`% of Staff Who Sent Recognition this ${timeRangeSelection}`}</Typography>
                                <Typography className='spotlight-percentage-text'>{`${Math.floor(spotlightData.staffSentRecognition * 100)}%`}</Typography>
                            </Box>
                            <Box className='spotlight-grid-item-border spotlight-percentage-item' >
                                <Typography className='spotlight-item-smaller-header'>{`% of Staff Recognized this ${timeRangeSelection}`}</Typography>
                                <Typography className='spotlight-percentage-text'>{`${Math.floor(spotlightData.staffReceivedRecognition * 100)}%`}</Typography>
                            </Box>
                        </Box>
                        <Box className={`spotlight-grid-item-border spotlight-grid-item-box${isMobile &&! printView ? '-mobile' : ''}` }>
                            <Typography className='spotlight-item-header'>Hilights sent by Core Value</Typography>
                            {spotlightData.schoolHilightsByTokenType.length > 0 ?
                                <UserKredsPieChart 
                                    data={spotlightData.schoolHilightsByTokenType}
                                    chartLegend={tokenTypesChartLegend}
                                    printView={printView}
                                />
                            : <Typography className='spotlight-no-data-text'>No data yet! Start sending some hilights!</Typography>}

                        </Box>
                    </Grid>
                    <Grid item xs={11} className={`spotlight-grid-item${isMobile ? '-mobile' : ''}`} >
                        <Box className='spotlight-grid-item-border' id='spotlight-recognition-examples' >
                            <Typography className='spotlight-item-header'>{`Hilights sent this ${timeRangeSelection}`}</Typography>
                            <Box className='spotlight-feed-list'>
                                <FeedList 
                                    schoolId={schoolId}
                                    districtId={districtId}
                                    userId={userId}
                                    recognitionType={districtOrSchool === 'district' ? RecognitionType.District : RecognitionType.School}
                                    startDate={startDate}
                                    pageSize={printView ? maxNumberOfHilightsDisplayedInPrintView : undefined} // use the default if not print View
                                />
                            </Box>

                        </Box>
                    </Grid>
                </Grid>
                {printView && (spotlightData?.hilightCount > maxNumberOfHilightsDisplayedInPrintView) &&<Typography className='spotlight-print-view-warnig-text'>{`*The maximum number of hilights displayed in this view is ${maxNumberOfHilightsDisplayedInPrintView}. To see the addititonal hilights sent this ${timeRangeSelection}, please take a look at the Hilight Bulliten tab!`}</Typography>}
            </Box>
        }  
        </Box>
    )
});



// NEXT STEPS
// fix auto height of recognition examples