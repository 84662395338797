import React from 'react';

import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import './Popup.css';


export interface InstructionsPopupProps {
    open: boolean;
    handleClosePopup: () => void;
    popupHeader: string;
    popupContent: JSX.Element;
}

export function InstructionsPopup(props: InstructionsPopupProps) {
    const { 
        open,
        handleClosePopup,
        popupHeader,
        popupContent
    } = props;


    return (
        <Dialog open={open} onClose={handleClosePopup}>
            <DialogTitle>{popupHeader}</DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClosePopup}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: 'grey',
                }}
                >
                <CloseIcon />
            </IconButton>
            <DialogContent>
                <DialogContentText>
                    {popupContent}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClosePopup}>Close</Button>
            </DialogActions>
        </Dialog>
    )
}