import * as React from 'react';

import { Box, FormControl, Grid, MenuItem, TextField, Typography } from '@mui/material';

import { UserKredsPieChart } from './UserKredsPieChart';
import { ColleaguesRecognizedChart } from './ColleaguesRecognizedChart';

import { GetTeacherDataDashboard } from '../../../../../src/types/dataDashboard';
import { OverallStatisticsChart } from './OverallStatisticsChart';
import { School } from '../../../../../src/types/user';
import { SchoolYear } from '../../../../../src/util/dates';

import './StandardUserData.css';

export interface StandardUserDataProps {
    data: GetTeacherDataDashboard,
    tokenTypesChartLegend: {
        id: string,
        color: string,
        value: string
    }[],
    currentSchool?: School,
    disabledForFreeUser?: boolean,
    schoolYearOptions?: SchoolYear[];
    handleChangeSchoolYearSelection: (newValue?: string) => void;
    selectedSchoolYear?: string;
}
export function StandardUserData(props: StandardUserDataProps) {
    const {
        data,
        tokenTypesChartLegend, 
        currentSchool,
        disabledForFreeUser,
        schoolYearOptions,
        handleChangeSchoolYearSelection,
        selectedSchoolYear
    } = props;

    return (
        <Box>
            <Box className='dashboard-page-header'>                
                {/* Only show the school year drop down for schools that have more than one year*/}
                {schoolYearOptions && schoolYearOptions?.length > 1 &&
                        <FormControl size='small' className='personal-school-year-dropdown'>
                            <TextField
                                value={selectedSchoolYear}
                                onChange={(e) => handleChangeSchoolYearSelection(e.target.value)}
                                label='Select a School Year'
                                select
                            >
                                {schoolYearOptions.map((schoolYear) => <MenuItem key={schoolYear.key} value={schoolYear.key}>{schoolYear.displayText}</MenuItem>)}                           
                            </TextField>
                        </FormControl> 
                }
            </Box>
            <Box className='data-dashboard-grid'>
                {/* <Typography className='data-dashboard-message'>
                    <Typography variant='h5'>Your Data Dashboard will be populated soon!</Typography>
                    <Typography variant='h6'>In the mean time, keep giving your colleagues recognition so that we have lots to show here when it's ready!</Typography>
                </Typography> */}
                
                <Box>
                    <Grid container spacing={3}>
                        {/* Total tokens earned pie chart */}
                        <Grid item xs={8} md={5} className='dashboard-grid-item dashboard-grid-item-small'>
                            <div className='dashboard-grid-item-border'>
                                <div className='dashboard-grid-item-content'>
                                    <h3 className='dashboard-grid-item-header'>
                                        Hilights Earned by Core Value
                                    </h3>
                                    <UserKredsPieChart data={data.tokensEarnedByType} chartLegend={tokenTypesChartLegend}/>
                                </div>
                            </div>
                        </Grid>
                        {/* Colleagues recognized graph */}
                        {!disabledForFreeUser && 
                        <Grid item xs={8} md={7} className='dashboard-grid-item'>
                            <div className='dashboard-grid-item-border'>
                                <div className='dashboard-grid-item-content'>
                                    <h3 className='dashboard-grid-item-header'>
                                        Colleagues You Have Recognized
                                        {currentSchool && <Typography className='dashboard-sub-header'>{`At ${currentSchool.name}`}</Typography>}
                                    </h3>
                                    <ColleaguesRecognizedChart 
                                        data={data.percentageUsersRecognizedByMonth} 
                                    />
                                </div>
                            </div>
                        </Grid>
                        }

                        {/* Overall statistics recognized graph */}
                        {!disabledForFreeUser &&
                        <Grid item xs={8} md={6} className='dashboard-grid-item dashboard-grid-item-large'>
                            <div className='dashboard-grid-item-border'>
                                <div className='dashboard-grid-item-content'>
                                    <h3 className='dashboard-grid-item-header'>
                                        Your Recognition
                                    </h3>
                                    <OverallStatisticsChart
                                    countRecognitionEarnedData={data.countRecognitionEarnedByMonth}
                                    countRecognitionGivenData={data.countRecognitionGivenByMonth}
                                />
                                </div>
                            </div>
                        </Grid>
                        }

                        {/* Your Connections graph */}
                        {!disabledForFreeUser &&
                        <Grid item xs={6} md={6} className='dashboard-grid-item' hidden>
                            <div className='dashboard-grid-item-border'>
                                <div className='dashboard-grid-item-content'>
                                    <h3 className='dashboard-grid-item-header'>Your Connections</h3>
                                    <Typography className='coming-soon'>Coming soon</Typography>
                                </div>
                            </div>
                        </Grid>
                        }

                        {/* More info - for free user */}
                        {disabledForFreeUser &&
                        <Grid item xs={8} md={7} className='dashboard-grid-item'>
                            <div className='dashboard-grid-item-border'>
                                <div className='dashboard-grid-item-content dashboard-more-info-free-user'>
                                    <h3 className='dashboard-grid-item-header dashboard-more-info-free-user-text'>Want to be able to see more data for your school? </h3>
                                        <Typography>Check out <a href='https://www.hilightedu.com'>our website</a> for more information on how your school can purchase Hilight premium for you and your colleagues.</Typography>
                                </div>
                            </div>
                        </Grid>
                        }
                    </Grid>
                </Box> 
            </Box>
        </Box>
    )
}