import React, { useEffect, useState } from 'react';
import * as Sentry from "@sentry/react";
import { Button, CircularProgress, List, ListItem} from '@mui/material';
import { Box } from '@mui/system';
import StarIcon from '@mui/icons-material/Star';
import StarsIcon from '@mui/icons-material/Stars';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';

import { timeAgo } from '../../../util/time';
import { MainServiceApi } from '../../../services/mainService';
import { authSelector } from '../../../store/authSlice';

import './Notifications.css';

export interface NotificationsProps {
    userId: number,
    schoolId?: number,
    lastLoginDate?: Date,
    currentPoints?: number,
    rewardsDisabled?: boolean
}
export function Notifications(props: NotificationsProps) {
    const {
        userId,
        schoolId,
        lastLoginDate,
        currentPoints,
        rewardsDisabled
    } = props; 
    
    // Navigation
    const navigate = useNavigate();

    const authDataStore = useSelector(authSelector);
	const { token } = authDataStore;

    const [notificationsList, setNotificationsList] = useState<{ 
        id: string,
        tokenTypeText?: string,
        notificationDateTimeText: string | [string, (number | undefined)?],
        recognitionGiver?: string
    }[]>([]);

    async function getNotificationsForUser (userId: number, schoolId: number): Promise<void> {
        try {
            const mainServiceApi = MainServiceApi();
            const notifications = await mainServiceApi.getRecognitionNotificationsForUser(userId, schoolId, token!, lastLoginDate);

            const notificationData = notifications.map((notification) => {
                return {
                    id: notification.id,
                    tokenTypeText: notification.tokenTypeText,
                    notificationDateTimeText: timeAgo.format(new Date(notification.notificationDateTime)),
                    recognitionGiver: notification.userSenderName ? `${notification.userSenderName?.firstName} ${notification.userSenderName?.lastName}` : undefined,
                }
            });

            setNotificationsList(notificationData);
        } catch (err) {
            // log the error
			Sentry.captureException(err);
        }
    }

    useEffect(() => {
        if (schoolId) {
            getNotificationsForUser(userId, schoolId);
        }
        
    }, [userId, schoolId]);
    
    return (
        <div className='notifications-border'>
            <div className={`${isMobile ? 'recognition-grid-item-mobile' : 'recognition-grid-item' } notifications`}>
                <h3 className='recognition-grid-item-header notifications-header'>Notifications</h3>
                <div>
                    { notificationsList === undefined && <CircularProgress /> }
                    
                    {/* Show no notifications message if there are no recognition notifications and no current points */}
                    { notificationsList && notificationsList.length === 0 && (currentPoints === 0 || rewardsDisabled) &&
                        <p className='no-notifications-text'>No new notifications</p>
                    }
                    
                    {/* Otherwise show notifications list */}
                    {
                        <List dense={true}>
                            {currentPoints !== undefined && currentPoints > 0 && !rewardsDisabled &&
                                <ListItem className='notifications-list-item' key={'-1'}>
                                    <Box className='notification-star-icon'>
                                        <EmojiEventsIcon />
                                    </Box>
                                    <Box>
                                        <p className='notification-list-item-text'>
                                            You have <span className='notification-token-text'>{currentPoints}</span> points to redeem in the Rewards marketplace!
                                        </p>
                                    </Box>
                                </ListItem>
                            }
                            {notificationsList && notificationsList.length > 0 &&
                                notificationsList.map((notificationItem) => 
                                    ( notificationItem.tokenTypeText ? 
                                        <ListItem className='notifications-list-item' key={notificationItem.id}>
                                            <Box className='notification-star-icon'>
                                                <StarsIcon />
                                            </Box>
                                            <Box>
                                                <p className='notification-list-item-text'>
                                                    You received a 
                                                    <span className='notification-token-text'> {notificationItem.tokenTypeText} </span>
                                                    hilight
                                                    {notificationItem.recognitionGiver && <span> from <span className='notification-giver-text'>{notificationItem.recognitionGiver}</span></span>}
                                                    !
                                                </p>
                                                <p className='notifications-time-text'>
                                                    <span >{notificationItem.notificationDateTimeText}</span>
                                                </p>
                                            </Box>
                                        </ListItem>
                                        : 
                                        <ListItem key={notificationItem.id}>
                                            <Box className='notification-star-icon'>
                                                <StarIcon />
                                            </Box>
                                            <Box>
                                                <p className='notification-list-item-text'>
                                                    You received new recognition
                                                    {notificationItem.recognitionGiver && <span> from <span className='notification-giver-text'>{notificationItem.recognitionGiver}</span></span>}
                                                    !
                                                </p>
                                                <p className='notifications-time-text'>
                                                    <span> {notificationItem.notificationDateTimeText}</span>
                                                </p>
                                            </Box>
                                        </ListItem>
                                    )
                                    
                                )
                            }
                        </List>
                    }
                </div>
                {
                    // Show go to recognition feed button if there are recognition notifications
                    notificationsList?.length > 0 &&
                    <Button className='notifications-view-feed-button' size='small' onClick={(() => {
                        navigate('/teacherDashboard/feed')
                    })}>Go to Recognition Feed</Button>
                }
                {
                    // Show got to rewards tab button if there's a points to redeem notification and no recognition notifications
                    notificationsList?.length === 0 && currentPoints !== undefined && currentPoints > 0 && !rewardsDisabled && 
                    <Button className='notifications-view-feed-button' size='small' onClick={(() => {
                        navigate('/teacherDashboard/rewards')
                    })}>Go to Rewards</Button>
                }
                
            </div>
        </div>
    );
}