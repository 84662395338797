import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Tooltip, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import PlusOneIcon from '@mui/icons-material/PlusOne';
import HelpIcon from '@mui/icons-material/Help';

import './SuccessPopup.css';

export interface SuccessPopupProps {
    popupOpen: boolean;
    closePopup: () => void;
    bonusPointAwarded?: boolean;
    rewardsDisabled?: boolean;
}

export default function SuccessPopup(props: SuccessPopupProps) {
    const { popupOpen, closePopup, bonusPointAwarded, rewardsDisabled} = props;

    // Navigation
    const navigate = useNavigate();

    const handleClickNavigate = () => {
        closePopup();

        navigate('/teacherDashboard/feed');

    };

    return (
        <Box>
            <Dialog open={popupOpen} onClose={closePopup}>
                <DialogTitle>Recognition Sent Successfully!</DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={closePopup}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: 'grey',
                    }}
                    >
                    <CloseIcon />
                </IconButton>
                <DialogContent>
                    <Typography>Giving thanks is powerful! Head to your school bulletin to view your sent recognition or close out of this window and send more!</Typography>
                    
                    {bonusPointAwarded && !rewardsDisabled && 
                        <Box className='bonus-point'>
                            <PlusOneIcon fontSize='large' className='bonus-point-icon'/>
                            <Typography>You just earned a Hilight bonus point! Great job!</Typography>
                            <Tooltip title={
                                <Box>
                                    <Typography>For every 5 hilights you send, you earn 1 point!</Typography>
                                    <br/>
                                    <Typography>Check out the Rewards tab to see how many points you have and redeem your points for rewards!</Typography>
                                </Box>
                            }>
                                <HelpIcon className='bonus-point-info-icon'/>
                            </Tooltip>
                            
                        </Box>
                    }
                </DialogContent>
                <DialogActions>
                    <Button
                        variant='contained'
                        onClick={handleClickNavigate}
                    >
                        Go to School Bulletin
                    </Button>
                    <Button
                        variant='contained'
                        onClick={closePopup}
                        id='close-recognition-popup-button'
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    )
}