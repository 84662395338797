import React from 'react';
import { DataGrid,  GridActionsCellItem,  GridColDef } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Box, Typography } from '@mui/material';

import { School, UserData } from '../../../../../../src/types/user';
import { UserType } from '../../../../util/type';

import './ManageUsersTable.css';

export interface ManageUsersTableProps {
    userRows: UserData[];
    rowsLoading: boolean;
    activeUsers: boolean; // whether this should be for active users or deactivated users
    handleClickAction: (data: {
        user: UserData,
        deactivate?: boolean,
        reactivate?: boolean
    }) => void;
    isCleverDistrict: boolean;
}

export function ManageUsersTable(props: ManageUsersTableProps) {
const { 
    activeUsers, 
    userRows, 
    rowsLoading, 
    handleClickAction,
    isCleverDistrict
} = props;


const columns: GridColDef[] = [
    {
        field: 'actions',
        type: 'actions',
        headerName: '',
        width: 90,
        cellClassName: 'actions',
        headerClassName: 'edit-user-column-name',
        getActions: ({ id, row }) => {
            if (activeUsers) {
                return [
                    <GridActionsCellItem 
                        icon={<EditIcon />}
                        label='Edit'
                        className='edit-user-icon'
                        onClick={() => handleClickAction({user: row})}
                    />,
                    <GridActionsCellItem 
                        icon={<DeleteIcon />}
                        label='Deactivate'
                        className='delete-user-icon'
                        onClick={() => handleClickAction({user: row, deactivate: true})}
                    />
                ]
            } else {
                return [
                    <GridActionsCellItem 
                        icon={<RefreshIcon />}
                        label='Edit'
                        className='edit-user-icon'
                        onClick={() => handleClickAction({user: row, reactivate: true})}
                    />,
                ]
            }
            
        }
    },
    { field: 'firstName', headerName: 'First Name', width: 130, headerClassName: 'edit-user-column-name', },
    { field: 'lastName', headerName: 'Last Name', width: 130, headerClassName: 'edit-user-column-name', },
    { field: 'email', headerName: 'Email', width: 180, headerClassName: 'edit-user-column-name', },
    { 
        field: 'schools',
        headerName: 'School', 
        width: 200,
        headerClassName: 'edit-user-column-name',
        valueGetter: (schools: School[]) => {
            if (schools) {
                if (schools.length > 1) {
                    let schoolsList = '';
                    for (const school of schools) {
                        if (schools.indexOf(school) === 0) {
                            schoolsList += `${school.name}`
                        } else {
                            schoolsList +=  `, ${school.name}`;
                        }
                    }
                    return schoolsList;
                } else {
                    return schools[0].name;
                }
            } else {
                return '';
            }
        }
    },
    { field: 'roleAtSchool', headerName: 'Role', width: 150 },
    { 
        field: 'userType',
        headerName: 'User Type',
        width: 80,
        headerClassName: 'edit-user-column-name',
        valueGetter: (userType: UserType) => {
            if (userType === UserType.Admin) {
                return 'Admin';
            } else {
                return 'Standard';
            }
        }
    }
];

if (isCleverDistrict) {
    columns.push({
        field: 'cleverUserId',
        headerName: 'Clever User?',
        width: 100,
        headerClassName: 'edit-user-column-name',
        valueGetter: (cleverUserId: string) => {
            if (cleverUserId) {
                return 'Clever';
            } else {
                return 'Non-Clever';
            }
        }
    })
}

if (!activeUsers) {
    columns.push({
        field: 'deleted',
        headerName: 'Deactivated Date',
        width: 80,
        headerClassName: 'edit-user-column-name',
    });
}

const noRowsOverlay = () => {
    return (
        <Box>
            <Typography className='users-no-rows-text'>None</Typography>
        </Box>
    )
}

return (
    <div>
        <Box className='users-data-grid-box'>
            <DataGrid
                rows={userRows}
                columns={columns}
                initialState={{
                    pagination: {
                        paginationModel: {
                        pageSize: 10,
                        },
                    },
                }}
                slots={{noRowsOverlay}}
                pageSizeOptions={[10, 25, 50]}
                loading={rowsLoading}
            />
        </Box>
    </div>
)

}