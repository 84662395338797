
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';

// Format timeAgo to use english
TimeAgo.addDefaultLocale(en);
export const timeAgo = new TimeAgo('en-US');

export const getFirstDayOfWeek = (today: Date): Date => {
    const firstDayOfWeekIndex = 1; // 1 for Monday, could use 0 for Sunday

    const dayOfWeek = today.getDay();
    const firstDayOfWeek = new Date(today);
    const diff = dayOfWeek >= firstDayOfWeekIndex ? dayOfWeek - firstDayOfWeekIndex : 6 - dayOfWeek;

    firstDayOfWeek.setDate(today.getDate() - diff);
    firstDayOfWeek.setHours(0,0,0,0);

    return firstDayOfWeek;
}