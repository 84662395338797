import React from 'react';

import { privacyHtml } from './privacy';

import './PrivacyPolicy.css';

export default function PrivacyPolicy() {
    return (
        <div className='privacy-policy-page'>
            <div dangerouslySetInnerHTML={{__html: privacyHtml}} />
        </div>
    );
}