import React, {useState} from 'react';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Tab, Typography } from '@mui/material';

import { UserData } from '../../../../../src/types/user';
import { UserType } from '../../../util/type';
import { ManageUsersTab } from './ManageUsers/ManageUsersTab';

import './AdminSettings.css';
import { ManageRewardsTab } from './ManageRewards/ManageRewardsTab';

export interface AdminSettings {
    user: UserData
}

export function AdminSettings(props: AdminSettings) {
    const { user } = props;

    const [tabValue, setTabValue] = useState('1');
    const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
    	setTabValue(newValue);
  	};

    if (user.userType !== UserType.Admin)  {
        return (
            <div className='admin-settings-page'>
                <Box className='page-heading'>Admin Settings</Box>
                <Box className='admin-settings-no-permission-text-box'>
                    <Typography className='admin-settings-no-permission-text'>You don't currently have Admin access. If you think this is incorrect, please reach out to your School Admin or IT Department. You can also reach out to support@hilightedu.com for further support. </Typography>
                </Box>
            </div>
        )
    }

    return (
        <div className='admin-settings-page'>
            <Box className='page-heading'>Admin Settings</Box>
            <Box className='admin-settings-box'>
                <TabContext value={tabValue}>
                    <Box className='admin-settings-tabs'>
                        <TabList onChange={handleChangeTab} aria-label='admin settings tabs'>
                            <Tab label='Manage Users' value='1' />
                            <Tab label='Manage Rewards' value='2' />
                        </TabList>
                    </Box>
                    <TabPanel value='1'>
                        <ManageUsersTab districtId={user.districtId}/>
                    </TabPanel>
                    <TabPanel value='2'>
                       <ManageRewardsTab districtId={user.districtId} />
                    </TabPanel>
                </TabContext>
            </Box>
        </div>
    )
}