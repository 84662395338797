import React, {useState, useEffect} from 'react';

import { Avatar, Box, Button, CircularProgress, Grid, TextField, Tooltip, Typography } from '@mui/material';
import StarsIcon from '@mui/icons-material/Stars';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import * as Sentry from "@sentry/react";
import { useSelector } from 'react-redux';

import { UserKredsCount } from '../../../../../src/types/user';
import { getUserAvatar } from '../../../util/images';
import ChangePasswordPopup from './ChangePasswordPopup';
import ChangeProfilePhotoPopup from './ChangeProfilePhotoPopup';
import { UserType } from '../../../util/type';
import { PermissionServiceApi } from '../../../services/permissionService';
import { authSelector } from '../../../store/authSlice';

import './UserAccount.css';

export interface UserAccountProps {
    userKreds?: UserKredsCount,
    handleSaveUserData: (userData: {
        newFirstName?: string,
        newLastName?: string,
        newEmail?: string,
        newPrimarySchoolId?: number,
        newDisplayName?: string,
        newRoleAtSchool?: string,
        newProfilePhotoImagePath?: string | null
    }) => Promise<void>,
    rewardsDisabled?: boolean,
}
export function UserAccount(props: UserAccountProps) {
    const {
        userKreds,
        handleSaveUserData,
        rewardsDisabled
    } = props;

    const authDataStore = useSelector(authSelector);
	const { user, token } = authDataStore;
    

    // Name field
    const [firstNameFieldEditable, setFirstNameFieldEditable] = useState<boolean>(false);
    const [lastNameFieldEditable, setLastNameFieldEditable] = useState<boolean>(false);
    const [firstNameField, setFirstNameField] = useState<string | undefined>(user?.firstName);
    const [lastNameField, setLastNameField] = useState<string | undefined>(user?.lastName);
    const [isCleverUser, setIsCleverUser] = useState(user?.cleverUserId !== undefined && user?.cleverUserId !== null);

    // TO DO - what should happen if any of the saves fail? Right now, it just doesn't show anything different

    const saveName = () => {
        if (firstNameField && lastNameField && user) {
            handleSaveUserData({
                newFirstName: firstNameField,
                newLastName: lastNameField
            });
        }
        setFirstNameFieldEditable(false);
        setLastNameFieldEditable(false);
    }
    const cancelSaveName = () => {
        // set the name back to the user's name - in case the user typed something into the text box 
        setFirstNameField(user?.firstName);
        setLastNameField(user?.lastName);
        setFirstNameFieldEditable(false);
        setLastNameFieldEditable(false);
    }

    // Role field
    const [roleFieldEditable, setRoleFieldEditable] = useState<boolean>(false);
    const [roleField, setRoleField] = useState<string | undefined>(user?.roleAtSchool);
    const editRole = () => {
        setRoleFieldEditable(true);
    }
    const saveRole = () => {
        if (roleField && roleField !== user?.roleAtSchool && user) {
            handleSaveUserData({
                newRoleAtSchool: roleField || '', // pass an empty string to the PUT user endpoint to reset the role field
            });
        }
        setRoleFieldEditable(false);
    }
    const cancelSaveRole = () => {
        // set the role back to the user's role - in case the user typed something into the text box 
        setRoleField(user?.roleAtSchool);
        setRoleFieldEditable(false);
    }

    // Display Name field
    const [displayNameFieldEditable, setDisplayNameFieldEditable] = useState<boolean>(false);
    const [displayNameField, setDisplayNameField] = useState<string | undefined>(user?.displayName);
    const editDisplayName = () => {
        setDisplayNameFieldEditable(true);
    }
    const saveDisplayName = () => {
        if (displayNameField && displayNameField !== user?.displayName && user) {
            handleSaveUserData({
                newDisplayName: displayNameField || '', // pass an empty string to the PUT user endpoint to reset the displayName field
            });
        }
        setDisplayNameFieldEditable(false);
    }
    const cancelSaveDisplayName = () => {
        // set the displayName back to the user's displayName - in case the user typed something into the text box 
        setDisplayNameField(user?.displayName);
        setDisplayNameFieldEditable(false);
    }

    // Change password popup
    const [showChangePasswordPopup, setShowChangePasswordPopup] = useState(false);

    // Profile photo
    const [showChangeProfilePhotoPopup, setShowChangeProfilePhotoPopup] = useState(false);
    const removeProfilePhoto = async () => {
        setRemoveProfilePhotoInProgress(true);
        await handleSaveUserData({ newProfilePhotoImagePath: '' });
        setRemoveProfilePhotoInProgress(false);
    }
    const [removeProfilePhotoInProgress, setRemoveProfilePhotoInProgress] = useState(false);

    // Permissions
    const [showDisplayNameForStudentsField, setShowDisplayNameForStudentsField] = useState(false);
    const getDistrictLevelPermissions = async (districtId: number) => {
		const permissionsServiceApi = PermissionServiceApi();

		try {
			const districtPermissions = await permissionsServiceApi.getPermissionsForDistrict(districtId, token!);
			
			// Is the student feature enabled?
			const hilightStudentsKey = 'STUDENT_HILIGHTS_ENABLED';
			const districtHasPermissionToHilightStudents = districtPermissions.find(permission => permission.permissionKey === hilightStudentsKey);
			if (districtHasPermissionToHilightStudents) {
                // If the student feature is enabled, then we should allow staff to set the display name they want to use when a student receives a hilight from them
				setShowDisplayNameForStudentsField(true);
			} else {
				setShowDisplayNameForStudentsField(false);
			}
		} catch (err) {
			// log the error
			Sentry.captureException(err);
		}
	}
    useEffect(() => {
		if (user) {
			getDistrictLevelPermissions(user.districtId);
		}
	}, [user?.districtId]);

    return (
    <div className='teacher-account-page'>
        <Box className='page-heading'>Account</Box>
        <ChangePasswordPopup 
            userId={user!.id}
            popupOpen={showChangePasswordPopup}
            closePopup={() => {
                setShowChangePasswordPopup(false);
            }}
        />
        <ChangeProfilePhotoPopup 
            userId={user!.id}
            popupOpen={showChangeProfilePhotoPopup}
            closePopup={() => {
                setShowChangeProfilePhotoPopup(false);
            }}
            handleSaveUserData={handleSaveUserData}
        />
        <Box className='teacher-account-grid'>
            {user === undefined || userKreds === undefined ? <CircularProgress /> :
                <Grid container spacing={3}>
                    <Grid item xs={12} md={12} lg={12} className='teacher-account-profile-photo-grid-item' >
                        <Box className='teacher-account-grid-item-box'>
                            <h3 className='teacher-account-grid-item-header'>Profile Photo</h3>
                            { removeProfilePhotoInProgress ? <CircularProgress/> :
                            <div className='teacher-account-profile-photo-data'>
                                <Avatar
                                    className='teacher-account-avatar'
                                    {...getUserAvatar(user.profilePhotoImageUrl)}
                                >{`${user.firstName[0]}${user.lastName[0]}`}</Avatar>
                                <div className='change-profile-photo-actions'>
                                    <Button 
                                            onClick={() => setShowChangeProfilePhotoPopup(true)}
                                            variant='contained'
                                            className='change-profile-photo-action-button'
                                            size='small'
                                        >Change Photo</Button>
                                    { (user.profilePhotoImageUrl) && 
                                        <Tooltip title='Removes your profile photo and sets it back to just your initials.'>
                                            <Button 
                                                onClick={() => removeProfilePhoto()}
                                                variant='contained'
                                                className='change-profile-photo-action-button'
                                                id='remove-profile-photo-button'
                                                size='small'
                                            >Remove Photo</Button>
                                        </Tooltip>
                                    }
                                </div>
                            </div>
                            }
                        </Box>
                        
                    </Grid>
                    {!rewardsDisabled && 
                        <Grid item xs={12} md={12} lg={12} className='teacher-account-kreds-grid-item' >
                            <Box className='teacher-account-grid-item-box'>
                                <h3 className='teacher-account-grid-item-header'>Your Points</h3>
                                <Typography className='teacher-account-points-text'>These are the points you have left to spend by sending Hilights this week, and the points you have earned that you can redeem in the Rewards Marketplace!</Typography>
                                {user.userType === UserType.Admin && <Typography className='teacher-account-sub-text'>As an Admin user, you have unlimited Hilight points to give each week. Non-admin users have 25 points to give each week.</Typography>}
                                <Box className='teacher-account-kreds'>
                                    <Box className='teacher-account-kreds-box'>
                                        <StarsIcon className='user-account-star-icon' fontSize='large'/>
                                        <Box className='kreds-count-data'>
                                            <Typography className='kreds-count'>{user.userType === UserType.Admin ? 'Unlimited' :  userKreds!.pointsToGive}</Typography>
                                            <Typography className='kreds-text'>Points to Give</Typography>
                                        </Box>
                                    </Box>
                                    <Box className='teacher-account-kreds-box'>
                                        <StarsIcon className='user-account-star-icon' fontSize='large'/>
                                        <Box className='kreds-count-data'>
                                            <Typography className='kreds-count'>{userKreds!.currentPoints}</Typography>
                                            <Typography className='kreds-text'>Points to Redeem</Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                    }
                    <Grid item xs={12} md={12} lg={12} className='teacher-account-basic-data-grid-item' >
                        <Box className='teacher-account-grid-item-box'>
                            <h3 className='teacher-account-grid-item-header'>Personal Info</h3>
                            <Box className='teacher-account-personal-info'>
                                {isCleverUser &&
                                    <div className='clever-warning'>
                                        <Typography className='clever-warning-text'>This account is linked to a Clever account, so your <span className='bold-text'>First Name</span> and <span className='bold-text'>Last Name</span> are imported from Clever and cannot be updated here. If you think the below information is incorrect, please correct it in Clever or reach out to your system administrator. For further assistance, please contact support@hilightedu.com.</Typography>
                                    </div>
                                }
                                <div className='teacher-account-edit-basic-data'>
                                    <h4 className='teacher-account-edit-basic-data-field-header'>First Name</h4>
                                        {firstNameFieldEditable ? 
                                            (
                                                <div className='teacher-account-edit-basic-data-field'>
                                                    <TextField 
                                                        variant='outlined'
                                                        className='edit-field'
                                                        disabled={!firstNameFieldEditable}
                                                        defaultValue={firstNameField}
                                                        type='text'
                                                        name='firstName'
                                                        id='name-input'
                                                        onChange={(e) => setFirstNameField(e.target.value)}
                                                    />
                                                    <div className='teacher-account-save-cancel-button'>
                                                        <Button className='teacher-account-edit-button' variant='contained' onClick={() => saveName()}>
                                                            Save
                                                        </Button>
                                                        <Button className='teacher-account-edit-button' id='cancel-edit-account-button' variant='contained' onClick={() => cancelSaveName()}>
                                                            Cancel
                                                        </Button>
                                                    </div>
                                                </div>   
                                            )
                                        : 
                                            (
                                                <Box  className='teacher-account-edit-basic-data-field'>
                                                    <Typography className='field-display'>{user.firstName}</Typography>
                                                    <Button 
                                                        className='teacher-account-edit-button' 
                                                        variant='contained' 
                                                        onClick={() => setFirstNameFieldEditable(true)}
                                                        disabled={isCleverUser}
                                                    >
                                                        Edit
                                                    </Button>
                                                </Box>
                                            )
                                    }   
                                </div>                          
                                <div className='teacher-account-edit-basic-data'>
                                    <h4 className='teacher-account-edit-basic-data-field-header'>Last Name</h4>
                                        {lastNameFieldEditable ? 
                                            (
                                                <div className='teacher-account-edit-basic-data-field'>
                                                    <TextField 
                                                        variant='outlined'
                                                        className='edit-field'
                                                        disabled={!lastNameFieldEditable}
                                                        defaultValue={lastNameField}
                                                        type='text'
                                                        name='lastName'
                                                        id='name-input'
                                                        onChange={(e) => setLastNameField(e.target.value)}
                                                    />
                                                    <div className='teacher-account-save-cancel-button'>
                                                        <Button className='teacher-account-edit-button' variant='contained' onClick={() => saveName()}>
                                                            Save
                                                        </Button>
                                                        <Button className='teacher-account-edit-button' id='cancel-edit-account-button' variant='contained' onClick={() => cancelSaveName()}>
                                                            Cancel
                                                        </Button>
                                                    </div>
                                                </div>   
                                            )
                                        : 
                                            (
                                                <Box  className='teacher-account-edit-basic-data-field'>
                                                    <Typography className='field-display'>{user.lastName}</Typography>
                                                    <Button 
                                                        className='teacher-account-edit-button' 
                                                        variant='contained' 
                                                        onClick={() => setLastNameFieldEditable(true)}
                                                        disabled={isCleverUser}
                                                    >
                                                        Edit
                                                    </Button>
                                                </Box>
                                            )
                                    }   
                                </div>
                                <div className='teacher-account-edit-basic-data'>
                                    <h4 className='teacher-account-edit-basic-data-field-header'>Role</h4>
                                        {roleFieldEditable ? 
                                            (
                                                <div className='teacher-account-edit-basic-data-field'>
                                                    <TextField 
                                                        variant='outlined'
                                                        className='edit-field'
                                                        disabled={!roleFieldEditable}
                                                        defaultValue={roleField}
                                                        type='text'
                                                        name='role'
                                                        id='role-input'
                                                        onChange={(e) => setRoleField(e.target.value)}
                                                    />
                                                    <div className='teacher-account-save-cancel-button'>
                                                        <Button className='teacher-account-edit-button' variant='contained' onClick={() => saveRole()}>
                                                            Save
                                                        </Button>
                                                        <Button className='teacher-account-edit-button' id='cancel-edit-account-button' variant='contained' onClick={() => cancelSaveRole()}>
                                                            Cancel
                                                        </Button>
                                                    </div>
                                                </div>   
                                            )
                                        : 
                                            (
                                                <Box  className='teacher-account-edit-basic-data-field'>
                                                    <Typography className='field-display'>{user.roleAtSchool}</Typography>
                                                    <Button className='teacher-account-edit-button' variant='contained' onClick={() => editRole()}>
                                                        Edit
                                                    </Button>
                                                </Box>
                                            )
                                    }   
                                </div>
                                {showDisplayNameForStudentsField &&
                                    <div className='teacher-account-edit-basic-data'>
                                        <h4 className='teacher-account-edit-basic-data-field-header'>Display Name</h4>
                                            <Box className='teacher-account-sub'>
                                                <Typography className='teacher-account-sub-text display-name-text'>{`This field is only used when students are viewing a hilight sent to them by you. How would you like your name to appear to students?`}</Typography>
                                                <Typography className='teacher-account-sub-text display-name-text'>{`ex: Ms. Day, Mr. Day, Coach Day, Principal Day, J. Day, Jessica Day, etc.`}</Typography>
                                            </Box>
                                            {displayNameFieldEditable ? 
                                                (
                                                    <div className='teacher-account-edit-basic-data-field'>
                                                        <TextField 
                                                            variant='outlined'
                                                            className='edit-field'
                                                            disabled={!displayNameFieldEditable}
                                                            defaultValue={displayNameField}
                                                            type='text'
                                                            name='displayName'
                                                            id='display-name-input'
                                                            onChange={(e) => setDisplayNameField(e.target.value)}
                                                        />
                                                        <div className='teacher-account-save-cancel-button'>
                                                            <Button className='teacher-account-edit-button' variant='contained' onClick={() => saveDisplayName()}>
                                                                Save
                                                            </Button>
                                                            <Button className='teacher-account-edit-button' id='cancel-edit-account-button' variant='contained' onClick={() => cancelSaveDisplayName()}>
                                                                Cancel
                                                            </Button>
                                                        </div>
                                                    </div>   
                                                )
                                            : 
                                                (
                                                    <Box  className='teacher-account-edit-basic-data-field'>
                                                        <Typography className='field-display'>{user.displayName || 'Click Edit to add your Display Name!'}</Typography>
                                                        <Button className='teacher-account-edit-button' variant='contained' onClick={() => editDisplayName()}>
                                                            Edit
                                                        </Button>
                                                        {(!user.displayName || user.displayName?.trim() === '') &&
                                                            <Box className='account-error-icon'>
                                                                <Tooltip
                                                                    title='Display Name is required!'
                                                                    arrow={true}
                                                                >
                                                                    <ErrorOutlineIcon/>
                                                                </Tooltip>
                                                                
                                                            </Box>
                                                        }
                                                    </Box>
                                                )
                                        }   
                                    </div>
                                }
                                <div className='teacher-account-edit-basic-data'>
                                    <h4 className='teacher-account-edit-basic-data-field-header'>Email</h4>
                                    <Box  className='teacher-account-edit-basic-data-field'>
                                        <Typography className='field-display'>{user.email}</Typography>
                                        {/* <div className='edit-button'>
                                            <Tooltip title='Email addresses cannot be edited via the Account page at this time. Please contact support if you need to change the email address associated with this account.'>
                                                <div>
                                                    <Button className='teacher-account-edit-button' variant='contained' disabled={true}>
                                                        Edit
                                                    </Button>
                                                </div>
                                            </Tooltip>
                                        </div> */}
                                    </Box>
                                </div>
                                <div className='teacher-account-edit-password'>
                                    <div>
                                        <Button 
                                            variant='contained'
                                            className='teacher-account-edit-password-button'
                                            onClick={() => setShowChangePasswordPopup(true)}
                                        >
                                            Change Password
                                        </Button>
                                    </div>
                                </div>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            }
        </Box>
    </div>

    )
}